import React, { useState } from "react";
import { RcFile, Upload } from "@sis-lab/web-ui-components";
import { useI18nContext } from "i18n/i18n-react";
import xmlApi from "apis/xml";
import { PrmDocument } from "apis/xml/types";
import { file2Base64, file2Blob } from "utils";
import moment from "moment";
import { useSelector } from "react-redux";
import { ApplicationState } from "modules";


interface Props {
  prmDocument?: PrmDocument,
  setPrmDocument: (document: PrmDocument) => void
  className?: string
  disabled: boolean
  formHook?: any
}


export default function PaymentUpload({ prmDocument, formHook, className, disabled, setPrmDocument }: Props) {
  const userSub = useSelector((state: ApplicationState) => state.authentication.user?.sub);
  const parentSub = useSelector((state: ApplicationState) => state.authentication.user?.parentSub);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const { LL } = useI18nContext()

  const uploadPrmDocument = async (data: RcFile) => {
    if (error) setError(undefined);

    const base64 = await file2Base64(data)
    const content = base64.replace(/^data:.*\/\w+;base64,/, '');

    if (data.type.includes('/xml') || data.type.includes('/EIP')) {
      try {
        setLoading(true);
        const { data: prm } = await xmlApi.transformXmlIntoPrm(file2Blob(data), parentSub || userSub);

        formHook.reset(
          {
            title: prm.orderId,
            description: prm.description,
            reqdExctnDt: prm.reqdExctnDt ? moment(prm.reqdExctnDt) : undefined,
            xpryDt: prm.xpryDt ? moment(prm.xpryDt) : undefined,
            amount: prm.amount,
            // payer
            payerEmail: prm.payer?.email,
            payerName: prm.payer?.name,
            payerId: prm.payer?.id,
            // document
            documentName: data.name,
            documentContentType: prm.document.contentType || data.type,
            documentType: prm.document.type,
          },
          {
            keepDirtyValues: false
          }
        )
      } catch { 
        // do nothing
      } finally {
        setLoading(false)
      }
    }
    
    setPrmDocument({
      name: data.name,
      content,
      contentType: data.type,
      type: prmDocument?.type
    })
  }


  return (
    <Upload
      className={className}
      disabled={disabled || loading}
      icon={loading ? 'loading' : 'document'}
      name='file'
      beforeUpload={uploadPrmDocument}
      size='small'
      errorMessage={error}
      title={LL.paymentCommonFields.upload.title()}
      text={prmDocument ? prmDocument.name : LL.commonFields.upload.prompt()}
      helpText={LL.commonFields.upload.helpText()}
    />
  )
}
