import axios from 'axios';
import React, { useContext, useState } from 'react';
import { Button, Divider, Form, Icon, Input, Modal, message, useForm } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import auth, { Member } from 'apis/auth';
import { errorParser } from 'utils/requests/errorParser';
import MembersContext from 'pages/AdminDashboard/components/MemberContext/MembersContext';
import styles from './MemberModal.module.scss';
import useRightsSelector from './RightsSelector/useRightsSelector';

interface Props {
  member: Member
  isUser: boolean
  isOpen: boolean
  onClose: () => void
}


export default function MemberModal({ member, isUser, isOpen, onClose }: Props) {
  const [loading, setLoading] = useState(false);
  const [RightsSelector, { isAdmin, ...permissions }] = useRightsSelector(member, isUser || member?.role === 'owner');
  const { members, updateMembers } = useContext(MembersContext);
  const { LL } = useI18nContext();
  const form = useForm();

  const onUpdate = async () => {
    setLoading(true);
    auth.updateMember(member?.sub, {
      role: isAdmin ? 'admin' : 'member',
      enabled: member.enabled,
      position: form.getValues('position') || member.position,
      permissions
    })
      .then(({ data: updatedMember }) => {
        const updatedMembers = members.map(current => current.sub === member.sub ? updatedMember : current)
        updateMembers(updatedMembers)
        onClose()
      })
      .catch(e => errorParser(e, err => form.setError('role', { message: err.response?.statusText })))
      .finally(() => setLoading(false))
  }

  const onToogle = async () => {
    setLoading(true)
    auth.updateMember(member?.sub, {
      role: member.role,
      enabled: !member.enabled,
      position: member.position,
      permissions: {
        allowedToSign: !!member.allowedToSign,
        allowedToView: !!member.allowedToView,
        allowedToUpload: !!member.allowedToUpload,
      },
    })
      .then(({ data: updatedMember }) => {
        const updatedMembers = members.map(current => current.sub === member.sub ? updatedMember : current)
        updateMembers(updatedMembers)
        onClose()
      })
      .catch(e => errorParser(e, err => form.setError('role', { message: err.response?.statusText })))
      .finally(() => setLoading(false))
  }

  const onDelete = async () => {
    setLoading(true)
    auth.deleteMember(member.sub)
      .then(() => {
        updateMembers(members.filter(current => current.sub !== member.sub))
        onClose()
      })
      .catch(e => errorParser(e, err => form.setError('role', { message: err.response?.statusText })))
      .finally(() => setLoading(false))
  }

  const onResend = async () => {
    setLoading(true);
    try {
      await auth.resendSuccessCreateMember(member.email);
    } catch(e) {
      if (axios.isAxiosError(e)) form.setError('role', { message: e.response?.statusText });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      open={isOpen}
      wrapClassName={styles.employeeModal}
      footer={null}
      onCancel={onClose}
      title={
        <div className={styles.modalHeader}>
          <Icon className={styles.icon} name={'face' as 'arrow_down'} />
          {member.lastName && <h3>{member.firstName} {member.lastName}</h3>}
          {!member.lastName && <h3>{LL.adminDashboard.unconfirmedIdentity()}</h3>}
          <small className={member.enabled ? styles.active : styles.disabled}>
            {member.enabled ? LL.common.enabled() : LL.common.disabled()}
          </small>
        </div>
      }
    >
      <Form className={styles.form} disabled={member.role === 'owner' && !isUser} formHook={form}>
        <Input
          disabled
          name='email'
          title={LL.commonFields.email.title()}
          defaultValue={member.email}
        />
        <Input
          disabled
          name='username'
          title={LL.commonFields.username.title()}
          defaultValue={member.username}
        />
        <Input
          disabled
          name='sub'
          title={LL.commonFields.sub.title()}
          defaultValue={member.sub}
          suffix='content_copy'
          onSuffix={() => {
            navigator.clipboard.writeText(member.sub || '');
            message.success(LL.common.textCopied(), 1)
          }}
        />
        <Input
          name='position'
          title={LL.commonFields.position.title()}
          defaultValue={member.position}
        />
        <Divider size='tiny' />
        <RightsSelector />
        <Divider size='tiny' />
      </Form>
      <div className={styles.modalFooter}>
        <Button
          disabled={isUser || member.role === 'owner' || loading}
          icon='delete'
          children={LL.commonButtons.delete()}
          onClick={onDelete}
          state='warning'
          type='secondary'
        />
        {
          !member.enabled && 
          <Button
            disabled={loading}
            children='Resend'
            onClick={onResend}
            type='secondary'
          />
        }
        <Button
          disabled={member.role === 'owner' || !member.lastName || loading}
          children={member.enabled ? LL.commonButtons.disable() : LL.commonButtons.enable() }
          state={member.enabled ? 'warning' : undefined}
          onClick={onToogle}
          type='secondary'
        />
        <Button
          disabled={loading}
          children={LL.commonButtons.update()}
          onClick={onUpdate}
        />
      </div>
    </Modal>
  )
}
