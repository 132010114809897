/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Avatar, Icon, TimelineItem } from '@sis-lab/web-ui-components';
import { IconsType } from '@sis-lab/web-ui-components/dist/components/Icon/Icon';
import { useI18nContext } from 'i18n/i18n-react';
import { transformDate } from 'utils';
import { IDocumentEvent, ISignatureDetailed } from 'apis/contract';
import SignatureDetailsModal from '../../../../../../../components/SignatureDetailsModal/SignatureDetailsModal';
import styles from './DocumentEvent.module.scss';

interface Props {
  event: IDocumentEvent;
  docOwnerId?: string
  signature?: ISignatureDetailed;
}

const actionToIconMapper: { [key: string]: IconsType } = {
  accept: 'check_circle_outline',
  reject: 'close_circle_outline',
  create: 'post_add',
  eIdSign: 'edit',
  update: 'edit',
  inviteReCreated: 'attribution' as 'schedule_outline',
  inviteCreated: 'schedule_outline',
  inviteRevoked: 'person_remove' as 'arrow_down',
}; 

export default function DocumentEvent({ event, signature, docOwnerId }: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const { LL } = useI18nContext();

  return (
    <TimelineItem
      dot={<Avatar name={event.target?.name || event.target?.email || event.actor?.name} width={40} />}
      title={event.target?.name ||  event.target?.email || event.actor?.name}
      subtitle={event.target?.email || event.actor?.email}
      note={decodeURIComponent(event.notes || '')}
    >
      <div className={[styles.stamp, styles[event.action]].join(' ')}>
        <Icon className={styles.icon} name={actionToIconMapper[event.action]} />
        <div className={styles.header}> 
          <p>{LL.actions[event.action]()}</p>
          {signature && <span onClick={() => setIsVisible(true)}>{LL.participantTab.details()}</span>}
          <small>{transformDate(event.createdAt).substring(0,16)}</small>
        </div>
      </div>
      <SignatureDetailsModal
        signature={signature}
        visible={isVisible}
        onClose={() => setIsVisible(false)}
        isOwner={docOwnerId === signature?.creator.identityId}
      />
    </TimelineItem>
  );
}
