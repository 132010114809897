import React, { useState } from 'react';
import { useI18nContext } from 'i18n/i18n-react';
import { ISignature } from 'apis/contract';
import PageHeader from 'components/PageHeader/PageHeader';
import TableFooter from 'components/TableFooter/TableFooter';
import SignatureDetailsModal from 'components/SignatureDetailsModal/SignatureDetailsModal';
import SignatureTable from 'components/SignatureTable/SignatureTable';
import useFetchDocument from './components/useFetchDocument';
import useFetchSignatures from './components/useFetchSignatures';
import SignaturesSearch from './components/SignaturesSearch/SignaturesSearch';
import styles from './SignaturesPage.module.scss';


export default function SignaturesPage() {
  const [selectedSignature, setSelectedSignature] = useState<ISignature>();
  const [signatures, isLoading, isFullyLoaded, fetch] = useFetchSignatures();
  const [document] = useFetchDocument(selectedSignature?.documentFingerprint);
  const { LL } = useI18nContext()

  const openSignature = (id: string) => {
    const signature = signatures.find((x: any) => x.id === id);
    setSelectedSignature(signature);
  };

  return (
    <div className={styles.page}>
      <PageHeader title={LL.mySignaturesPage.title()} />
      <div className={styles.body}>
        <SignaturesSearch onSearch={(search, signal) => fetch(search, true, signal)} />
        <SignatureTable signatures={signatures} onClick={openSignature} isLoading={isLoading} />
        <TableFooter isLoading={isLoading} isFullyLoaded={isFullyLoaded} onLoadMore={() => fetch({})}/>
      </div>
      <SignatureDetailsModal
        visible={!!selectedSignature}
        signature={document?.signatures.find(
          signature => signature.creator.identityId === selectedSignature?.creator.identityId
        )}
        fingerprint={selectedSignature?.documentFingerprint}
        onClose={() => setSelectedSignature(undefined)}
        isOwner={selectedSignature?.isDocumentOwner}
      />
    </div>
  );
}
