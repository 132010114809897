import { ActionTypes, CertificateAction, CertificatesState } from './types';

const initialState: any = { loading: false, certificate: null };

const certificates = (state = initialState, action: CertificateAction): CertificatesState => {
  switch (action.type) {
    // ====> CERTIFICATE <====
    case ActionTypes.REQUEST_GET_CERTIFICATE:
      return {
        ...state,
        certificate: null,
        loading: true,
      };
    case ActionTypes.SUCCESS_GET_CERTIFICATE:
      return {
        ...state,
        loading: false,
        certificate: action.certificate,
        error: undefined,
      };
    case ActionTypes.ERROR_GET_CERTIFICATE:
      return {
        ...state,
        loading: false,
        certificate: null,
        error: action.error,
      };
    default:
      return state;
  }
};

export default certificates;
