import contract, { IDocumentBasic } from "apis/contract"
import { useEffect, useState } from "react"


const useFetchHistory = () => {
  const [documents, setDocuments] = useState<IDocumentBasic[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()

  useEffect(() => {
    contract.getMyDocuments({ pageSize: 4 })
      .then(({ data }) => setDocuments(data.documents.slice(0, 4)))
      .catch(() => setError('error'))
      .finally(() => setLoading(false))
  }, [])

  return [documents, loading, error] as const
}

export default useFetchHistory
