import { objectToQuery } from "utils";
import { PaginatedResponse } from "types";
import { instance } from "../instance";
import { MailGroup, MailGroupSearch } from "./types";

export const createMailGroup = (email: string, name: string) => (
  instance.post<MailGroup>('/organizations/mail-groups', { email, name })
)

export const getMailGroups = (search: MailGroupSearch, signal?: AbortSignal) => (
  instance.get<PaginatedResponse<MailGroup>>(`/organizations/mail-groups/${objectToQuery(search)}`, { signal })
)

export const getMailGroup = (id: string) => (
  instance.get<MailGroup>(`/organizations/mail-groups/${id}`)
)

export const patchMailGroup = (id: string, updatedMailGroupFields: Partial<MailGroup>) => (
  instance.patch<MailGroup>(`/organizations/mail-groups/${id}`, updatedMailGroupFields)
)

export const deleteMailGroup = (id: string) => (
  instance.delete(`/organizations/mail-groups/${id}`)
)