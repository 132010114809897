import { useEffect, useState } from 'react';
import { message } from '@sis-lab/web-ui-components';
import { Token } from 'apis/gateway/types';
import auth from 'apis/auth';


export default function useApiTokens() {
  const [tokens, setTokens] = useState<Token[]>();

  useEffect(() => {
    auth.getTokens()
      .then(({ data }) => setTokens(data.tokens))
      .catch(() => {
        message.error('Could not load tokens')
      })
  }, []);

  const createToken = async (name: string) => {
    // Handle errors in the upper scope
    const { data } = await auth.generateToken({ name, services: ['corposign-api-token'] });
    const newToken = { createdAt: data.createdAt, id: data.id, name, paused: false,  services: data.services }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    tokens ? setTokens([newToken, ...tokens]) : setTokens([newToken])
    return data.accessToken;
  }

  const updateToken = async (id: string, paused: boolean) => {
    if (!tokens) return
    await auth.updateToken(id, paused)
      .then(() => setTokens(tokens.map((token) => token.id === id ? {...token, paused } : token )))
      .catch(() =>  message.error('Update failed'))
  }

  const deleteToken = async (id: string) => {
    if (!tokens) return
    await auth.deleteToken(id)
      .then(() => setTokens(tokens.filter(token => token.id !== id)))
      .catch(() => message.error('Deletion failed'))
  }

  return [tokens, createToken, updateToken, deleteToken] as const;
};
