import eDeliveryApi, { EdeliveryMessage, LastReceivedLog } from "apis/eDelivery"
import { useEffect, useState } from "react"


export default function useGetMessagesInfo() {
  const [messages, setMessages] = useState<EdeliveryMessage[]>();
  const [lastLog, setLastLog] = useState<LastReceivedLog>();
  const [receiving, setReceiving] = useState<boolean>();
  const [loading, setLoading] = useState(false);
  const [missingConfig, setMissingConfig] = useState(false);

  const fetchMessagesInfo = async () => {
    setLoading(true)
    try {
      await Promise.all([
        eDeliveryApi.getNewMessages(),
        eDeliveryApi.getLastReceivedLog(),
        eDeliveryApi.getReceivingStatus(),
      ]).then(([msgResp, logResp, statusResp]) => {
        setMessages(msgResp.data)
        setLastLog(logResp.data)
        setReceiving(statusResp.data)
      })
    } catch (err: any) {
      if (err?.response?.status === 403) setMissingConfig(true)
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => { fetchMessagesInfo() }, [])

  return [messages, lastLog, receiving, loading, missingConfig, fetchMessagesInfo] as const
}
