import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { getMyIdentity, IIdentity } from "apis/contract";

const useFetchIdentity = () => {
  const [identity, setIdentity] = useState<IIdentity>();  
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    const fetchIdentity = async () => {
      try {
        const { data } = await getMyIdentity();
        setIdentity(data)

      } catch (e) {
        if (!axios.isAxiosError(e)) return
        setError(e)
      }
    }

    fetchIdentity()
  }, [])

  return [identity, error] as const;
}

export default useFetchIdentity;
