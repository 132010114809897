import React, { useEffect } from 'react';
import { Skeleton, useBreakpoints } from '@sis-lab/web-ui-components';
import { useHistory } from 'react-router-dom';
import InfiniteTable from "components/InfiniteTable/InfiniteTable";
import { useSelector } from "react-redux";
import { PRM } from 'apis/prmApi';
import { ApplicationState } from 'modules';
import styles from './PaymentsTable.module.scss';
import PaymentColumns from '../PaymentColumns/PaymentsColumns';



interface Props {
  fetchPayments: () => Promise<void>
  payments?: PRM[]
  loading: boolean
  isFullyLoaded: boolean
}


export default function PaymentsTable({ payments, isFullyLoaded, loading, fetchPayments }: Props) {
  const history = useHistory();
  const { mobile, tabletSmall } = useBreakpoints() || {}
  const sub = useSelector((state: ApplicationState) => state.authentication.user?.sub) || '';

  return (
    !payments
      ? (
          <Skeleton
            containerClassName={styles.skeletonContainer}
            className={styles.skeletonItem}
            count={5}
            height={81}
            borderRadius='16px'
          />
        )
      :
        (
          <InfiniteTable
            loadMore= { fetchPayments }
            showHeader = {false}
            pagination = {false}
            fullyLoaded = { isFullyLoaded }
            loading = { loading }
            columns = { mobile || tabletSmall ? [PaymentColumns(sub)[0], PaymentColumns(sub)[2]] : PaymentColumns(sub) }
            dataSource = { payments }
            onRow = { record => ({ onClick: () => history.push(`/payments/${record.id}`) })}
            rowKey = { record => record.id }
          />
      )
  );
}
