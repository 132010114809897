import React from 'react';
import { Form, Icon, Input, RangePicker, RangeValue, useForm } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import { DocumentTypes, IDocumentsSearch } from 'apis/contract/types';
import DocumentTypeSelector from 'components/DocumentTypeSelector/DocumentTypeSelector';
import styles from './Search.module.scss';

interface Props {
  onSearch: (searchParams: IDocumentsSearch, reset?: boolean) => void
}

interface FormFields {
  type: { value: DocumentTypes, label: string }[];
  date: RangeValue
  search: string
}


export default function Search({ onSearch }: Props) {
  const { LL } = useI18nContext();
  const form = useForm();

  const handleSearch = () => {
    const { type, date, search } = form.getValues() as FormFields;

    const searchProps: IDocumentsSearch = {
      type: type?.map(t => t.value),
      createdAfter:  date?.[0]?.startOf('day').toISOString(),
      createdBefore: date?.[1]?.endOf('day').toISOString(),
      substring: search !== '' ? search.trim() : undefined
    }
    onSearch(searchProps, true);
  }

  return (
    <Form formHook={form} className={styles.searchWrapper}>
      <Input
        name='search'
        placeholder={LL.search.mainPlaceholder()}
        wrapperClassName={styles.search}
        onChange={handleSearch}
        descr={<div className={styles.searchDescr}><Icon onClick={handleSearch} name='search' /></div>}
      />
      <DocumentTypeSelector
        name='type'
        indicator='filter_alt'
        onChange={handleSearch}
        fixedHeight
        isMulti
        isRtl
      />
      <RangePicker
        name='date'
        onChange={handleSearch}
        placeholder={[LL.paymentsPage.dateFilter.startDate(), LL.paymentsPage.dateFilter.endDate()]} 
      />
    </Form>
  )
}
