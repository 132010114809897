import React from 'react';
import {
  Avatar,
  Card,
  Divider,
  Form,
  useForm,
  Input,
  CardHeader,
  message,
} from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import { ApplicationState } from 'modules';
import { useSelector } from 'react-redux';
import styles from './ProfileCard.module.scss';


export default function ProfileInfoCard() {
  const { LL } = useI18nContext();
  const user = useSelector((state: ApplicationState) => state.authentication.user)
  const formHook = useForm();

  return (
    <Card>
      <CardHeader title={LL.profileCard.title()} />
      <Form disabled formHook={formHook} className={styles.inputsLayout}>
        <div className={styles.avatarRow}>
          <Avatar
            width={70}
            className={styles.avatar}
            name={user?.sub}
          />
          <div>
            <Input
              name='fullName'
              title={LL.commonFields.fullName.title()}
              defaultValue={`${user?.firstName} ${user?.lastName}`}
              bodyClassName={styles.userName}
              />
            <Input
              name='country'
              defaultValue={user?.documentLocale}
              title={LL.commonFields.country.title()}
            />
          </div>
        </div>
        <Divider size='small' />
        <div className={styles.emailRow}>
          <Input
            name='email'
            defaultValue={user?.email}
            title={LL.commonFields.email.title()}
            suffix='content_copy'
            onSuffix={() => {
              navigator.clipboard.writeText(user?.email || '');
              message.success(LL.common.textCopied(), 1)
            }}
          />
          <Input
            name='username'
            defaultValue={user?.username}
            title={LL.commonFields.username.title()}
          />
        </div>
        <Input
          name='sub'
          defaultValue={user?.sub}
          title={LL.commonFields.sub.title()}
          suffix='content_copy'
          onSuffix={() => {
            navigator.clipboard.writeText(user?.sub || '');
            message.success(LL.common.textCopied(), 1)
          }}
        />
        <Divider size='small' />
        <Input
          name='personalId'
          defaultValue={user?.personalIdentifierSha256}
          title={LL.commonFields.nationalCode.title()}
          />
        <div className={styles.birthRow}>
          <Input
            name='birthdate'
            defaultValue={user?.dateOfBirth}
            title={LL.commonFields.birthDate.title()}
            />
          <Input
            name='verification'
            title={LL.profileCard.verificationMethod()}
            defaultValue={user?.kycMethod} 
          />
        </div>
      </Form>
    </Card>
  );
}
