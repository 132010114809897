import React from 'react';
import { Card, CardHeader } from '@sis-lab/web-ui-components';
import { Link } from 'react-router-dom';
import verifyImg from '../../../images/verify.svg'
import styles from './ResetSuccess.module.scss';


export default function ResetSuccess() {
  return (
    <Card className={styles.card}>
      <CardHeader
        title='Password changed'
        suffix={<Link to='/login'>Login</Link>}
      />
      <div className={styles.cardBody}>
        <img src={verifyImg} alt='Verify your email'/>
        <p>Your password was successfuly changed.</p> 
        <p>You can now use your new credentials to <Link to='/login'>Login</Link> into the account</p>
      </div>
    </Card>
  );
}
