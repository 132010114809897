import React from 'react';
import { Card, CardHeader } from '@sis-lab/web-ui-components';
import { Link } from 'react-router-dom';
import { useI18nContext } from 'i18n/i18n-react';
import mailboxImg from 'images/mailbox.svg'
import styles from './CompleteKybEmail.module.scss'


export default function CompleteKybEmail() {
  const { LL } = useI18nContext()

  return (
    <Card className={styles.card}>
      <CardHeader title={<h2>{LL.kybCard.title()}</h2>} />
      <img src={mailboxImg} alt='Verify your email'/>
      <p>{LL.kybCard.instruction()}</p>
      <small><Link to='todo'>{LL.idenfyEmailPage.clickHere()}</Link> {LL.kybCard.restartText()}</small>
    </Card>
  )
}
