import { Icon } from "@sis-lab/web-ui-components";
import { useI18nContext } from "i18n/i18n-react";
import React from "react";
import styles from './DueDate.module.scss'

interface Props {
  dueDate: string
}

export default function DueDate({ dueDate } : Props) {
  const { LL } = useI18nContext()

  return (
    <div className={styles.dueDate}>
      <Icon className={styles.icon} name='schedule_outline' />
      <div>
        <p className={styles.innerTitle}>{LL.paymentCommon.dueDate()}</p>
        <p className={styles.date}>{dueDate}</p>
      </div>
    </div>
  )
}