import React, { useState } from 'react';
import IssuerStep from './components/IssuerStep/IssuerStep';
import AuthStep from './components/AuthStep/AuthStep';

type Props = {
  switchToPasswordLogin: () => void
}

export default function VpLogin({ switchToPasswordLogin }:Props) {
  const [vpSession, setVpSession] = useState('');

  return (
    !vpSession
      ? <IssuerStep
          setVpSession={(session: string) => setVpSession(session)}
          switchToPasswordLogin={switchToPasswordLogin}
        />
      : <AuthStep resetVpSession={() => setVpSession('')} vpSession={vpSession} />
  );
}
