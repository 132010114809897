import React, { useContext, useState } from "react";
import { Button } from "@sis-lab/web-ui-components";
import { useI18nContext } from "i18n/i18n-react";
import PageHeader from "components/PageHeader/PageHeader";
import NewPaymentModal from "./components/NewPaymentModal/NewPaymentModal";
import PaymentSearch from "./components/PaymentSearch/PaymentSearch";
import styles from './PaymentsPage.module.scss';
// import PaymentsContext from "./components/PaymentsContext";
import useGetPaymentList from "./components/useGetPaymentList";
import PaymentsTable from "./components/PaymentsTable/PaymentsTable";


export default function PaymentsPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const { fetchPayments, updatePayments, payments, loading, isFullyLoaded} = useContext(PaymentsContext);
  const [fetchPayments, updatePayments, payments, loading, isFullyLoaded] = useGetPaymentList();
  const { LL } = useI18nContext();

  return (
    <div className={styles.page}>
      <PageHeader title='Payments'>
        <div className={styles.headerButtons}>
          <Button
            suffix='add'
            mobileIcon='add'
            children={LL.paymentsPage.createPrmButton()}
            onClick={() => setIsModalOpen(true)}
          />
        </div>
        <NewPaymentModal
          isOpen={isModalOpen}
          addNewPayment={updatePayments}
          onCancel={() => setIsModalOpen(false)}
        />
      </PageHeader>
      <div className={styles.body}>
        <PaymentSearch />
        <PaymentsTable
          payments={payments}
          fetchPayments={fetchPayments}
          isFullyLoaded={isFullyLoaded}
          loading={loading}
        />
        {/* <InfiniteTable
          loadMore={fetchPayments}
          showHeader={false}
          pagination={false}
          fullyLoaded={isFullyLoaded}
          loading={loading}
          columns={mobile || tabletSmall ? [PaymentColumns(sub)[0], PaymentColumns(sub)[2]] : PaymentColumns(sub)}
          dataSource={payments}
          onRow={record => ({ onClick: () => history.push(`/payments/${record.id}`) })}
          rowKey={record => record.id}
        /> */}
      </div>
    </div>
  )
}
