/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import CompleteKybEmail from './components/CompleteKybEmail/CompleteKybEmail';
import IdenfyEmail from './components/IdenfyEmail/IdenfyEmail';
import VerificationEmail from './components/VerificationEmail/VerifciationEmail';

interface Props {
  content: 'idenfy' | 'verification' | 'kyb'
}

export default function CheckEmailPage({ content }: Props) {
  if (content === 'verification') return <VerificationEmail />
  if (content === 'kyb') return <CompleteKybEmail />
  return <IdenfyEmail />
}
