/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-promise-executor-return */
import { message } from "@sis-lab/web-ui-components";
import keycloak from "apis/keycloak";
import { authenticateAction, logoutAction } from "modules/authentication/actions";
import { useI18nContext } from 'i18n/i18n-react';
import { useDispatch, useSelector } from "react-redux";
import { decodeJWS } from "utils";
import axios from "axios";
import { ApplicationState } from "modules";

const validateToken = (token?: string | null, sub?: string) => {
  if (!token)
    throw new Error('Token is missing.')

  // * might throw an "Invalid token" error, will be catched in useInitUser
  const decodedToken = decodeJWS(token);

  if (new Date().getTime() / 1000 > decodedToken.exp!)
    throw new Error('Token has expired.')

  if (sub && decodedToken.sub !== sub)
    throw new Error('Token sub does not match to logged in user.')

  return { token, decodedToken }
}

const useInitUser = () => {
  const { LL } = useI18nContext();
  const dispatch = useDispatch();
  const user = useSelector((state: ApplicationState) => state.authentication.user)

  const refreshToken = async (delay: number = 0, refreshAttemptsCount: number = 1) => {
    try {
      await new Promise(resolve => setTimeout(resolve, delay));

      // ? no need to validate because it is validated below, inside "initUser"?
      // const { token } = validateToken(localStorage.getItem('refreshToken'), user?.sub);
      const token = localStorage.getItem('refreshToken');

      const newSession = await keycloak.refreshToken(token || '');
      localStorage.setItem('refreshToken', newSession.refresh_token);
      localStorage.setItem('accessToken', newSession.access_token);

      refreshToken((newSession.expires_in - 35) * 1000);
      authenticateAction(newSession.access_token)(dispatch);
    } catch (e) {
      if (
        !window.navigator.onLine ||
        !axios.isAxiosError(e) ||
        (axios.isAxiosError(e) && [400, 401, 403, undefined].includes(e.response?.status)) ||
        refreshAttemptsCount > 4
      ) {
        message.info(LL.common.failedToRefreshLogout(), 3)
        await new Promise(resolve => setTimeout(resolve, 3000));
        logoutAction()(dispatch);
      }
      else {
        refreshAttemptsCount++;
        const retryTimeoutSeconds = 2 ** refreshAttemptsCount
        message.info(LL.common.failedToRefreshRetrying(retryTimeoutSeconds), 3)
        refreshToken(retryTimeoutSeconds * 1000, refreshAttemptsCount)
      }
    }
  };

  const initUser = async () => {
    //  * the idea is to firstly find access token and try to authenticate with it; 
    //  * if it is expired or not found - try to find refresh token and if it is still valid - get new access token
    //  * if both fail - log out the user
    try {
      const nowSeconds = new Date().getTime() / 1000;

      // * 10020 - 35 > 10000 false - not much time left, refresh immediately
      // * 10090 - 35 > 10000 true - enough time, use found accessToken, schedule refresh as:
      // * 10090 - 35 - 10000 = 55 seconds * 1000
      // * "ENOUGH" is more than 35 seconds

      // console.log(`nowSeconds: ${nowSeconds}`);
      // console.log(`decodedToken.exp: ${decodedToken.exp}`);
      // console.log(`decodedToken.exp! - 35 > nowSeconds: ${decodedToken.exp! - 35 > nowSeconds}`);
      // console.log(`decodedToken.exp! - 35 - nowSeconds: ${decodedToken.exp! - 35 - nowSeconds}`);

      // * try to work with accessToken
      try {
        const { token, decodedToken } = validateToken(localStorage.getItem('accessToken'), user?.sub);

        // * if "enough" time to initialize user and make requests to api, authenticate with found access token
        if (decodedToken.exp! - 35 > nowSeconds) {
          authenticateAction(token)(dispatch);
          // * schedule refresh
          refreshToken((decodedToken.exp! - 35 - nowSeconds) * 1000);
        } else {
          validateToken(localStorage.getItem('refreshToken'), user?.sub);
          refreshToken(); // * refresh immediatelly
        }
      } catch (error) {
        // * try to work with refreshToken
        validateToken(localStorage.getItem('refreshToken'), user?.sub);

        refreshToken(); // * refresh immediatelly
      }
    } catch (e) {
      logoutAction()(dispatch);
    }
  }

  return initUser
}
export default useInitUser
