import React, { useState } from 'react';
import { Button, Card, Form, Input, Password, useForm } from '@sis-lab/web-ui-components';
import { Link, useHistory } from 'react-router-dom';
import { useI18nContext } from 'i18n/i18n-react';
import { passwordValidator, useQuery } from 'utils';
import { AccountTypeBox } from './components';
import styles from './SignupPage.module.scss';
import SignupForm from './components/SignupForm/SignupForm';

export default function SignupPage() {
  const query = useQuery();
  const { LL } = useI18nContext()
  const form = useForm({ defaultValues: { email: query.get('email') } });

  const [accountType, setAccountType] = useState<'business' | 'natural'>('natural');

  return (
    <Card className={styles.card} bodyClassName={styles.cardBody}>
      <div className={styles.header}>
        <h2>{LL.signupPage.title()}</h2>
        <p> 
          {LL.signupPage.loginPrompt()}
          <Link to='/login'>{LL.signupPage.loginLink()}</Link>
        </p>
      </div>
      <div className={styles.typeSelector}>
        <AccountTypeBox
          type='personal'
          selected={accountType === 'natural'}
          onClick={() => setAccountType('natural')} />
        <AccountTypeBox
          type='business'
          selected={accountType === 'business'}
          onClick={() => setAccountType('business')} />
      </div>
      {
        accountType === 'natural'
          ? <SignupForm accountType={accountType} />
          : <p className={styles.businessHelp}>{LL.signupPage.businessAccountHelp()}</p>
      }
    </Card>
  );
}
