import { useEffect, useMemo, useState } from "react";
// import { errorParser } from "utils/requests/errorParser";
import { useSelector } from "react-redux";
import { ApplicationState } from "modules";
import { message } from "@sis-lab/web-ui-components";
import { CorpoFile, FetchFileFunction } from '../types'
import extractDocumentInfo from "./extractDocumentInfo";
import visualizeXml from "./visualizeXml";


export default function useFetchFile(fetchFile: FetchFileFunction, documentId: string) {
  const [loading, setLoading] = useState(false);
  const [filePreview, setFilePreview] = useState<CorpoFile>();
  const [fileDownload, setFileDownload] = useState<CorpoFile>();

  const userSub = useSelector((state: ApplicationState) => state.authentication.user?.sub);
  const parentSub = useSelector((state: ApplicationState) => state.authentication.user?.parentSub);
  // ! later accept only file id, standartize file download across COR and PAY 
  const memoizedFetchFunction = useMemo(() => fetchFile, [documentId])

  const fetchAndTransformFile = async () => {
    if (!documentId) return;
    setLoading(true);

    try {
      const response = await memoizedFetchFunction();
      const [blob, fileName, contentType, type] = extractDocumentInfo(response)
      setFileDownload({ blob, fileName });

      if (['application/xml','text/xml'].includes(contentType)) {
        await visualizeXml({ blob, fileName, type }, setFilePreview, parentSub || userSub);
      }

    } catch (e) {
      message.error('Error during file fetching');
      // eslint-disable-next-line no-console
      // errorParser(e, err => console.log(err.response))
    }
    setLoading(false)
  };
  
  useEffect(() => {
    fetchAndTransformFile()
  }, [memoizedFetchFunction])

  return [filePreview, fileDownload, loading] as const
}
