import React, { useEffect, useState } from 'react';
import { useBreakpoints } from '@sis-lab/web-ui-components';
import { connect } from 'react-redux';
import { ApplicationState } from 'modules';
import CorpoHeader from './components/CorpoHeader/CorpoHeader';
import CorpoSider from './components/CorpoSider/CorpoSider';
import CorpoFooter from './components/CorpoFooter/CorpoFooter';
import AuthHeader from './components/AuthHeader/AuthHeader';
import styles from './Layout.module.scss';


interface Props {
  authenticated?: boolean
  children?: React.ReactNode;
  initialized: boolean
}

const mapStateToProps = (state: ApplicationState) => ({
  authenticated: state.authentication.authenticated,
  initialized: state.authentication.initialized
});

function Layout({ children, authenticated, initialized  }: Props) {
  const [currentNav, setCurrentNav] = useState<'auth' | 'sider' | 'header'>()
  const { tabletSmall, mobile } = useBreakpoints() || {};

  useEffect(() => {
    if (!initialized) return
    if (!authenticated) setCurrentNav('auth')
    else if (tabletSmall || mobile) setCurrentNav('header')
    else setCurrentNav('sider')    
  }, [authenticated, tabletSmall, initialized, mobile])

  return (
    <div className={[styles.layout, (currentNav === 'auth' || currentNav === 'header') && styles.column].join(' ')}>
      {currentNav === 'auth' && <AuthHeader /> }
      {currentNav === 'header' && <CorpoHeader /> }
      {currentNav === 'sider' && <CorpoSider /> }
      <main>
        {children}
        <CorpoFooter />
      </main>
    </div>
  );
}

export default connect(mapStateToProps)(Layout);
