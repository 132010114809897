import React from 'react';
import { ColumnProps } from '@sis-lab/web-ui-components';
import { transformDate } from 'utils';
import { TranslationFunctions } from 'i18n/i18n-types';
import Stamp from 'components/Stamp/Stamp';
import styles from './signatureColumns.module.scss';
import { SingatureTableData } from '../utils';

const SignatureColumns = (LL: TranslationFunctions): ColumnProps<SingatureTableData>[] => [
  {
    title: 'Document title',
    width: 'auto',
    dataIndex: 'title',
    ellipsis: true,
    render: (text, record) => (
      <div className={styles.title}>
        <h3>{record.title}</h3>
        <p>{LL.documentTypes[record.type as 'workOrder']() || record.type}</p>
      </div>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '225px',
    render: (val: string, record) => (
      <Stamp status={record.status}>
        {transformDate(record.uploaded).substring(0, 16)}
      </Stamp>
    ),
  },
];

export default SignatureColumns;
