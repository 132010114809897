import React, { ReactNode } from 'react'
import styles from './PageHeader.module.scss';

interface Props {
  title: string
  children?: ReactNode
}


export default function PageHeader({ title, children }: Props) {
  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <h1>{title}</h1>
      </div>
      <div className={styles.children}>
        {children}
      </div>
    </div>
  )
}
