import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Header } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import corposignLogo from 'images/corposign-logo.svg';
import MobileModal from '../MobileModal/MobileModal';
import styles from './CorpoHeader.module.scss';


export default function CorpoHeader() {
  const [isOpen, setIsOpen] = useState(false);
  const { LL } = useI18nContext()
  const history = useHistory();

  return (
    <>
      <Header
        className={styles.header}
        onTitleClick={() => history.push('/')}
        logo={corposignLogo}
        title={<h1 className={styles.title}>CorpoSign</h1>}
        mobileBtnProps={{
          type: 'secondary',
          onClick: () => setIsOpen(state => !state),
          children: LL.header.menu()
        }}
     />
     <MobileModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
}
