import React from 'react';
import { useBreakpoints } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import TableFooter from 'components/TableFooter/TableFooter';
import Search from 'components/Search/Search';
import PageHeader from 'components/PageHeader/PageHeader';
import DetailedDocumentsTable from 'components/DetailedDocumentTable/DetailedDocumentTable';
import { ColumnsWithOwner, DefaultColumns } from 'components/DetailedDocumentTable/columns';
import useFetchDocuments from './useFetchDocuments';
import styles from './OrganizationDocPage.module.scss';


export default function OrganizationDocPage() {
  const [documents, loading, isFullyLoaded, fetchDocuments] = useFetchDocuments();
  const { mobile } = useBreakpoints() || {}
  const { LL } = useI18nContext()

  return (
    <div className={styles.page}>
      <PageHeader title={LL.organizationDocuments.title()} />
      <div className={styles.body}>
        <Search onSearch={fetchDocuments} />
        <DetailedDocumentsTable
          loading={loading}
          data={documents}
          columns={mobile ? [DefaultColumns(LL)[0]] : ColumnsWithOwner(LL) }
        />
        <TableFooter
          isFullyLoaded={isFullyLoaded}
          isLoading={loading}
          onLoadMore={() => fetchDocuments({})}
        />
      </div>
    </div>
  );
}
