import React from 'react'
import { useQuery } from 'utils';
import ForgotPasswordInit from './components/ForgotPasswordInit/ForgotPasswordInit';
import ForgotPasswordSuccess from './components/ForgotPasswordSuccess/ForgotPasswordSuccess';


export default function ForgotPasswordPage() {
  const query = useQuery();

  return query.get('username') ? <ForgotPasswordSuccess/> : <ForgotPasswordInit/>
}
