import React from "react";
import { Icon, IconsType, Tooltip } from "@sis-lab/web-ui-components";
import { useI18nContext } from "i18n/i18n-react";
import { PrmStatus } from "apis/prmApi";
import { transformDate } from "utils";
import styles from './StatusField.module.scss'

interface Props {
  status: PrmStatus
  updatedAt: string
}

const statusToIcon = (status: PrmStatus): IconsType => {
  switch (status) {
    case 'created': return 'add_circle_outline'
    case 'paid': return 'check_circle_outline'
    case 'delivered': return 'check_circle_outline'
    case 'rejected': return 'close_circle_outline'
    case 'accepted': return 'schedule_outline'
    case 'canceled': return 'close_circle_outline'
    case 'started': return 'schedule_outline'
    case 'received': return 'check_circle_outline'
    case 'invited': return 'mail_outline' as 'arrow_down'
    case 'sent': return 'mail_outline' as 'arrow_down'
    case 'responded_error': return 'done_all' as 'arrow_down'
    case 'responded_warning': return 'done_all' as 'arrow_down'
    case 'responded_ok': return 'done_all' as 'arrow_down'
    case 'assigned': return 'mail_outline' as 'arrow_down'
    default: return 'account_circle'
  }
}

export default function StatusField ({ status, updatedAt }: Props) {
  const { LL } = useI18nContext()
  return (
    <div className={styles.statusField}>
      <div className={[styles.header].join(' ')}>
        <p>{LL.commonFields.status.title()}</p>
        <Tooltip text='todo status description' iconClassName={styles.tooltip} />
      </div>
      <div className={[styles.input, styles[status]].join(' ')}>
        <Icon className={styles.prefix} name={statusToIcon(status)} />
        <input disabled id='statusField' value={LL.paymentStatus[status]()} />
        <p className={styles.description}>{transformDate(updatedAt).substring(0, 16)}</p>
      </div>
    </div>
  )
}
