import React from "react";
import PageHeader from "components/PageHeader/PageHeader";
import MembersTable from "./components/MembersTable/MembersTable";
import SealCard from "./components/SealCard/SealCard";
import styles from './SealPage.module.scss'

export default function SealsPage() {
  return (
    <div className={styles.page}>
      <PageHeader title='Seal' />
      <div className={styles.body}>
        <div>
          <SealCard />
        </div>
        <MembersTable />
      </div>
    </div>
  )
}
