import React from 'react';
import { Button, LangSelector, Locale } from '@sis-lab/web-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { useI18nContext } from 'i18n/i18n-react';
import { transformDate } from 'utils';
import { loadLocaleAsync } from 'i18n/i18n-util.async';
import { setMetadataAction } from 'modules/authentication/actions';
import { ApplicationState } from 'modules';
import auth, { UserMetadata } from 'apis/auth';
import config from 'config';
import { availableLocales } from 'components/LocalizationProvider/LocalizationProvider';
import styles from './CorpoFooter.module.scss';


export default function CorpoFooter() {
  const { LL } = useI18nContext();
  const { locale, setLocale } = useI18nContext()
  const dispatch = useDispatch()
  const metadata = useSelector((state: ApplicationState) => state.authentication.metadata)

  const handleLangChange = async (langCode: Locale) => {
    localStorage.setItem('lang', langCode)
    await loadLocaleAsync(langCode);
    setLocale(langCode)

    if (metadata) {
      const newPreferences: UserMetadata = { ...metadata, preferences: { ...metadata.preferences, language: langCode } }
      setMetadataAction(newPreferences)(dispatch)
      await auth.updateUserMetadata(newPreferences)
    }
  }

  return <footer className={styles.footer}>
    <div className={styles.privacyWrapper}>
      <p>© {new Date().getFullYear()} CorpoSign.</p>
      <a href='https://www.corposign.net/privacy-policy/'>{LL.footer.policy()}</a>
    </div>

    <div className={styles.btnContainer}>
      <Button
        icon='info_outline'
        type='secondary'
        className={styles.btn}
        // eslint-disable-next-line no-alert
        onClick={() => alert(`
          Release date: ${transformDate(config.projectCommitDate)}
          Version: ${config.projectCommitShortSHA}
        `)}
      />
      <Button
        type='secondary'
        children={LL.footer.help()}
        onClick={() => window.open('mailto:support@corposign.net')}
        suffix='help_outline'
        className={[styles.btn, styles.help].join(' ')}
      />
      <LangSelector
        availableLocales={availableLocales}
        currentLocale={locale as 'en'}
        onChange={handleLangChange}
      />
    </div>
  </footer>
}
