import React from "react";
import { Card, CardHeader, Form, Input, useForm } from "@sis-lab/web-ui-components";
import { transformDate } from "utils";
import { useI18nContext } from 'i18n/i18n-react';
import useFetchSeal from "./useFetchSeal";
import styles from './SealCard.module.scss'


export default function SealCard() {
  const seal = useFetchSeal()
  const form = useForm()
  const { LL } = useI18nContext()
  

  return (
    <Card loading={!seal}>
      <CardHeader title={LL.sealPage.infoCard.title()} />
      <Form disabled className={styles.form} formHook={form}>
        <div className={styles.row}>
          <Input
            name='name'
            title={LL.paymentsTab.name.title()}
            value={seal?.name}
          />
          <Input
            name='email'
            title={LL.commonFields.email.title()}
            value={seal?.email}
          />
        </div>

        <Input
          name='sub'
          title={LL.commonFields.sub.title()}
          value={seal?.sub}
        />
        <Input
          name='parentSub'
          title={LL.commonFields.parentSub.title()}
          value={seal?.parentSub}
        />
        <div className={styles.row}>
          <Input
            name='created'
            title={LL.documentInfoCard.creationDate.title()}
            value={transformDate(seal?.createdAt || '') }
          />
          <Input
            name='updated'
            title={LL.documentInfoCard.updateDate.title()}
            value={transformDate(seal?.updatedAt || '') }
          />
        </div>
      </Form>
    </Card>
  );
}
