import React, { useContext } from "react"
import PageHeader from "components/PageHeader/PageHeader"
import DocumentContext from "../DocumentContext/DocumentContext"


export default function DocumentHeader() {
  const { document } = useContext(DocumentContext)

  return (
    <PageHeader title={document?.title || ''} />
  )
}
