import React, { useState } from 'react';
import { Button, Card, CardFooter, Form, Input, useForm } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import styles from './TokenCard.module.scss';

interface Props {
  id: string
  name: string
  isPaused: boolean
  dateAdded: string
  deleteToken: (id: string) => Promise<void>
  updateToken: (id: string, paused:boolean) => Promise<void>
}

export default function TokenCard({ id, name, isPaused, dateAdded, updateToken, deleteToken }: Props) {
  const [loading, setLoading] = useState(false);
  const { LL } = useI18nContext()
  const form = useForm();

  const onToogle = async () => {
    setLoading(true)
    await updateToken(id, !isPaused);
    setLoading(false)
  };

  const onDelete = async () => {
    setLoading(true)
    await deleteToken(id);
    setLoading(false)
  };

  return (
    <Card loading={loading}>
      <Form className={styles.tokenCard} formHook={form}>
        <Input
          defaultValue={name}
          disabled
          name='name'
          title={LL.tokenTab.tokenName.title()}
        />
        <Input
          defaultValue={!isPaused ? LL.common.enabled() : LL.common.disabled()}
          disabled
          name='status'
          prefix={!isPaused ? 'check_circle' : 'close_circle_outline'}
          styleType={!isPaused ? 'positive' : 'negative'}
          title={LL.commonFields.status.title()}
        />
        <Input
          defaultValue={dateAdded}
          disabled
          name='date'
          title={LL.commonFields.dateAdded.title()}
        />
      </Form>
      <CardFooter>
        <Button
          children={!isPaused ? LL.commonButtons.disable() : LL.commonButtons.enable()}
          className={styles.toogleBtn}
          disabled={loading}
          onClick={onToogle}
          type='secondary'
        />
        <Button
          children={LL.commonButtons.remove()}
          className={styles.removeBtn}
          onClick={onDelete}
          state='warning'
          type='secondary'
        />
      </CardFooter>
    </Card>
  );
}
