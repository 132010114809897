import { Button, Input, Skeleton, useForm } from '@sis-lab/web-ui-components';
import React, { useState } from 'react';
import { useI18nContext } from 'i18n/i18n-react';
import { GenerateTokenError } from 'apis/auth';
import { errorParser } from 'utils/requests/errorParser';
import NewTokenModal from './components/NewTokenModal/NewTokenModal';
import TokenCard from './components/TokenCard/TokenCard';
import useApiTokens from './components/useApiTokens';
import styles from './TokensTab.module.scss';


export default function TokensTab() {
  const [tokens, createToken, updateToken, deleteToken] = useApiTokens();
  const [loading, setLoading] = useState(false)
  const [newToken, setNewToken] = useState<string>()
  const { LL } = useI18nContext()
  const form = useForm();

  const onCreate = async () => {
    form.clearErrors()
    const name = form.getValues('name') as string;

    if (name.length < 1) {
      form.setError('name', { message: LL.errors.auth.generateTokenErrors.noName() })
      return
    }
    
    if (tokens?.some(token => token.name === name)) {
      form.setError('name', { message: LL.errors.auth.generateTokenErrors.nameConflict() })
      return 
    }

    setLoading(true)
    createToken(name)
      .then(accessToken => setNewToken(accessToken))
      .finally(() => setLoading(false))
      .catch(e => errorParser(e, err => {
        form.setError('name', {message: LL.errors.auth.generateTokenErrors[err.response?.data.message as GenerateTokenError]() })
      }))
  }

  return (
    <div className={styles.tokenTab}>
      <Input
        wrapperClassName={styles.addInput}
        bodyClassName={styles.addInputBody}
        name='name'
        formHook={form}
        placeholder={LL.tokenTab.newTokenInput.placeholder()}
        rules={{ minLength: { value: 1, message: 'Enter token name' } }}
        prefix='add_circle_outline'
        onSuffix={onCreate}
        descr={(
          <Button
            disabled={loading}
            className={styles.addButton}
            onClick={onCreate}
            children={LL.tokenTab.newTokenInput.buttonSuffix()} 
            mobileIcon='add'
            suffix='add'
          />
        )}
      />
      {!tokens && new Array(4).fill(1).map(() => <Skeleton height={280} style={{ borderRadius: 16 }} />)}
      {tokens?.map((token) => (
        <TokenCard
          key={token.id}
          id={token.id}
          name={token.name}
          isPaused={token.paused}
          dateAdded={token.createdAt.substring(0, 10)}
          updateToken={updateToken}
          deleteToken={deleteToken}
        />
      ))}
      {tokens?.length === 0 && (
        <div className={styles.noTokens}>
          <h3>No tokens</h3>
          <p>Create new token</p>
        </div>
      )}
      <NewTokenModal
        newToken={newToken}
        tokenName={form.getValues('name')}
        afterCreation={() => setNewToken(undefined)}
      />
    </div>
  );
}
