import { Button, Card, Divider, Form, Input, Password, useForm } from '@sis-lab/web-ui-components';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useI18nContext } from 'i18n/i18n-react';
import { resetInitAction } from 'modules/authentication/actions';
import keycloak from 'apis/keycloak';
import { AxiosError } from 'axios';
import styles from './PasswordLogin.module.scss';

type Props = {
  switchToVpLogin: () => void
}

export default function PasswordLogin({ switchToVpLogin }:Props) {
  const [isLoading, setIsLoading] = useState(false);
  const { LL } = useI18nContext()
  const dispatch = useDispatch();
  const form = useForm();

  const onLogin = async () => {
    form.clearErrors();
    try {
      const username: string = form.getValues('username');
      const password: string = form.getValues<string>('password');

      if (!username) {
        form.setError('username', { message: 'Enter your username/email' });
      }
      if (!password) {
        form.setError('password', { message: 'Enter your password' });
      }
      if (!username || !password) return;

      setIsLoading(true);
      const tokenInfo = await keycloak.getKeycloakToken({ username, password });
      localStorage.setItem('refreshToken', tokenInfo.refresh_token);
      localStorage.setItem('accessToken', tokenInfo.access_token);
      resetInitAction()(dispatch);
    } catch (e: any) {
      if (e instanceof AxiosError) {
        form.setError('username', {});
        form.setError('password', { message: `${LL.errors.auth.loginError()}. Keycloak: ${e.response?.data.error_description}` })
      } else {
        form.setError('username', {});
        form.setError('password', { message: `Some error occurred, try again later, or contact SIS: support@corposign.net` })
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className={styles.card}>
      <div className={styles.header}>
        <h2>{LL.loginPage.title()}</h2>
        <p>
          {LL.loginPage.signupPrompt()}
          <Link to='/signup'>{LL.loginPage.signupLink()}</Link>
          {/* <a href='/' >Login with VP</a> */}
        </p>
      </div>
      <Divider size='tiny' />
      <Form formHook={form} className={styles.emailForm}>
        <Input
          required
          name='username'
          title={LL.commonFields.username.title()}
          placeholder={LL.commonFields.username.placeholder()}
        />
        <Password
          required
          name='password'
          title={LL.commonFields.password.title()}
          placeholder={LL.commonFields.password.placeholder()}
        />
        <Divider size='tiny' />
        <Button
          disabled={isLoading}
          onClick={onLogin}
          children={LL.loginPage.loginButton()}
        />
        <p style={{display: 'flex', justifyContent: 'space-between'}}>
          <Link to='/login' onClick={() => switchToVpLogin()}>Login with VP</Link>
          <Link to='/forgot-password'>{LL.loginPage.forgotPassword()}</Link>
        </p>
      </Form>
    </Card>
  );
}
