import React from 'react';
import { useI18nContext } from 'i18n/i18n-react';
import { useBreakpoints } from '@sis-lab/web-ui-components';
import PageHeader from 'components/PageHeader/PageHeader';
import Search from 'components/Search/Search';
import TableFooter from 'components/TableFooter/TableFooter';
import DetailedDocumentsTable from 'components/DetailedDocumentTable/DetailedDocumentTable';
import { ColumnsWithOwner, DefaultColumns } from 'components/DetailedDocumentTable/columns';
import useFetchInvited from './components/useFetchInvited';
import styles from './MyInvitesPage.module.scss'


function MyInvitesPage() {
  const [documents, loading, isFullyLoaded, fetchDocs] = useFetchInvited();
  const { mobile } = useBreakpoints() || {}
  const { LL } = useI18nContext()

  return (
    <div className={styles.page}>
      <PageHeader title={LL.invitesPage.title()} />
      <div className={styles.body}>
        <Search onSearch={() => null} />
        <DetailedDocumentsTable 
          data={documents}
          loading={loading}
          columns={mobile ? [DefaultColumns(LL)[0]] : ColumnsWithOwner(LL)}
        />
        <TableFooter
          isFullyLoaded={isFullyLoaded}
          isLoading={loading}
          onLoadMore={() => fetchDocs({})}
        />
      </div>
    </div>
  );
}

export default MyInvitesPage;
