import axios from 'axios';
import { instance } from './instance';
import { PrmFromXml } from './types';


export const transformXmlWithXslt = async (file: any, targetSub?: string, key?: string) => {
  const formData = new FormData();
  formData.append('file', file, 'transformation.xml');
  if (key) formData.append('key', key);
  if (targetSub) formData.append('targetSub', targetSub);

  return instance.post('/xslt/transform', formData);
}

export const transformXmlIntoPrm = async (file: any, targetSub?: string, key?: string) => {
  const formData = new FormData();
  formData.append('file', file, 'transformation.xml');
  if (key) formData.append('key', key);
  if (targetSub) formData.append('targetSub', targetSub);

  return instance.post<PrmFromXml>('/xslt/prm', formData);
}

export const visualizeXmlWithXslt = async (file: any, targetSub?: string, key?: string) => {
  const formData = new FormData();
  formData.append('file', file, 'visualization.xml');
  if (key) formData.append('key', key);
  if (targetSub) formData.append('targetSub', targetSub);

  return instance.post('/xslt/visualize', formData);
}

export const findKeyByXml = async (file: any, targetSub?: string) => {
  const formData = new FormData();
  formData.append('file', file, 'transformation.xml');
  if (targetSub) formData.append('targetSub', targetSub);

  return axios.put<{ key: string }>('/xsd/findKey', formData);
}