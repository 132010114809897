import eDeliveryApi from "apis/eDelivery"
import { useState } from "react"
import { errorParser } from "utils/requests/errorParser";


export default function useReceiveMessages(updateMessagesInfo: () => Promise<void>) {
  const [loading, setLoading] = useState(false)

  const receiveMessages = async () => {
    setLoading(true)
    eDeliveryApi.receiveNewMessages()
      .then(() => {})
      // eslint-disable-next-line no-alert
      .catch(e => errorParser(e, err => alert(`Error: ${err.response?.data.message}`)))
      .finally(() => { updateMessagesInfo().then(() => setLoading(false)) })
  }

  return [loading, receiveMessages] as const
}
