import React, { useState } from 'react';
import { Button, Switch, Tab, Tabs, useBreakpoints } from '@sis-lab/web-ui-components';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useI18nContext } from 'i18n/i18n-react';

import { logoutAction } from 'modules/authentication/actions';
import PageHeader from 'components/PageHeader/PageHeader';
import ProfileTab from './components/ProfileTab/ProfileTab';
import SecurityTab from './components/SecurityTab/SecurityTab';
import TokensTab from './components/TokensTab/TokensTab';
import PaymentsTab from './components/PaymentsTab/PaymentsTab';
import PeppolTab from './components/PeppolTab/PeppolTab';
import styles from './ProfilePage.module.scss';
import CredentialsTab from './components/CredentialsTab/CredentialsTab';

interface RouteParams {
  tab?: string
}


export default function ProfilePage() {
  const { tab } = useParams<RouteParams>();
  const [advanced, setAdvanced] = useState(false);
  const { LL } = useI18nContext() 
  const dispatch = useDispatch()
  const history = useHistory();
  const { tabletSmall, mobile } = useBreakpoints() || {};

  const tabs = [
    <Tab label={LL.profilePage.profileTab()} element={<ProfileTab />} />
  ]

  if (advanced) tabs.push(
    <Tab label='Payments' element={<PaymentsTab />} />,
    <Tab label='Peppol' element={<PeppolTab />} />,
    <Tab label={LL.profilePage.securityTab()} element={<SecurityTab />} />,
    <Tab label={LL.profilePage.tokenTab()} element={<TokensTab />} />,
    <Tab label='Credentials' element={<CredentialsTab />} />
  )

  return (
    <div id='ProfilePage' className={styles.page}>
      <PageHeader title={LL.profilePage.title()}>
        <Switch 
          reversed
          checked={advanced}
          className={styles.switch}
          onChange={() => setAdvanced(!advanced)}
          // children={LL.uploadSettingsCard.provideLocation()}
          children='Advanced'
        />
        {(!tabletSmall && !mobile) && (
          <Button
            children={LL.common.logoutButton()}
            className={styles.logout}
            onClick={() => logoutAction()(dispatch)}
            suffix='logout'
            type='secondary'
          />
        )}
      </PageHeader>
      <Tabs
        activeTab={!advanced ? 0 : undefined}
        bodyClassName={styles.body}
        defaultTab={parseInt(tab || '0', 10)}
        onTabChange={(newTab) => history.push(`/profile/${newTab}`)}
        tabsClassName={styles.tabsHeader}
      >
        { tabs }
      </Tabs>
    </div>
  );
}
