import axios, { AxiosResponse } from 'axios';
import { instance } from './instance';
import { IDocumentUploadRequest } from './types';

export const getStoredDocument = (id: string): Promise<AxiosResponse<any>> => instance
  .get<any>(`/files/${id}`, { responseType: 'arraybuffer' });

export const getStoredDocumentByUri = (uri: string) => (
  axios.get<ArrayBuffer>(uri, { headers: instance.defaults.headers.common, responseType: 'arraybuffer' })
)

export const addDocument = (request: IDocumentUploadRequest) => instance.post<any>('/documents', request);
