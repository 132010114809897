import React from 'react';
import {
  Button,
  Modal,
  TextArea,
  useForm,
} from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import { downloadBlobFile } from 'utils';
import styles from './NewTokenModal.module.scss';

interface Props {
  afterCreation: () => void;
  tokenName: string;
  newToken?: string;
}


export default function NewTokenModal({ newToken, tokenName, afterCreation }: Props) {
  const { LL } = useI18nContext()
  const form = useForm();
  const downloadToken = () => {
    const blob = new Blob([newToken || ''], { type: 'text/plain' });
    downloadBlobFile(blob, `${tokenName}.txt`);
  };

  return (
    <Modal
      open={!!newToken}
      width={720}
      onCancel={afterCreation}
      title={LL.tokenTab.newTokenModalTitle()}
      footer={
        <div className={styles.modalFooter}>
          <Button
            type='secondary'
            onClick={downloadToken}
            children={LL.tokenTab.downloadButton()}
          />
          <Button
            suffix='integration'
            copyText={newToken || ''}
            children={LL.commonButtons.copyToClipboard()}
          />
        </div>
      }
    >
      <TextArea
        disabled
        bodyClassName={styles.textArea}
        defaultValue={newToken}
        errorMessage={LL.tokenTab.newTokenField.warning()}
        formHook={form}
        name='newToken'
        title={LL.tokenTab.newTokenField.title()}
        wrapperClassName={styles.textAreaWrapper}
      />
    </Modal>
  );
}
