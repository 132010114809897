import React from 'react';
import { Table, useBreakpoints } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import { ISignature } from 'apis/contract';
import { DefaultColumns, convertSignature, SingatureTableData } from './columns';
import styles from './SignatureTable.module.scss';

interface Props {
  signatures: ISignature[]
  onClick: (id: string) => void;
  isLoading?: boolean
}

export default function SignatureTable({ signatures, onClick, isLoading }: Props) {
  const { mobile } = useBreakpoints() || {}
  const { LL } = useI18nContext()

  return <Table<SingatureTableData>
    showHeader={false}
    pagination={false}
    columns={mobile ? [DefaultColumns(LL)[0]] : DefaultColumns(LL)}
    className={isLoading ? styles.loadingTable : ''}
    dataSource={signatures.map((signature) => convertSignature(signature))}
    rowKey='id'
    onRow={(signature) => ({ onClick: () => onClick(signature.id) })}
    loading={isLoading}
  />
}
