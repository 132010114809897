import { Input, InputProps } from "@sis-lab/web-ui-components";
import React from "react";


export default function CurrencyInput({ formHook, name, title, ...rest }: InputProps) {
  if (!formHook) return null

  const onChange = () => {
    formHook.clearErrors(name);
    const amountStr = formHook.getValues(name) as string | undefined
    if (!amountStr) return
    let newAmount = parseFloat(amountStr)

    if (newAmount === 0) return
    if (newAmount.toString().split('.')[1]?.length > 2) newAmount = parseFloat(newAmount.toFixed(2))
    // if (newAmount < 0.1) newAmount = Math.max(newAmount * -1, 0.1);

    formHook.setValue(name, newAmount)
  }

  return (
    <Input
      name={name}
      formHook={formHook}
      title={title}
      type='number'
      prefix={'euro' as 'arrow_down'}
      onChange={onChange}
      debaunceTimeMs={0}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  )
}
