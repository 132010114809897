import React from 'react';
import { Button } from '@sis-lab/web-ui-components';
import { scrollToTop } from 'utils';
import styles from './TableFooter.module.scss';

interface Props {
  isLoading?: boolean
  onLoadMore?: () => void
  isFullyLoaded?: boolean
}

export default function TableFooter({ isLoading, onLoadMore, isFullyLoaded }: Props) {
  return <div className={[styles.tableFooter, (isLoading || isFullyLoaded) && styles.hidden].join(' ')}>
    <Button
      children='Load More'
      className={isFullyLoaded ? styles.hidden : ''}
      onClick={onLoadMore}
      type='secondary'
    />
    <Button
      type='secondary'
      suffix='arrow_up'
      children='Back to Top'
      onClick={scrollToTop}
    />
</div>
}
