import React from "react";
import SealProvider from "./components/SealContext/SealProvider";
import SealPage from "./SealPage";


export default function SealWrapper() {
  return (
    <SealProvider>
      <SealPage />
    </SealProvider>
  )
}
