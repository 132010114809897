/* eslint-disable react/jsx-no-constructed-context-values */
import React, { ReactNode } from "react";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import { useParams } from "react-router-dom";
import SealsContext from "./SealContext";
import useFetchMembers from "./useFetchMembers";

interface Props {
  children: ReactNode
}

export default function SealsProvider({ children }: Props) {
  const { sealId } = useParams<{ sealId: string }>();
  const {
    members,
    loading,
    fullyLoaded,
    error,
    setMembers,
    getMoreMembers,
    searchMembers
  } = useFetchMembers(sealId)

  if (error || !sealId) return <ErrorPage errorMessage={error || 'No seal id'} />
  return (
    <SealsContext.Provider 
      value={{
        members,
        loading,
        fullyLoaded,
        sealId,
        setMembers,
        getMoreMembers,
        searchMembers
      }}
    >
      {children}
    </SealsContext.Provider>
  )
}
