import { Button, message } from "@sis-lab/web-ui-components";
import prmApi from "apis/prmApi";
import { useI18nContext } from "i18n/i18n-react";
import PrmContext from "pages/PaymentPage/components/PrmContext/PrmContext";
import React, { useContext, useState } from "react";
import { errorParser } from "utils/requests/errorParser";


export default function PayNowButton() {
  const { LL } = useI18nContext()
  const { prm } = useContext(PrmContext)
  const [loading, setLaoding] = useState(false)
  if (!prm) return <div/>
  
  const initiatePrm = async () => {
    setLaoding(true)

    await prmApi.initiatePrm(prm?.id)
      .then(({ data }) => window.open(data.gatewayLink))
      .catch(e => errorParser(e, () => message.error(`This request can/'t be initiated now`)))
      .finally(() => setLaoding(false))
  }

  return (
    <Button
      children={LL.paymentPage.payNowButton()}
      suffix={!loading ? 'arrow_right' : 'loading'}
      disabled={loading}
      onClick={initiatePrm}
    />
  )
}
