import { AxiosError } from 'axios';
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useI18nContext } from 'i18n/i18n-react';
import { Button, Card, CardHeader, CardFooter, Icon, Input, useForm } from '@sis-lab/web-ui-components'

import auth from 'apis/auth';

import styles from './ForgotPasswordInit.module.scss';

export default function ForgotPasswordInit() {
  const [isLoading, setIsLoading] = useState(false);
  const { LL } = useI18nContext();
  const history = useHistory();
  const form = useForm();

  const onReset = async () => {
    // ! for some reason form.clearErrors is not changing the state immediately
    form.clearErrors();
    await new Promise(resolve => setTimeout(resolve, 10));

    const username = form.getValues('username');
    if (Object.keys(form.formState.errors).length !== 0) return;

    try {
      setIsLoading(true)
      await auth.startPasswordReset(username)
      history.push(`./forgot-password?username=${username}`)
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err.response?.status === 404) {
          form.setError('username', { message: 'User with such username was not found' })
        } else {
          form.setError('username', { message: err.response?.data?.message || 'Some error occured, try again later' })
        }
      }
  } finally {
    setIsLoading(false)
    }
  }

  return (
    <Card className={styles.card}>
      <CardHeader
        title={LL.forgotPassword.title()}
        prefix={<Icon onClick={() => history.push('/login')} name='arrow_left' />}
      />
      <Input
        name='username'
        formHook={form}
        title={LL.commonFields.username.title()}
        placeholder={LL.commonFields.username.placeholder()}
      />
      <CardFooter
        className={styles.footer}
      >
        <Button 
          disabled={isLoading}
          suffix={isLoading ? 'loading' : undefined}
          onClick={onReset}
          children={LL.commonButtons.continue()}
        />
      </CardFooter>
    </Card>
  );
}
