import React from 'react';
import { Avatar, ColumnsType } from '@sis-lab/web-ui-components';
import { TranslationFunctions } from 'i18n/i18n-types';
import Stamp from 'components/Stamp/Stamp';
import { IDocumentBasic } from 'apis/contract';
import { transformDate } from 'utils';
import styles from './ColumnsWithOwner.module.scss';


const ColumnsWithOwner = (LL: TranslationFunctions): ColumnsType<IDocumentBasic> => [
  {
    title: 'Title',
    key: 'title',
    width:  'auto',
    ellipsis: true,
    render: (_text, record) => (
      <div className={styles.title}>
        <h3>{record.title}</h3>
        <p>{LL.documentTypes[record.type as 'workOrder']() || record.type}</p>
      </div>
    ),
  },
  {
    title: 'Creator',
    key: 'creator',
    width:  'auto',
    render: (_text, record) => (
      <div className={styles.creator}>
        <Avatar name={record.creator.sub} width={34} />
        <div className={styles.title}>
          <h3>{record.creator.name}</h3>
          <p>{transformDate(record.createdAt)}</p>
        </div>
      </div>
    ),
  },
  {
    title: 'Status',
    key: 'status',
    width: '225px',
    // REFACTOR: It should not require status check!
    render: (_text, document) => document.status !== 'created' ? (
      <Stamp
        children={transformDate(document.createdAt).substring(0, 16)}
        invites={document.invites}
        signatures={document.signatures || []}
        status={document.status}
      />
    ) : (
      <Stamp
        children={transformDate(document.createdAt).substring(0, 16)}
        status={document.status}
        />
    )
  },
  // {
  //   title: 'Status',
  //   key: 'status',
  //   width: '225px',
  //   // REFACTOR: It should not require status check!
  //   render: (_text, document) => document.status === 'unsigned' ? (
  //     <Stamp
  //       children={transformDate(document.createdAt).substring(0, 16)}
  //       invites={document.invites}
  //       signatures={document.signatures || []}
  //       status={document.status}
  //     />
  //   )  : (
  //     <Stamp
  //       children={transformDate(document.createdAt).substring(0, 16)}
  //       status={document.status}
  //      />
  //   )
  // },
];

export default ColumnsWithOwner
