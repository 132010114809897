import React from 'react';
import {
  Modal,
  Form,
  useForm,
  TextArea,
} from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import styles from './StatusDetailsModal.module.scss'

interface StatusDetailsModalProps {
  visible: boolean;
  onClose: () => void;
  details?: string;
}

export default function StatusDetailsModal({ visible, details, onClose }: StatusDetailsModalProps) {
  const { LL } = useI18nContext()
  const formHook = useForm();

  return (
    <Modal
      width={640}
      footer={null}
      title={LL.paymentPage.statusDetailModal.title()}
      open={visible}
      onCancel={onClose}
    >
      <Form formHook={formHook}>
        <TextArea
          disabled
          fitContent
          name='details'
          className={styles.textArea}
          defaultValue={JSON.stringify(details || 'No details', null, 2)}
        />
      </Form>
    </Modal>
  );
}