/* eslint-disable no-param-reassign */
// import { useSelector } from "react-redux"
// import { ApplicationState } from "modules"
import { useContext, useEffect, useState } from "react"
import { PRM } from "apis/prmApi/prm/types"
import { errorParser } from "utils/requests/errorParser"
import prmApi from "apis/prmApi"
import PaymentsContext from "./PaymentsContext"

// * if reset is triggered - clear up payments array and update fetchPayments function (autoupdated from context?)

export default function useGetPaymentList() {
  const { filter, abortSignal } = useContext(PaymentsContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [payments, setPayments] = useState<PRM[]>();
  const [bookmark, setBookmark] = useState<string>();
  // const sub = useSelector((state: ApplicationState) => state.authentication.user?.sub);

  // incoming -> temporary until separate backend routes or filters are created
  const fetchPayments = async (reset?: boolean) => {
    setLoading(true);

    if (reset) {
      // * setting undefined values for Skeleton to appear
      // * but they will be present in the current useEffect closure
      setPayments(undefined);
      setBookmark(undefined);
    }

    // console.log(`fetchPayments ${JSON.stringify(filter)}`)
    // console.log(payments)
    // console.log(bookmark)

    await prmApi.getPrmList({ ...filter, bookmark: !reset ? bookmark : '' }, abortSignal)
      .then(({ data }) => {
        // * removed incoming/putgoing filter, too much of a trouble to implement it with infinite table and without dedicated routes
        // * you request 10 outgoing, filter - there are none, what to do? recursive? not worth it for now
        // data.prms = data.prms.filter(({ payee }) => showIncoming && payee.id === sub || !showIncoming && payee.id !== sub)

        setPayments((!reset && payments) ? payments.concat(data.prms) : data.prms)
        setBookmark(data.bookmark);
      })
      .catch(e => errorParser(e, err => setError(err.response?.statusText)))
      .finally(() => {
        setLoading(false);
      })
  }

  const updatePayments = (newPrm: PRM) => {
    setPayments([newPrm, ...(payments || [])])
  }

  // * you can't move reset of fields into "filter" useeffect because of the stale closure
  // * if you reset fields before calling fetchPayments, fetchPayments will still have non-reset fields
  // * https://dmitripavlutin.com/react-hooks-stale-closures/#google_vignette:~:text=3.%20State%20closure%20in%20useEffect()
  // * https://stackoverflow.com/questions/73697416/react-stale-usestate-value-in-closure-how-to-fix

  // useEffect(() => {
  //   console.log('useGetPayments: reset useEffect')
  //   if (reset) {
  //     setPayments(undefined);
  //     setBookmark(undefined);
  //     setReset(false);
  //   }
  // }, [reset])

  // ? https://www.reddit.com/r/reactjs/comments/17eh3nm/is_useeffect_really_the_best_way_to_handle/
  useEffect(() => {
    // console.log('useGetPayments: filter useEffect')
    if (filter) {
      fetchPayments(true)
    }
  }, [filter])

  return [fetchPayments, updatePayments, payments, loading, !bookmark && !loading, error] as const
}
