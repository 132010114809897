/* eslint-disable no-param-reassign */
import { useState } from "react"
import contract from "apis/contract"
import { getFileFingerprint } from "utils"
import { RcFile } from "@sis-lab/web-ui-components"
import { useI18nContext } from "i18n/i18n-react"
import { DocumentInfo } from "pages/UploadPage/components/types"
import { ApplicationState } from "modules"
import aiApi, { AiPreferences } from "apis/aiApi"
import { useSelector } from "react-redux"


const useFileUpload = (currentList: DocumentInfo[], saveForPreview: boolean) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const { LL } = useI18nContext();
  const aiPreferences = useSelector<ApplicationState, AiPreferences | undefined>(state => state.authentication.aiPreferences)

  const uploadFiles = async (files: RcFile[]) => {
    setLoading(true);
    setError(undefined);
    let errorBuffer: string | undefined;

    const parsedFiles = await Promise.all(files.map(async (file) => {
      const title = file.name;
      const format = file.name.split('.').pop()?.toLowerCase() || '';
      const fingerprint = await getFileFingerprint(file);
      const buffer = await file.arrayBuffer();
      const data = Array.from(new Uint8Array(buffer));

      if (file.size / 1048576 > 50) errorBuffer = `File ${title} too big`;

      return {
        title,
        format,
        fingerprint,
        data,
        saveForPreview,
        invitees: [],
      } as DocumentInfo
    }));

    // check for duplicates inside new files
    const fingerprintSet = new Set<string>()
    let uniqueFiles = parsedFiles.filter(file => {
      if (fingerprintSet.has(file.fingerprint) || currentList.find(doc => doc.fingerprint === file.fingerprint)) {
        errorBuffer = LL.errors.uploadPage.fileAlreadyUploaded(file.title)
        return false
      }

      fingerprintSet.add(file.fingerprint)
      return file
    })

    const [{ data: distinctivness }] = await Promise.all([
      contract.checkFingerprints(uniqueFiles.map(data => data.fingerprint)),
    ])

    uniqueFiles = uniqueFiles.filter((file, index) => {
      if (distinctivness[file.fingerprint].exists) {
        errorBuffer = LL.errors.uploadPage.fileAlreadyCreated(uniqueFiles[index].title)
        return false
      }
      return true
    })

    if (aiPreferences?.enabled) {
      const predictedTypes = await Promise.all(uniqueFiles.map(file => aiApi.classifyByFileName(file.title)));

      uniqueFiles.forEach((file, index) => {
        file.type = predictedTypes[index].data
      })
    }

    // uniqueFiles.forEach(file => {
    //   const { title } = file;
    //   if ((localeContains(title, 'Nurašym') || localeContains(title, 'Atsarg')) && documentTypes.writeOff) {
    //     file.type = 'writeOff'
    //   } else if ((localeContains(title, 'Degalu') || localeContains(title, 'žurnal')) && documentTypes.petroleumProductsJournal) {
    //     file.type = 'petroleumProductsJournal'
    //   } else if (localeContains(title, 'inventor') && documentTypes.inventory) {
    //     file.type = 'inventory'
    //   }
    // })

    setLoading(false)
    setError(errorBuffer)
    return uniqueFiles
  }

  return [uploadFiles, error, loading] as const
}

export default useFileUpload
