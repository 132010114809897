import { instance } from '../instance';
import { UserMetadata } from '../types';
import { CreateUserRequest } from './types';

export const createUser = async (req: CreateUserRequest) => (
  instance.post<string | 'OK'>('/users', req)
)

export const resendSuccessCreateUser = async (email: string) => (
  instance.post('/users/email', { email })
)

export const startPasswordReset = async (username: string) => (
  instance.post('/users/start-password-reset', { username })
)

export const resetPassword = async (token: string, password: string) => (
  instance.post('/users/reset-password', { token, password })
)

export const changePassword = async (currentPassword: string, newPassword: string) => (
  instance.put('/users/change-password', { currentPassword, newPassword })
)

export const getUserMetadata = async () => (
  instance.get<UserMetadata>('/users/metadata')
)

export const updateUserMetadata = async (metadata: UserMetadata) => (
  instance.put('/users/metadata', metadata)
)
