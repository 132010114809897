import React from 'react';
import { store, history } from 'store';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import Layout from 'components/Layout/Layout';
import PrivateRoute from 'components/Routes/PrivateRoute';
import PublicRoute from 'components/Routes/PublicRoute';

import AdminDashboard from 'pages/AdminDashboard';
import Authentication from 'components/Authentication/Authentication';
import CheckEmailPage from 'pages/CheckEmailPage.tsx/CheckEmailPage';
import DocumentPage from 'pages/DocumentPage/DocumentPage';
import DocumentsPage from 'pages/DocumentsPage/DocumentsPage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage/FargotPasswordPage';
import IdentityConfirmedPage from 'pages/IdentityConfirmedPage/IdentityConfirmedPage';
import LocalizationProvider from 'components/LocalizationProvider/LocalizationProvider';
import LoginPage from 'pages/LoginPage/LoginPage';
import MobileVerificationPage from 'pages/MobileVerificationPage/MobileVerificationPage';
import MyInvitesPage from 'pages/MyInvitesPage/MyInvitesPage';
import OfflineWatcher from 'components/OfflineWatcher/OfflineWatcher';
import OrganizationDocPage from 'pages/OrganizationDocuments/OrganizationDocPage';
import OrgSignaturesPage from 'pages/OrgSignaturesPage/OrgSignaturesPage';
import PaymentPage from 'pages/PaymentPage/PaymentPage';
import PaymentsPage from 'pages/PaymentsPage/index';
import ProfilePage from 'pages/ProfilePage/ProfilePage';
import RedirectPage from 'pages/RedirectPage/RedirectPage';
import ResetPasswordPage from 'pages/ResetPasswordPage/ResetPasswordPage';
import SealPage from 'pages/SealPage';
import SealsPage from 'pages/SealsPage/index';
import SignaturesPage from 'pages/SignaturesPage/SignaturesPage';
import SignupPage from 'pages/SignupPage/SignupPage';
import SmartVerificationPage from 'pages/SmartVerificationPage/SmartVerificationPage';
import UploadPage from 'pages/UploadPage/UploadPage';
import ValidatorPage from 'pages/ValidatorPage/ValidatorPage';
import VerificationPage from 'pages/VerificationPage/VerficationPage';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import queryString  from 'query-string';


export default function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
      <QueryParamProvider
        adapter={ReactRouter5Adapter}
        options={{
          searchStringToObject: queryString.parse,
          objectToSearchString: queryString.stringify,
        }}
      >
        <LocalizationProvider>
          <OfflineWatcher/>
          <Authentication>
            <Layout>
              <Switch>
                {/* Authentication Routes */}
                <PublicRoute path='/login' component={LoginPage} />
                <PublicRoute path='/signup' component={SignupPage} />
                <PublicRoute path='/forgot-password' component={ForgotPasswordPage} />
                <PublicRoute path='/reset-password' component={ResetPasswordPage} />

                {/* Identity-verification */}
                <PublicRoute exact path='/identity-verification/:sub' component={VerificationPage}  />
                <PublicRoute exact path='/identity-verification/smartID/:sub' component={SmartVerificationPage} />
                <PublicRoute exact path='/identity-verification/mobileID/:sub' component={MobileVerificationPage} />
                <PublicRoute exact path='/identity-confirmed-kyc' children={<IdentityConfirmedPage content='kyc'/>} />
                <PublicRoute exact path='/identity-confirmed-kyb' children={<IdentityConfirmedPage content='kyb'/>} />
                <PublicRoute exact path='/identity-confirmed-member' children={<IdentityConfirmedPage content='member'/>} />

                <PublicRoute path='/redirect' children={<RedirectPage partner='Idenfy' />} />

                {/* Check email pages */}
                <PublicRoute path='/verify-email' children={<CheckEmailPage content='verification'/>} />
                <PublicRoute path='/idenfy-email' children={<CheckEmailPage content='idenfy'/>} />
                {/* <PublicRoute path='/verify-kyb' children={<CheckEmailPage content='kyb'/>} /> */}
                <PublicRoute exact path='/' component={ValidatorPage} />

                {/* Routes available only with Auth */}
                <PrivateRoute exact protection='allowedToUpload' path='/upload' component={UploadPage} />
                <PrivateRoute exact path='/documents' component={DocumentsPage} />
                <PrivateRoute exact path='/documents/:fingerprint' component={DocumentPage} />
                <PrivateRoute path='/invites' component={MyInvitesPage} />
                <PrivateRoute path='/profile/:tab' component={ProfilePage} />
                <PrivateRoute path='/signatures' component={SignaturesPage} />
                <PrivateRoute exact path='/validate' component={ValidatorPage} />

                {/* CorposignPay routes */}
                <PrivateRoute exact path='/payments' component={PaymentsPage} />
                <PrivateRoute exact path='/payments/:prmId' component={PaymentPage} />

                {/* Organization Routes */}
                <PrivateRoute exact protection='admin' path='/organization' component={AdminDashboard} />
                <PrivateRoute exact protection='admin' path='/seals' component={SealsPage} />
                <PrivateRoute exact protection='admin' path='/seals/:sealId' component={SealPage} />

                <PrivateRoute exact protection='allowedToView' path='/organization/documents' component={OrganizationDocPage} />
                <PrivateRoute
                  exact
                  protection='allowedToView'
                  path='/organization/signatures'
                  component={OrgSignaturesPage}
                />
                {/* Routes available everythere */}
                <Route exact path='/validate/:fingerprint' component={DocumentPage} />
              </Switch>
            </Layout>
          </Authentication>
          </LocalizationProvider>
      </QueryParamProvider>
      </ConnectedRouter>
    </Provider>
  );
}
  