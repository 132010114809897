import { instance } from './instance';
import { EdeliveryConfig, EdeliveryConfigUpdate, EdeliveryMessage, LastReceivedLog, MessageStatus } from './types';

export const getNewMessages = async () => (
  instance.get<EdeliveryMessage[]>('/messages')
)

export const getReceivingStatus = async () => (
  instance.get<boolean>('/receive-all-messages/status')
)

export const getEdeliveryConfig = async () => (
  instance.get<EdeliveryConfig>('/account/config')
)

export const updateEdeliveryConfig = async (newConfig: EdeliveryConfigUpdate) => (
  instance.put<boolean>('/account/config', newConfig)
)

export const getMessageStatus = async (id: string) => (
  instance.get<MessageStatus>(`messages/${id}/status`)
)

export const getLastReceivedLog = async () => (
  instance.get<LastReceivedLog>('/receive-all-messages/log')
)

export const receiveNewMessages = async () => (
  instance.post('/receive-all-messages')
) 