import { useState } from 'react';
import { DocumentUpdatableFields, IDocumentDetailed, patchDocument } from 'apis/contract';
import { useI18nContext } from 'i18n/i18n-react';
import { message } from '@sis-lab/web-ui-components';


const useUpdateDocument = (fingerprint: string, setDocument: (document?: IDocumentDetailed) => void) => {
  const [updating, setUpdating] = useState<boolean>(false);
  const { LL } = useI18nContext()
  
  const updateDocument = (fieldsToUpdate: DocumentUpdatableFields) => {
    setUpdating(true);
    patchDocument(fingerprint, fieldsToUpdate)
      .then(({ data: { document } }) => setDocument(document))
      .catch(() => { message.error(LL.errors.common.internalError(), 1) })
      .finally(() => setUpdating(false) )
  };

  return [updating, updateDocument] as const;
}

export default useUpdateDocument;
