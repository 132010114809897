import { DocumentUpdatableFields, IDocumentDetailed } from "apis/contract";
import { createContext } from "react";

export type DocumentContextType = {
  updating?: boolean
  fetching?: boolean
  document?: IDocumentDetailed
  updateDocument: (fieldsToUpdate: DocumentUpdatableFields) => void
  setDocument: (document?: IDocumentDetailed) => void
  fetchDocument: () => Promise<void>
}

export default createContext<DocumentContextType>({
  setDocument: () => null,
  updateDocument: () => null,
  fetchDocument: () => new Promise(resolve => { resolve() })  
})
