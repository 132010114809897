import React from 'react';
import { Card, CardHeader } from '@sis-lab/web-ui-components';
import { useHistory } from 'react-router-dom';
import { useI18nContext } from 'i18n/i18n-react';
import mailboxImg from 'images/mailbox.svg'
import styles from './IdenfyEmail.module.scss'


export default function IdenfyEmail() {
  const { LL } = useI18nContext()
  const history = useHistory()

  return (
    <Card className={styles.card}>
      <CardHeader title={<h2>{LL.idenfyEmailPage.title()}</h2>} /> 
      <img src={mailboxImg} alt='Verify your email'/>
      <p>{LL.idenfyEmailPage.completeMessage()}</p>
      <small>
        <button type='button' onClick={() => history.goBack()}>
          {LL.idenfyEmailPage.clickHere()}
        </button>
        {LL.idenfyEmailPage.restartText()}
      </small>
    </Card>
  )
}

