import { Card, CardHeader,Icon, Skeleton, message } from '@sis-lab/web-ui-components';
import React, { useEffect, useState } from 'react';

import auth, { User } from 'apis/auth';
import { AxiosError } from 'axios';
import { resetInitAction } from 'modules/authentication/actions';
import { useDispatch } from 'react-redux';
import styles from './AuthStep.module.scss';
import UserRow from './components/UserRow/UserRow';

type Props = {
  resetVpSession: () => void;
  vpSession: string;
}

const UsersSkeleton = <div><Skeleton count={2} /></div>

export default function AuthStep({ resetVpSession, vpSession }: Props) {
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<User[]>();
  const dispatch = useDispatch();

  const getUserToken = async (sub: string) => {
    try {
      setLoading(true);
      const { data } = await auth.getVpSessionUserToken(vpSession, sub);
      localStorage.setItem('refreshToken', data.refresh_token);
      localStorage.setItem('accessToken', data.access_token);
      resetInitAction()(dispatch);
    } catch (error) {
      if (error instanceof AxiosError) {
        setErrorMessage(error.message);
      } else {
        console.log(error);
        message.error('Could not get token for selected user: support@corposign.net');
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
  // * https://legacy.reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
    const getSessionUsers = async () => {
      try {
        const { data } = await auth.getVpSessionUsers(vpSession);
        setUsers(data);
      } catch (error) {
        if (error instanceof AxiosError) {
          setErrorMessage(error.message);
        } else {
          console.log(error);
          setErrorMessage('Unexpected error occured contact SIS: support@corposign.net');
        }
      }
    }

    getSessionUsers();
  }, [vpSession]);

  const goBack = () => {
    resetVpSession();
    setUsers(undefined);
    setErrorMessage('');
  }

  return (
    <Card className={styles.card} loading={loading}>
      <CardHeader
        title='Step 2/2: Choose account'
        prefix={<Icon onClick={goBack} name='arrow_left' />}
      />
      <div className={styles.cardBody}>
        {/* Ask AB for a new session every N min?</p> */}
        {!users && !errorMessage && UsersSkeleton}
        <p>{errorMessage}</p>
        {users && !users.length && <p>You wallet is not associated with any users</p>}
        {users && users.map(user => <UserRow user={user} onClick={() => getUserToken(user.sub)} />)}
      </div>
    </Card>
  );
}
