/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import { Button, Card, CardFooter, Form, Icon, Input, Paragraph, message, QrCode } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import issuerApi from 'apis/issuerApi';
import authApi from 'apis/auth';
import { setUserInfoAction } from 'modules/authentication/actions';
import { useDispatch } from 'react-redux';
import { errorParser } from 'utils/requests/errorParser';
import styles from './BindingCard.module.scss';

export default function BindingCard() {
  const { LL } = useI18nContext();
  const [loading, setLoading] = useState(true);
  const [deeplink, setDeeplink] = useState<string>();
  const dispatch = useDispatch()
  // * initiate bind
  // * 
  // * poll userinfo
  // * change state

  const checkBindingStatus = async () => {
    try {
      const {data: userinfo} = await authApi.getUserInfo();
      if (userinfo.did) {
          setUserInfoAction(userinfo)(dispatch)
      } else {
          setTimeout(checkBindingStatus, 2000);
      }
    } catch (error) {
        errorParser(error, err => alert(err.response?.data|| 'Some error occured'))
    }
  }

  const initiateWalletBind = () => {
    // setLoading(true);
    issuerApi.initiateWalletBind()
      .then(({ data }) => {
        setDeeplink(data.uri);
        setTimeout(checkBindingStatus, 2000);
      })
      .catch((error) => {
        errorParser(error, err => alert(err.response?.data|| 'Some error occured'))
      })
      .finally(() => setLoading(false))
    // ;
  }
  
  useEffect(initiateWalletBind, [])
  const loader = <div className={styles.loaderContainer}><Icon className={styles.loader} name='loading' /></div>
  
  return (
    <Card bodyClassName={styles.card}>
      <Paragraph>
        To start using Verifiable Credentials, attach your wallet by scanning the QR code with MyDID wallet app:
      </Paragraph>
      {
        (!loading && deeplink)
          ? (
            <QrCode
              width={300}
              height={300}
              data={deeplink}
            />
            )
          : loader
      }
    </Card>
  )
}