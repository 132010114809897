import { Button, Card, CardHeader, Divider, Password, useForm } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import React, { useState } from 'react';
import axios from 'axios';
import { passwordValidator, useQuery } from 'utils';
import auth from 'apis/auth';
import ResetSuccess from './components/ResetSuccess';
import styles from './ResetPasswordPage.module.scss';


export default function ResetPassword() {
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const { LL } = useI18nContext();
  const query = useQuery();
  const form = useForm();

  const resetPassword = async () => {
    form.clearErrors()
    const token = query.get('token') || '';
    const password = form.getValues('password');
    const passwordCheck = form.getValues('passwordCheck');

    if (!password || !passwordCheck) {
      form.setError('password', {});
      form.setError('passwordCheck', { message: LL.errors.common.resetPasswordEmpty() });
    } else if (!passwordValidator.validate(password)) {
      form.setError('password', {});
      form.setError('passwordCheck', { message: LL.errors.common.passwordWeak() });
    } else if (password !== passwordCheck) {
      form.setError('password', {});
      form.setError('passwordCheck', { message: LL.errors.common.passwordsDoNoMatch() });
    };

    // ! for some reason form.setError is not changing the state immediately
    await new Promise(resolve => setTimeout(resolve, 10));

    if (Object.keys(form.formState.errors).length !== 0) return;

    try {
      setLoading(true);
      await auth.resetPassword(token, password);
      setIsChanged(true);
    } catch (e) {
      if (!axios.isAxiosError(e))
        return;
      form.setError('password', {});
      form.setError('passwordCheck', { message: e.response?.data?.message || e.message });
    } finally {
      setLoading(false);
    }
  };

  if (isChanged) return <ResetSuccess />;
  return (
    <Card className={styles.card} bodyClassName={styles.cardBody}>
      <CardHeader className={styles.header} title={<h2>Reset Password</h2>}/>
      <Password
        formHook={form}
        name='password'
        title={LL.commonFields.password.title()}
        placeholder={LL.commonFields.password.placeholder()}
      />
      <Password
        formHook={form}
        name='passwordCheck'
        title={LL.commonFields.passwordRepeat.title()}
        placeholder={LL.commonFields.passwordRepeat.placeholder()}
        helpText={LL.commonFields.passwordRepeat.helpText()}
      />
      <Divider size='tiny' />
      <Button
        disabled={loading}
        className={styles.button}
        onClick={resetPassword}
        children={LL.resetPassword.button()}
      />
    </Card>
  );
}
