import { Caption, Icon, Label } from "@sis-lab/web-ui-components";
import { useI18nContext } from "i18n/i18n-react";
import React from "react";
import styles from './NoPayerRow.module.scss'

export default function NoPayerRow() {
  const { LL } = useI18nContext()

  return (
    <div className={styles.noPayerRow}>
      <Icon name='info_outline' className={styles.icon} />
      <div>
        <Label className={styles.label}>{LL.paymentPage.noPayerRow.mainText()}</Label>
        <Caption className={styles.caption} small>{LL.paymentPage.noPayerRow.subText()}</Caption>
      </div>
    </div>
  )
}