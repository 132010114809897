import { useEffect, useState } from 'react';
import { getDocument, IDocumentDetailed, validateDocument } from 'apis/contract';
import { useLocation } from 'react-router-dom';
import { useI18nContext } from 'i18n/i18n-react';
import { errorParser } from 'utils/requests/errorParser';


const useFetchDocument = (fingerprint: string) => {
  const [document, setDocument] = useState<IDocumentDetailed>();
  const [error, setError] = useState<string>();
  const [fetching, setFetching] = useState<boolean>();
  const { LL } = useI18nContext()
  const location = useLocation()

  const fetchDocument = async () => {
    setFetching(true);
    const isValidator = location.pathname.includes('validate')
    const fetchFunction = isValidator ? validateDocument : getDocument

    await fetchFunction(fingerprint)
      .then(({ data }) => setDocument(data))
      .catch(err => errorParser(err, e => setError(e.response?.status === 404 ?
        LL.errors.contract.getDocumentErrors.documentNotFound() :
        LL.errors.common.internalError()
      )))
      .finally(() => setFetching(false))
  };

  useEffect(() => {
    fetchDocument();
  }, [fingerprint, location.pathname])

  return [document, error, setDocument, fetching, fetchDocument] as const;
}

export default useFetchDocument;
