import { Button } from "@sis-lab/web-ui-components";
import React, { useState } from "react";
import { useI18nContext } from "i18n/i18n-react";
import InviteModal from "./InviteModal";


export default function InviteButton() {
  const [modalOpen, setModalOpen] = useState(false)
  const { LL } = useI18nContext()

  return (
    <div>
      <Button
        children={LL.paymentPage.inviteModal.startInviteButton()}
        suffix='arrow_right'
        type='primary'
        onClick={() => setModalOpen(true)}
      />
      <InviteModal open={modalOpen} onCancel={() => setModalOpen(false)} />
    </div>
  )
}
