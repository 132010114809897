import { ActionTypes, SignaturesAction, SignaturesState } from './types';

const initialState: SignaturesState = { loading: false };

const signatures = (state = initialState, action: SignaturesAction): SignaturesState => {
  switch (action.type) {
    // ====> DOCUMENTS <====
    case ActionTypes.REQUEST_GET_DOCUMENT_SIGNATURE:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.SUCCESS_GET_DOCUMENT_SIGNATURE:
      return {
        ...state,
        loading: false,
        signature: action.signature,
        error: undefined,
      };
    case ActionTypes.ERROR_GET_DOCUMENT_SIGNATURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

      // ====> DOCUMENT <====
    case ActionTypes.REQUEST_GET_DOCUMENT_SIGNATURES:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.SUCCESS_GET_DOCUMENT_SIGNATURES:
      return {
        ...state,
        loading: false,
        signatures: action.signatures,
        bookmark: action.bookmark,
        pageSize: action.pageSize,
        count: action.count,
        error: undefined,
      };
    case ActionTypes.ERROR_GET_DOCUMENT_SIGNATURES:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default signatures;
