import { Icon, IconsType } from "@sis-lab/web-ui-components"
import { useI18nContext } from "i18n/i18n-react"
import React from "react"
import { PrmStatus } from "apis/prmApi/prm/types"
import styles from './PaymentStamp.module.scss'

interface Props {
  status: PrmStatus
  amount: number
}


const statusToIcon = (status: PrmStatus): IconsType => {
  switch(status) {
    case 'started': return 'schedule_outline'
    case 'invited': return 'mail_outline' as 'arrow_down'
    case 'assigned': return 'mail_outline' as 'arrow_down'
    case 'rejected': return 'close_circle_outline'
    case 'created': return 'add_circle_outline'
    case 'canceled': return 'close_circle_outline'
    case 'paid': return 'check_circle'
    case 'accepted': return 'check_circle_outline'
    case 'received': return 'mail_outline' as 'arrow_down'
    case 'delivered': return 'check_circle_outline'
    case 'sent': return 'mail_outline' as 'arrow_down'
    case 'responded_error': return 'done_all' as 'arrow_down'
    case 'responded_warning': return 'done_all' as 'arrow_down'
    case 'responded_ok': return 'done_all' as 'arrow_down'
    default: return 'add_circle_outline'

  }
}

export default function PaymentStamp({ amount, status }: Props) {
  const { LL } = useI18nContext()

  return (
    <div className={[styles.stamp, styles[status]].join(' ')}>
      <Icon className={styles.icon} name={statusToIcon(status)} />
      <div className={styles.amount}>
        <h3>
          {
            amount.toString().split('.')[1]?.length > 2
              ? parseFloat(amount.toFixed(2))
              : amount
          }
        </h3>
        <Icon name={'euro' as 'arrow_down'} />
      </div>
      <p>{LL.paymentStatus[status]()}</p>
    </div>    
  )
}
