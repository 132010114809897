import axios from 'axios';
import React, { useState } from 'react';
import { passwordValidator, useQuery } from 'utils';
import { Button, Card, Form, Input, Password, useForm } from '@sis-lab/web-ui-components';
import { Link, useHistory } from 'react-router-dom';
import authApi, { CreateUserRequest } from 'apis/auth';
import { useI18nContext } from 'i18n/i18n-react';
import { EMAIL_REG_EXP } from 'utils/consts';
import styles from './SignupForm.module.scss';

type SignupFormProps = {
  accountType: 'business' | 'natural' 
}

export default function SignupForm({ accountType }: SignupFormProps) { 
  const { LL } = useI18nContext()
  const history = useHistory();
  const query = useQuery();
  const form = useForm({ defaultValues: { email: query.get('email') } });
  const [isLoading, setIsLoading] = useState(false);

  const onSignup = async () => {
    form.clearErrors();
    const newErrors: { name: string; message: string; }[] = [];

    const user: CreateUserRequest = {
      email: form.getValues('email'),
      password: form.getValues('password'),
      personType: accountType,
      kybRequired: accountType === 'business' ? 'true' : 'false',
      inviteId:  query.get('inviteId') || undefined,
    }

    if (!user.email.match(EMAIL_REG_EXP)) newErrors.push({ name: 'email', message: LL.errors.common.invalidEmail() });
    if (!passwordValidator.validate(user.password)) {
      newErrors.push({ name: 'password', message: '' });
      newErrors.push({ name: 'passwordCheck', message: LL.errors.common.passwordWeak() });
    } else if (user.password !== form.getValues('passwordCheck')) {
      newErrors.push({ name: 'password', message: '' });
      newErrors.push({ name: 'passwordCheck', message: LL.errors.common.passwordsDoNoMatch() });
    }

    if (newErrors.length !== 0) {
      newErrors.map(({ name, message: msg }) => form.setError(name, { message: msg }));
      return;
    }

    setIsLoading(true)

    try {
      const { data: sub } = await authApi.createUser(user)
      if (sub === 'OK') {
        history.push(`/verify-email?email=${user.email}`)
      } else {
        history.push(`/identity-verification/${sub}`)
      }
    } catch (e) {
      if (!axios.isAxiosError(e)) return
      if (e.response?.status === 409) form.setError('email', { message: LL.errors.auth.createUser.userExists() });
      if (e.response?.status === 400) history.push('/signup')
    } 
    setIsLoading(false);
  };
  return (
    <Form className={styles.form} formHook={form}>
      <Input
        required
        disabled={!!query.get('email')}
        name='email'
        placeholder={LL.commonFields.email.placeholder()}
        title={LL.commonFields.email.title()}
      />
      <Password
        required
        name='password'
        title={LL.commonFields.password.title()}
        placeholder={LL.commonFields.password.placeholder()}
      />
      <Password
        required
        name='passwordCheck'
        title={LL.commonFields.passwordRepeat.title()}
        placeholder={LL.commonFields.passwordRepeat.placeholder()}
        helpText={LL.commonFields.passwordRepeat.helpText()}
      />
      <Button
        disabled={isLoading}
        className={styles.signupButton}
        onClick={onSignup}
        children={LL.signupPage.buttonTitle()}
      />
      <small className={styles.termsBox}>
        {LL.signupPage.policyText()}
        <a href='https://www.corposign.net/terms-and-conditions/' target='_blank' rel='noreferrer'> {LL.signupPage.terms()}</a>
        &#160; {LL.common.and()} &#160;
        <a href='https://www.corposign.net/privacy-policy/' target='_blank' rel='noreferrer'>{LL.signupPage.policy()}</a>
      </small>
    </Form>
  )
}
