/* eslint-disable react/jsx-no-constructed-context-values */
import React, { ReactNode } from "react";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import MembersContext from "./MembersContext";
import useFetchMembers from "./useFetchMembers";

interface Props {
  children: ReactNode
}

export default function MembersProvider({ children }: Props) {
  const {
    members,
    loading,
    fullyLoaded,
    error,
    setMembers,
    getMoreMembers,
    searchMembers
  } = useFetchMembers()

  if (error) return <ErrorPage errorMessage={error} />
  return (
    <MembersContext.Provider 
      value={{
        members,
        loading,
        fullyLoaded,
        updateMembers: setMembers,
        getMoreMembers,
        searchMembers,
      }}
    >
      {children}
    </MembersContext.Provider>
  )
}