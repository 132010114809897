import React from "react";
import styles from './SkeletonList.module.scss'


export default function SkeletonList() {
  return (
    <div className={styles.list}>
      <div className={styles.skeletonRow}><div/></div>
      <div className={styles.skeletonRow}><div/></div>
      <div className={styles.skeletonRow}><div/></div>
      <div className={styles.skeletonRow}><div/></div>
    </div>
  )
}
