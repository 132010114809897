import { Card, CardHeader, Skeleton } from "@sis-lab/web-ui-components";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "modules";
import { UserDetailed } from "apis/auth";
import { transformDate } from "utils";
import { useI18nContext } from "i18n/i18n-react";
import PayerFooter from "./components/PayerFooter/PayerFooter";
import calculatePrmPermissions from "./components/calculatePrmPermissions";
import PayerRow from "./components/PayerRow/PayerRow";
import InviteeRow from "./components/InviteeRow/InviteeRow";
import InviteButton from "./components/InviteButton/InviteButton";
import PayNowButton from "./components/PayNowButton/PayNowButton";
import PrmContext from "../PrmContext/PrmContext";
import PayeeRow from "./components/PayeeRow/PayeeRow";
import NoPayerRow from "./components/NoPayerRow/NoPayerRow";
import DueDate from "./components/DueDate/DueDate";
import styles from './PayerCard.module.scss'


export default function PayerCard() {
  const { prm } = useContext(PrmContext)
  const { LL } =  useI18nContext()

  const user = useSelector<ApplicationState>(state => state.authentication?.user) as UserDetailed
  const [allowedToInvite, allowedToPay, userIsPayee] = calculatePrmPermissions(user, prm)
  
  return (
    <Card className={styles.card} size='small'>
      <CardHeader
        title={userIsPayee ? LL.paymentCommon.payer() : LL.paymentCommon.payee()}
        suffix={<>{allowedToPay && <PayNowButton />} {allowedToInvite && <InviteButton />}</>}
      />
      <div className={styles.body}>
        {!prm && <Skeleton height={85} style={{ marginBottom: 8 }} />}
        {!userIsPayee && prm && <PayeeRow payee={prm.payee} />}
        {userIsPayee && prm?.payer && <PayerRow payer={prm.payer} />}
        {userIsPayee && !prm?.payer && prm?.invitee && <InviteeRow invitee={prm.invitee} />}
        {userIsPayee && !prm?.payer && !prm?.invitee && <NoPayerRow />}
        {prm?.xpryDt && <DueDate dueDate={transformDate(prm.xpryDt)} />}
      </div>
      <PayerFooter
        accepted={prm?.status === 'accepted'}
        hidden={!allowedToPay || !!prm?.payer}
      />
    </Card>
  )
}
