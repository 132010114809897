import { Button } from "@sis-lab/web-ui-components";
import { useI18nContext } from "i18n/i18n-react";
import React, { useState } from "react";
import PageHeader from "components/PageHeader/PageHeader";
import SealsTable from "./components/SealsTable/SealsTable";
import NewSealModal from "./components/NewSealModal/NewSealModal";
import styles from './SealsPage.module.scss'


export default function SealsPage() {
  const [newOpen, setNewOpen] = useState(false);
  const { LL } = useI18nContext()
  
  return (
    <div className={styles.page}>
      <PageHeader title='Seals'>
        <Button
          children='new seal'
          suffix='add'
          mobileIcon='add'
          onClick={() => setNewOpen(true)}
        />
      </PageHeader>
      <SealsTable />
      <NewSealModal
        open={newOpen}
        onCancel={() => setNewOpen(false)}
      />
    </div>
  )
}
