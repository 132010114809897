import { Card, CardHeader, Icon } from '@sis-lab/web-ui-components';
import React, { useEffect } from 'react';
import { useI18nContext } from 'i18n/i18n-react';
import { useHistory, useParams } from 'react-router-dom';
import auth from 'apis/auth';
import { WidgetSettings } from './types';
import initWidget from './components/initWidget';
import styles from './SmartVerificationPage.module.scss';

type RouteProps = {
  sub: string
}

const containerId = 'smartWidgetContainer'

export default function SmartIdPage() {
  const { sub } = useParams<RouteProps>()
  const { LL } = useI18nContext()

  const history = useHistory()

  const goBack = () => history.push(`/identity-verification/${sub}`)
  
  useEffect(() => {
    const settings: WidgetSettings = {
      countryCode: 'LT',
      email: 'todo',
      onFailure: () => null,
      onSuccess: async (verificationResponse) => {
        const { data: { redirectUrl, external } } = await auth.finishKyc({
          sub,
          kycMethod: 'smartId',
          country: verificationResponse.country,
          firstName: verificationResponse.firstname,
          lastName: verificationResponse.lastname,
          dateOfBirth: verificationResponse.birth_date,
          personalIdentifier: verificationResponse.idcode
        })
      
        if (redirectUrl && external) {
          // window.open(redirectUrl, '_blank');
          history.push(`/identity-confirmed-kyb?idenfyKybUrl=${redirectUrl}`)
        } else if (redirectUrl && !external) {
          history.push(`/identity-confirmed-member?resetPasswordUrl=${redirectUrl}`)
          // history.push(redirectUrl)
        } else {
          history.push('/identity-confirmed-kyc')
        }
    }}
    const widget = initWidget(settings, containerId)

    return () => widget.remove();
  }, [])

  return (
    <Card className={styles.card}>
      <CardHeader
        prefix={<Icon name='arrow_left' onClick={goBack} />}
        title={<h2>{LL.identityVerificationPage.smartIdTitle()}</h2>}
      />
      <div id={containerId} />
    </Card>
  )
}
