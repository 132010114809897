/* eslint-disable no-param-reassign */
import React, { ReactNode, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useI18nContext } from "i18n/i18n-react";
import { loadLocaleAsync } from "i18n/i18n-util.async";
import { useHistory, useLocation } from 'react-router-dom';
import authApi, { UserDetailed, UserMetadata } from "apis/auth";
import aiApi, { AiPreferences } from "apis/aiApi";
import documents from "apis/contract";
import { ApplicationState } from "modules";
import {
  setMetadataAction,
  setUserErrorAction,
  setUserInfoAction,
  setDocumentInvitesCountAction,
  setPrmInvitesCountAction,
  // setDocumentTypesAction,
  setAiPreferencesAction,
} from "modules/authentication/actions";
import localCompanyInfo from "utils/localStorage/localCompanyInfo";
import prmApi from "apis/prmApi";
import handleRedirect from "./components/handleRedirect";
import useInitUser from "./components/useInitUser";

interface Props {
  children: ReactNode
  authenticated?: boolean
  user?: UserDetailed
  initialized?: boolean
  metadata?: UserMetadata
  aiPreferences?: AiPreferences
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.authentication.user,
  initialized: state.authentication.initialized,
  authenticated: state.authentication.authenticated,
  metadata: state.authentication.metadata,
  aiPreferences: state.authentication.aiPreferences,
});


function Authentication({ children, authenticated, user, metadata, initialized, aiPreferences }: Props) {
  const { setLocale } = useI18nContext()
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const initializeUser = useInitUser()

  // useEffect(() => { 
  //   if (authenticated) documents.getDocumentTypes()
  //     .then(({ data }) => setDocumentTypesAction(data)(dispatch))
  //     .catch(e => setUserErrorAction(e)(dispatch));
  // }, [location.pathname, authenticated])

  useEffect(() => { 
    if (authenticated) documents.getMyInvitesCount()
      .then(({ data }) => setDocumentInvitesCountAction(data.count)(dispatch))
      .catch(e => setUserErrorAction(e)(dispatch));
  }, [location.pathname, authenticated])

  useEffect(() => { 
    if (authenticated) prmApi.getInvitedPrmsCount()
      .then(({ data }) => setPrmInvitesCountAction(data.count)(dispatch))
      .catch(e => setUserErrorAction(e)(dispatch));
  }, [location.pathname, authenticated])

  useEffect(() => {
    if (!initialized) initializeUser();

    if (authenticated && !user) {
      authApi.getUserInfo()
      .then(({ data }) => setUserInfoAction(data)(dispatch))
      .catch(e => setUserErrorAction(e)(dispatch))
    }

    if (authenticated && !metadata) {
      authApi.getUserMetadata()
        .then(({ data: userMetadata }) => {
          localStorage.setItem('lang', userMetadata.preferences.language)
          loadLocaleAsync(userMetadata.preferences.language).then(() => setLocale(userMetadata.preferences.language))
          if (userMetadata.redirectUrl) handleRedirect(userMetadata, history)
          setMetadataAction(userMetadata)(dispatch)
        })
        .catch(e => setUserErrorAction(e)(dispatch));
    }

    if (authenticated && !aiPreferences) {
      aiApi.getPreferences()
        .then(({ data }) => {
          setAiPreferencesAction(data)(dispatch)
        })
        .catch(e => setUserErrorAction(e)(dispatch));
    }

    if (authenticated && (user?.parentSub && !localCompanyInfo.getCompanyName())) {
      authApi.getOrganization().then(({ data }) => localCompanyInfo.storeCompanyName(data.name))
    }
  
  }, [authenticated, user, initialized, metadata, aiPreferences]);

  return <div>{children}</div>;
}

export default connect(mapStateToProps)(Authentication);
