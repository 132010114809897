import qs from 'qs'
import { instance } from './instance';
import { CredentialType, InitiateWalletBindResponse } from './types';

export const initiateWalletBind = () => (
  instance.post<InitiateWalletBindResponse>(`/corposign/initiate-bind-wallet`)
)

export const removeWalletBind = () => (
  instance.delete<string>('/corposign/unbind')
)

export const getCredentialTypes = () => (
  instance.get<CredentialType[]>('/corposign/credential-types')
)

export const getCredentialOffer = (credentialOfferId: string) => (
  instance.get<any>(`/issuer/offers/${credentialOfferId}`)
)

export const initiateCredentialOffer = (credentialType: CredentialType) => (
  instance.post<string>(
    '/corposign/initiate-credential-offer',
    qs.stringify({ credential_type: credentialType })
  )
)