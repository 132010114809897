import React from 'react';
import { ColumnsType } from '@sis-lab/web-ui-components';
import { transformDate } from 'utils';
import { PRM } from 'apis/prmApi/prm/types';
import { useI18nContext } from 'i18n/i18n-react';
import PaymentStamp from './components/PaymentStamp/PaymentStamp';
import styles from './PaymentColumns.module.scss';


const PaymentColumns = (sub: string): ColumnsType<PRM> => [
  {
    title: 'Title',
    key: 'id',
    width:  'auto',
    ellipsis: true,
    render: (_text, record) => (
      <div className={styles.titleColumn}>
        <h3>{record.orderId}</h3>
        <p>{record.description}</p>
      </div>
    ),
  },
  {
    title: 'Creator',
    key: 'orderId',
    width:  'auto',
    render: (_text, record) => {
      // ! Console: "Warning: You can only call Hooks at the top level of your React function."
      const { LL } = useI18nContext()

      const isPayee = record.payee.id === sub
      const receiver = record.payer?.email || record.invitee?.email

      return (
        <div className={styles.payeeColumn}>
          <div className={[styles.title, !receiver && styles.noPayer].join(' ')}>
            <h3>
              {isPayee && (receiver || LL.paymentsPage.noInvited())}
              {!isPayee && record.payee?.email}
            </h3>
            <p>{transformDate(record.createdAt)}</p>
          </div>
        </div>
      )
    },
  },
  {
    title: 'Status',
    key: 'email',
    width: '225px',
    render: (_text, { status, amount }) => (
      <PaymentStamp
        status={status}
        amount={amount}
      />
    )
  },
];

export default PaymentColumns
