import { useEffect, useState } from 'react';
import { useI18nContext } from 'i18n/i18n-react';
import prmApi from "apis/prmApi"
import { PRM } from "apis/prmApi/prm/types"
import { errorParser } from 'utils/requests/errorParser';


const useFetchPayment = (prmId: string) => {
  const [loading, setLoading] = useState(true);
  const [prm, setPrm] = useState<PRM>();
  const [error, setError] = useState<string>();
  const { LL } = useI18nContext()

  const fetchPrm = async () => {
    setLoading(true);
    prmApi.getPrm(prmId)
      .then(({ data }) => setPrm(data))
      .finally(() => setLoading(false))
      .catch(e => errorParser(e, err => {
        setError(err.response?.status === 404 ? 
          LL.errors.contract.getDocumentErrors.documentNotFound() :
          LL.errors.common.internalError()
        )
      }))
  };

  useEffect(() => {
    fetchPrm();
  }, [prmId])

  return [prm, loading, setPrm, error] as const;
}

export default useFetchPayment;
