import { IDocumentDetailed, IDocumentBasic } from 'apis/contract';

export enum ActionTypes {
  CLEAR_DOCUMENT = '@@documents/REQUEST_GET_DOCUMENT',
  REQUEST_GET_DOCUMENT = '@@documents/REQUEST_GET_DOCUMENT',
  SUCCESS_GET_DOCUMENT = '@@documents/SUCCESS_GET_DOCUMENT',
  ERROR_GET_DOCUMENT = '@@documents/ERROR_GET_DOCUMENT',

  SET_VALIDATION_RESULTS = '@@documents/SET_VALIDATION_RESULTS',
  CLEAR_VALIDATION_RESULTS = '@@documents/CLEAR_VALIDATION_RESULTS',

  REQUEST_GET_DOCUMENTS = '@@documents/REQUEST_GET_DOCUMENTS',
  SUCCESS_GET_DOCUMENTS = '@@documents/SUCCESS_GET_DOCUMENTS',
  ERROR_GET_DOCUMENTS = '@@documents/ERROR_GET_DOCUMENTS',

  REQUEST_GET_REFERENCED_DOCUMENTS = '@@documents/REQUEST_GET_REFERENCED_DOCUMENTS',
  SUCCESS_GET_REFERENCED_DOCUMENTS = '@@documents/SUCCESS_GET_REFERENCED_DOCUMENTS',
  ERROR_GET_REFERENCED_DOCUMENTS = '@@documents/ERROR_GET_REFERENCED_DOCUMENTS',
  CLEAR_REFERENCED_DOCUMENTS = '@@documents/CLEAR_REFERENCED_DOCUMENTS',

  REQUEST_GET_PUBLIC_DOCUMENTS = '@@documents/REQUEST_GET_PUBLIC_DOCUMENTS',
  SUCCESS_GET_PUBLIC_DOCUMENTS = '@@documents/SUCCESS_GET_PUBLIC_DOCUMENTS',
  ERROR_GET_PUBLIC_DOCUMENTS = '@@documents/ERROR_GET_PUBLIC_DOCUMENTS',

  REQUEST_GET_PUBLIC_DOCUMENTS_TABLE = '@@documents/REQUEST_GET_PUBLIC_DOCUMENTSS_TABLE',
  SUCCESS_GET_PUBLIC_DOCUMENTS_TABLE = '@@documents/SUCCESS_GET_PUBLIC_DOCUMENTSS_TABLE',
  ERROR_GET_PUBLIC_DOCUMENTS_TABLE = '@@documents/ERROR_GET_PUBLIC_DOCUMENTSS_TABLE',

  REQUEST_QUERY_DOCUMENTS = '@@documents/REQUEST_QUERY_DOCUMENTS',
  SUCCESS_QUERY_DOCUMENTS = '@@documents/SUCCESS_QUERY_DOCUMENTS',
  ERROR_QUERY_DOCUMENTS = '@@documents/ERROR_QUERY_DOCUMENTS',

  REQUEST_CREATE_DOCUMENT = '@@documents/REQUEST_CREATE_DOCUMENT',
  SUCCESS_CREATE_DOCUMENT = '@@documents/SUCCESS_CREATE_DOCUMENT',
  ERROR_CREATE_DOCUMENT = '@@documents/ERROR_CREATE_DOCUMENT',

  TRANSFORM_DATE = '@@documents/TRANSFORM_DATE',
}

export interface DocumentsAction {
  type: ActionTypes;
  loading?: boolean;
  validationResults?: IDocumentDetailed;
  document?: IDocumentBasic;
  documents?: IDocumentBasic[];
  publicDocuments?: IDocumentBasic[];
  publicDocumentsTable?: IDocumentBasic[];
  referencedDocuments?: IDocumentBasic[];
  error?: Error;
  bookmark?: string;
  pageSize?: number;
  count?: number;
}

export interface DocumentsState {
  readonly loading: boolean;
  readonly validationResults?: IDocumentDetailed;
  readonly document?: IDocumentBasic;
  readonly documents?: IDocumentBasic[];
  readonly publicDocuments?: IDocumentBasic[];
  readonly publicDocumentsTable?: any;
  readonly referencedDocuments?: IDocumentBasic[];
  readonly error?: Error;
  readonly bookmark?: string;
  readonly pageSize?: number;
  readonly count?: number;
}
