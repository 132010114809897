import { Member, SealSearch } from "apis/auth";
import { createContext } from "react";


export type SealContextType = {
  members: Member[]
  loading: boolean
  fullyLoaded: boolean
  getMoreMembers: (search: SealSearch) => Promise<void> 
  searchMembers: (search: SealSearch) => Promise<void> 
  setMembers: (seals: Member[]) => void,
  sealId: string
}

export default createContext<SealContextType>({
  members: [],
  loading: true,
  fullyLoaded: false,
  setMembers: () => null,
  sealId: '',
  getMoreMembers: () => new Promise(resolve => { resolve() }),
  searchMembers: () => new Promise(resolve => { resolve() }),
})
