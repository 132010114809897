export function removeEmptyStringProperties<T extends Record<string, unknown>>(obj: Record<string, unknown>) {
  const newObj: Record<string, unknown> = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'string' && value !== '') newObj[key] = value;
    else if (typeof value === 'object' && value !== null) {
      const nestedObj = removeEmptyStringProperties(value as Record<string, unknown>);
      if (Object.keys(nestedObj).length > 0) newObj[key] = nestedObj;
    }
    else if (value) newObj[key] = value
  }

  return newObj as T;
}
