/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { getOrganizationSignatures, ISignaturesSearch, MySignaturesResponse } from 'apis/contract';

const useFetchSignatures = () => {
  const [response, setResponse] = useState<MySignaturesResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [isFullyLoaded, setIsFullyLoaded] = useState(false);

  const fetchSignatures = async (searchParams: ISignaturesSearch, reset?: boolean, signal?: AbortSignal) => {
    setIsLoading(true);
    searchParams.bookmark = reset ? undefined : response?.bookmark
    searchParams.pageSize = 10;

    try {
      const { data } = await getOrganizationSignatures(searchParams, signal);
      if (!reset && response?.signatures) data.signatures = response.signatures.concat(data.signatures);
      setResponse(data);
      setIsFullyLoaded(!data.bookmark);
    } catch (err) {
      // message.error(`${err}`, 3);
    } finally {
      setIsLoading(true)
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchSignatures({});
  }, []);

  return [response?.signatures || [], isLoading, isFullyLoaded, fetchSignatures] as const;
};

export default useFetchSignatures;
