import eDeliveryApi, { EdeliveryConfig, EdeliveryConfigUpdate } from "apis/eDelivery"
import { useEffect, useState } from "react"


export default function usePeppolConfig() {
  const [config, setConfig] = useState<EdeliveryConfig>()
  const [loading, setLoading] = useState(true)
  const [prohibited, setProhibited] = useState(false);

  useEffect(() => {
    eDeliveryApi.getEdeliveryConfig()
      .then(({ data }) => setConfig(data))
      .catch(err => {
        if (err?.response?.status === 404) setProhibited(true);
        console.log(err);
      })
      .finally(() => setLoading(false))
  }, [])

  const updateConfig = async (newConfig: EdeliveryConfigUpdate) => {
    setLoading(true)
    if (config) {
      await eDeliveryApi.updateEdeliveryConfig(newConfig)
        .then(() => setConfig({ ...config, ...newConfig }))
        .catch(err => console.log(err))
        .finally(() => setLoading(false))
    }
  }

  return [config, loading, prohibited, updateConfig] as const
}
