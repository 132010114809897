export interface Position {
  longitude: number
  latitude: number
}

// eslint-disable-next-line import/prefer-default-export
export const  getUserLocation = () => new Promise<Position>((resolve, reject) => {
  if (!navigator.geolocation)  reject(new Error('Geolocation is not supported by your browser'));
  navigator.geolocation.getCurrentPosition(
    (position) => {
      const { latitude, longitude } = position.coords
      resolve({ latitude, longitude })
    },
    () => resolve({longitude: 0, latitude: 0})
    // (error) => reject(error)
  );
})
