import { Caption, Icon, Label } from "@sis-lab/web-ui-components";
import { PrmInvitee } from "apis/prmApi";
import React from "react";
import styles from './InviteeRow.module.scss'

interface Props {
  invitee: PrmInvitee
}


export default function InviteeRow({ invitee }: Props) {
  return (
    <div className={styles.inviteeRow}>
      <Icon className={styles.icon} name={'email' as 'add'} />
      <div>
        <Label>{invitee.name}</Label>
        <Caption small>{invitee.email}</Caption>
      </div>
    </div>
  )
}
