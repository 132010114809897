import React, { ReactChild } from "react"
import ErrorPage from "pages/ErrorPage/ErrorPage";
import PrmContext from "./PrmContext"
import useFetchPrm from "./useFetchPrm";

interface Props {
  children: ReactChild
  prmId: string
}


export default function PrmProvider({ children, prmId }: Props) {
  const [prm, loading, updatePrm, error] = useFetchPrm(prmId);

  if (error) return <ErrorPage errorMessage={error} />
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <PrmContext.Provider value={{ prm, loading, updatePrm }}>
      {children}
    </PrmContext.Provider>
  )
}
