import { objectToQuery } from 'utils';
import { instance } from './instance';
import {
  ReInviteReturn,
  // IDocumentBasic,
  IDocumentEvent,
  IDocumentsSearch,
  AddInvitesReturn,
  IDocumentDetailed,
  MyDocumentsResponse,
  ICreateDocumentRequest,
  DocumentUpdatableFields,
  PrepareResponse,
} from './types'

// ========= Metadata =============
export const getMyInvitesCount = () => (
  instance.get<{ count: number }>(`/invites/count`)
)

export const getDocumentTypes = (signal?: AbortSignal) => (
  instance.get<{ [key: string]: boolean }>(`/account/document-types`, { signal })
)

// ========= Documents =============
export const getMyDocuments = (searchParams: IDocumentsSearch) => (
  instance.get<MyDocumentsResponse>(`/documents${objectToQuery(searchParams)}`)
)

export const createDocument = (request: ICreateDocumentRequest) => (
  instance.post('/documents', request)
)

export const validateDocument = (fingerprint: string) => (
  instance.get<IDocumentDetailed>(`/validate/${fingerprint}`)
)

export const getDocument = (fingerprint: string) => (
  instance.get<IDocumentDetailed>(`/documents/${fingerprint}`)
)

export const eIdPrepareDocument = (fingerprint: string) => (
  instance.post<PrepareResponse>(`/documents/${fingerprint}/eid-prepare-document`)
)

export const eIdGetDocument = (fingerprint: string, documentId: string) => (
  instance.get<ArrayBuffer>(`/documents/${fingerprint}/eid-get-document?documentId=${documentId}`, { responseType: 'arraybuffer' })
)

export const patchDocument = (fingerprint: string, fieldsToUpdate: DocumentUpdatableFields) => (
  instance.patch<{ document: IDocumentDetailed, event: IDocumentEvent }>(`/documents/${fingerprint}`, fieldsToUpdate)
)

export const getDocumentCertificate = (fingerprint: string) => (
  instance.get<string>(`/certificates/document/${fingerprint}`, { responseType: 'arraybuffer' })
)

// ========= Invites =============
export const getMyInvites = (searchParams: IDocumentsSearch) => (
  instance.get<MyDocumentsResponse>(`/invites${objectToQuery(searchParams)}`)
)

export const addInvites = (fingerprint: string, emails: string[]) => (
  instance.post<AddInvitesReturn>(`/documents/${fingerprint}/invite`, { emails })
)

export const reInvite = (fingerprint: string, emails: string[]) => (
  instance.post<ReInviteReturn>(`/documents/${fingerprint}/re-invite`, { emails })
)

export const revokeInvite = (fingerprint: string, email: string) => (
  instance.delete<IDocumentEvent>(`/documents/${fingerprint}/invite`, { data: { email } })
)

export const checkFingerprints = (fingerprints: string[]) => (
  instance.get<{ [key: string]: { exists: boolean } }>(`/check-fingerprints?fingerprints=${fingerprints.join(',')}`)
)
