import React, { useCallback, useContext, useState } from 'react';
import { Form, FormSelect, Input, breakpoints, useForm, useWindowSize } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'modules';
import { Member, MemberRole, MemberSearchParams } from 'apis/auth';
import InfiniteTable from 'components/InfiniteTable/InfiniteTable';
import { MemberColumns, NameEmailColumn } from './components/columns/MemberColumns';
import MembersContext from '../MemberContext/MembersContext';
import MemberModal from './components/MemberModal/MemberModal';
import styles from './MembersTable.module.scss';

const availableRoles: MemberRole[] = ['admin', 'member', 'owner']

type TypedFormValues = {
  substring?: string
  roles?: { value: MemberRole, label: string }[]
}

export default function MembersTable() {
  const [selectedMember, setSelectedMember] = useState<Member>()
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { members, loading, fullyLoaded, getMoreMembers, searchMembers } = useContext(MembersContext)
  const { LL } = useI18nContext();

  const userSub = useSelector((state: ApplicationState) => state.authentication.user?.sub)
  const windowsSize = useWindowSize();
  const form = useForm();

  const expanded = windowsSize.width > breakpoints.tabletSmall;

  const handleSearch = () => {
    const { roles, substring } = form.getValues() as TypedFormValues
    const search: MemberSearchParams = {}
    if (roles) search.role = roles.map(role => role.value) 
    // ! if person cleared the search input - refetch
    search.substring = substring
    searchMembers(search)
  }

  const handleLoad = useCallback(async () => {
    const { roles, substring } = form.getValues() as TypedFormValues
    const search: MemberSearchParams = {}
    if (roles) search.role = roles.map(role => role.value) 
    if (substring) search.substring = substring 
    await getMoreMembers(search)
  }, [getMoreMembers])

  if (!userSub) return <div />
  return (
    <div className={styles.wrapper}>
      <Form formHook={form} className={styles.search}>
        <Input
          onChange={handleSearch}
          name='substring'
          placeholder='Search'
        />
        <FormSelect
          isMulti
          fixedHeight
          name='roles'
          onChange={handleSearch}
          placeholder='Select employee type'
          options={availableRoles.map(role => ({ value: role, label: role }))}
        />
      </Form>
      <InfiniteTable
        loadMore={handleLoad}
        showHeader={false}
        pagination={false}
        fullyLoaded={fullyLoaded}
        loading={loading}
        columns={expanded ? MemberColumns(userSub, LL) : NameEmailColumn(userSub)}
        dataSource={members}
        rowKey={({ sub }) => sub}
        onRow={member => ({ onClick: () => { setModalOpen(true); setSelectedMember(member)} })}
        rowClassName={({ enabled, sub }) => (!enabled || sub === userSub) ? styles.disabled : ''}
      />
      {selectedMember && <MemberModal
        isOpen={modalOpen}
        isUser={selectedMember?.sub === userSub}
        member={selectedMember}
        onClose={() => {
          setModalOpen(false)
          setTimeout(() => {
            setSelectedMember(undefined)
          }, 200);
        }}
      />}
    </div>

  )
}
