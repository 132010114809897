import React from 'react';
import { Button, Card, CardFooter, CardHeader, message } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import { useQuery } from 'utils';
import verifiedImg from '../../../images/account-verified.svg';
import styles from '../IdentityConfirmedPage.module.scss'

export default function KybFlow() {
  const { LL } = useI18nContext() 
  const query = useQuery()

  const redirectToIdenfyKyb = () => {
    const kybUrl = query.get('idenfyKybUrl') || '';
    if (kybUrl) {
      window.open(kybUrl, '_blank')
    } else {
      message.error('Some error occured, check you email for kyb link')
    }
  }

  return (
    <Card className={styles.card}>
      <CardHeader
        title={<h2>{LL.confirmedPage.kybFlow.title()}</h2>}
      />
      <div className={styles.body}>
        <img src={verifiedImg} alt='success'/>
        <p>{LL.confirmedPage.kybFlow.explanation()}</p>
        <span>{LL.confirmedPage.kybFlow.prompt()}</span>
      </div>
      <CardFooter className={styles.footer}>
        <Button onClick={redirectToIdenfyKyb} children={LL.confirmedPage.kybFlow.buttonText()} />
      </CardFooter>
    </Card>
  )
}
