import { Card, CardHeader, Form, Input, useForm } from '@sis-lab/web-ui-components'
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useI18nContext } from 'i18n/i18n-react';
import styles from './ReferencedDocsCard.module.scss';
import DocumentContext from '../DocumentContext/DocumentContext';


export default function ReferencedDocsCard() {
  const { document } = useContext(DocumentContext)
  const { LL } = useI18nContext()
  const form = useForm();
  const history = useHistory();

  return (
    <Card size='small' className={!document?.referencedDocuments?.length? styles.hidden : ''}>
      <CardHeader title={LL.referencedDocsCard.title()} />
      <Form size='medium' formHook={form} disabled>
        {document?.referencedDocuments?.map(({ fingerprint }) => (
          <Input
            name={fingerprint}
            key={fingerprint}
            defaultValue={fingerprint}
            suffix={'open_in_new' as 'arrow_down'}
            onSuffix={() => history.push(`/documents/${fingerprint}`)}
          />
        ))}
      </Form>
    </Card>
  )
}
