import {
  getNewMessages,
  receiveNewMessages,
  getReceivingStatus,
  getLastReceivedLog,
  getMessageStatus,
  getEdeliveryConfig,
  updateEdeliveryConfig,
} from "./services"

export * from './types'

const eDeliveryApi = {
  getNewMessages,
  receiveNewMessages,
  getReceivingStatus,
  getLastReceivedLog,
  getMessageStatus,
  getEdeliveryConfig,
  updateEdeliveryConfig,
}

export default eDeliveryApi
