import { Button, Divider, Form, Input, Modal, useForm } from "@sis-lab/web-ui-components";
import prmApi from "apis/prmApi";
import React, { useContext, useState } from "react";
import { EMAIL_REG_EXP } from "utils/consts";
import { errorParser } from "utils/requests/errorParser";
import PrmContext from "pages/PaymentPage/components/PrmContext/PrmContext";
import { useI18nContext } from "i18n/i18n-react";
import styles from './InviteModal.module.scss';

interface Props {
  open: boolean
  onCancel: () => void
}


export default function InviteModal({ open, onCancel }: Props) {
  const [loading, setLoading] = useState(false)
  const { prm, updatePrm } = useContext(PrmContext)
  const { LL } = useI18nContext()
  const form = useForm()

  const handleClosing = () => {
    if (loading) return
    form.reset()
    onCancel()
  }

  const invite = async () => {
    let hasError = false
    form.clearErrors()
    const recipient = {
      email: form.getValues('email'),
      name: form.getValues('name')
    }

    if (!recipient.email) {
      form.setError('email', { message: 'Please provide a valid email address' })
      hasError = true
    }
    if (!recipient.name) {
      form.setError('name', { message: 'Please provide invitee name' })
      hasError = true
    }
    if (hasError) return

    setLoading(true)
    prmApi.inviteToPay(prm?.id || '', recipient)
      .then(({ data }) => {
        if (!prm) return
        updatePrm({ ...prm, invitee: data.invitee, status: data.status.status })
        handleClosing()
      }) 
      // eslint-disable-next-line no-console
      .catch(e => errorParser(e, err => console.log(err.response)))
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Modal
      size='small'
      open={open}
      onCancel={handleClosing}
      title={LL.paymentPage.inviteModal.title()}
      footer={null}
    >
      <Form formHook={form} size='medium' disabled={loading}>
        <Input
          required
          name='email'
          placeholder={LL.commonFields.email.placeholder()}
          rules={{ validate: (val) => val.match(EMAIL_REG_EXP) ? true : LL.errors.common.invalidEmail() }}
        />
        <Input
          required
          name='name'
          placeholder='Name'
        />
        <Divider size='tiny' />
        <div className={styles.footer}>
          <Button
            disabled={loading}
            onClick={handleClosing}
            children={LL.commonButtons.cancel()}
            type='secondary'
          />
          <Button
            disabled={loading}
            onClick={invite}
            children={LL.paymentPage.inviteModal.inviteConfirmButton()}
          />
        </div>
      </Form>
    </Modal>
  )
}
