import React, { useCallback, useRef, useState } from 'react';
import {
  Button,
  Divider,
  Form,
  Icon,
  Input,
  Modal,
  Checkbox,
  useForm,
  FormSelectAsync,
  message,
} from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import { EMAIL_REG_EXP } from 'utils/consts';
import authApi from 'apis/auth';
import { AxiosError } from 'axios';
import useRightsSelector from '../MembersTable/components/MemberModal/RightsSelector/useRightsSelector';
import styles from './NewMemberModal.module.scss';
import useCreateMember from './useCreateMember';

interface Props {
  open: boolean
  onCancel: () => void
}

export default function NewMemberModal({ open, onCancel }: Props) {
  const form = useForm();
  const { LL } = useI18nContext();

  const [createMember, loading] = useCreateMember();
  const [RightsSelector, permissions] = useRightsSelector()
  const [usernameIsEmail, setUsernameIsEmail] = useState(true)
  const abortControllerRef = useRef(new AbortController());

  const handleClose = () => {
    // setError(undefined)
    form.clearErrors()
    form.setValue('email', undefined);
    form.setValue('username', undefined);
    form.setValue('position', undefined);
    form.setValue('sealSub', null);
    onCancel();
  };

  const parseError = (error: any) => {
    if (error instanceof AxiosError) {
      if (error.response?.status === 409) {
        const fieldName = usernameIsEmail ? 'email' : 'username'
        form.setError(
          fieldName,
          { message: LL.errors.auth.createMember.usernameConflict() || error.response?.data?.message }
        );
      } else {
        message.error(error.response?.data?.message);
      }
    } else {
      console.log(error);
      message.error('Some error occurred, try again later');
    }
  }

  const loadSeals = useCallback(async (search: string, _: any, additional: any) => {
    abortControllerRef.current.abort()
    abortControllerRef.current = new AbortController()
    const { data } = await authApi.getSeals(
      { bookmark: additional?.bookmark, substring: search, pageSize: 7, order: 'asc' },
      abortControllerRef.current.signal
    )

    return {
      options: data.items.map(seal => ({ label: seal.name, value: seal.sub })),
      hasMore: data.items.length > 10 || data.bookmark !== '',
      additional: {
        bookmark: data.bookmark,
      },
    };
  }, [abortControllerRef]);

  const onRegister = async () => {
    const newErrors: { name: string, message: string }[] = [];
    form.clearErrors();

    const email = form.getValues('email') as string;
    const username = form.getValues('username') as string;
    const position = form.getValues('position') as string;
    const sealSub = form.getValues('sealSub');

    if (!email || !email.match(EMAIL_REG_EXP)) {
      newErrors.push({ name: 'email', message: LL.errors.common.invalidEmail() })
    }
    if (!position) {
      newErrors.push({ name: 'position', message: LL.errors.auth.createMember.missingPosition() })
    }
    if (!username && !usernameIsEmail) {
      newErrors.push({ name: 'username', message: LL.errors.auth.createMember.missingUsername() })
    }

    if (newErrors.length !== 0) {
      newErrors.map(({ name, message: errMessage }) => form.setError(name, { message: errMessage }));
      return;
    };

    await createMember(
      {
        email,
        position,
        username: usernameIsEmail ? email : username,
        role: permissions.isAdmin ? 'admin' : 'member',
        sealSub: sealSub?.value,
        permissions: {
          allowedToView: permissions.allowedToView,
          allowedToSign: permissions.allowedToSign,
          allowedToUpload: permissions.allowedToUpload,
        },
      },
      handleClose,
      parseError
    )
  };

  return (
    <Modal
      open={open}
      wrapClassName={styles.employeeModal}
      footer={null}
      onCancel={handleClose}
      title={
        <div className={styles.modalHeader}>
          <Icon className={styles.icon} name={'face' as 'arrow_down'} />
          <h3 className={styles.mobile}>{LL.adminDashboard.createEmployee_mobile()}</h3>
          <h3 className={styles.notMobile}>{LL.adminDashboard.createEmployee()}</h3>
        </div>
      }
    >
      <Form className={styles.form} formHook={form}>
        <Input
          name='email'
          title={LL.commonFields.email.title()}
          placeholder={LL.commonFields.email.placeholder()}
        />
        {
          !usernameIsEmail && 
          <Input
            name='username'
            title={LL.commonFields.username.title()}
            placeholder={LL.commonFields.username.placeholder()}
          />
        }
        <Input
          name='position'
          title={LL.commonFields.position.title()}
          placeholder={LL.commonFields.position.placeholder()}
        />
        <FormSelectAsync
          name='sealSub'
          isMulti={false}
          isCreatable={false}
          placeholder='Seal (optional)'
          noOptionsMessage={() => null}
          debounceTimeout={300}
          loadOptions={loadSeals}
          additional={{ bookmark: '' }}
          title='Seal (optional)'
        />
        <Checkbox
          checked={usernameIsEmail}
          onChange={() => setUsernameIsEmail(!usernameIsEmail)}
          children='Username is the same as email'
        />
        <Divider size='tiny' />
        <RightsSelector />
        <Divider  size='tiny' />

        {/* <p className={styles.error}>{error?.response?.status} {error?.response?.statusText}</p> */}
      </Form>

      <div className={styles.modalFooter}>
        <Button
          onClick={handleClose}
          type='secondary'
          children={LL.commonButtons.cancel()}
        />
        <Button
          disabled={loading}
          onClick={onRegister}
          suffix={loading ? 'loading' : 'add'}
          children={LL.commonButtons.create()}
        />
      </div>
    </Modal>
  )
}
