import React from 'react';
import { ApplicationState } from 'modules';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import LoginPage from 'pages/LoginPage/LoginPage';
import { AxiosError } from 'axios';
import { UserDetailed } from 'apis/auth';
import LoadingPage from 'pages/LoadingPage/LoadingPage';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import canUserDo, { Protections } from 'utils/unique/canUserDo';

interface Props extends RouteProps {
  loading: boolean,
  authenticated?: boolean
  user?: UserDetailed
  protection?: Protections
  error?: AxiosError
}

const mapStateToProps = (state: ApplicationState) => ({
  authenticated: state.authentication.authenticated,
  user: state.authentication.user,
  loading: state.authentication.authenticated && !state.authentication.user || !state.authentication.initialized,
  error: state.authentication.error
});

function PrivateRoute({ authenticated, user, protection, loading, error, ...rest }: Props) {
  if (error) return <ErrorPage errorMessage={error.message} />
  if (loading) return <LoadingPage />
  if (!authenticated) return <LoginPage />

  if (protection && user && !canUserDo(user, protection))  return <Redirect to='/documents' />;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Route {...rest} />;
}
/** Component used for routes which should be accessible only then loged in */
export default connect(mapStateToProps)(PrivateRoute);
