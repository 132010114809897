import { Card, CardHeader, Checkbox, Form, Input, Skeleton, TextArea, useForm } from "@sis-lab/web-ui-components";
import React, { useEffect, useState } from "react";
import { useI18nContext } from "i18n/i18n-react";
import EditFooter from "../../../PaymentsTab/components/EditFooter/EditFooter";
import usePeppolConfig from "./usePeppolConfig";
import styles from './PeppolConfigCard.module.scss';

export default function PaypolConfigCard() {
  const [config, loading, missingConfig, updateConfig] = usePeppolConfig()
  const [inEdit, setInEdit] = useState(false)
  const [validateMessages, setValidateMessages] = useState(false)
  const { LL } = useI18nContext()
  const form = useForm()

  const showSkeleton = !config && loading && !form.formState.isDirty

  useEffect(() => {
    if (!config) return;
    form.clearErrors()
    form.setValue('peppolId', config.peppolId)
    form.setValue('proxyId', config.proxyId)
    form.setValue('email', config.notificationEmail)
    form.setValue('apiToken', config.apiToken)
    setValidateMessages(config.validateMessages);
  }, [config, inEdit])

  const startUpdate = async () => {
    form.clearErrors()
    const fieldValues = form.getValues()
    await updateConfig({
      validateMessages,
      apiToken: fieldValues.apiToken,
      notificationEmail: fieldValues.email,
    })
    setInEdit(false)
  }

  return (
    <Card className={styles.configCard}>
      <CardHeader title={LL.peppolTab.configTitle()} />
      {showSkeleton && <Skeleton count={4} style={{ marginBottom: '20px' }} />}
      {missingConfig && LL.peppolTab.missingConfig()}
      <Form
        disabled={!inEdit}
        formHook={form}
        className={[styles.cardBody, (missingConfig || showSkeleton) && styles.hidden].join(' ')}
      >
        <Input
          name='peppolId'
          title={LL.peppolTab.peppolId.title()}
          disabled
        />
        <Input
          name='proxyId'
          title={LL.peppolTab.proxyId.title()}
          disabled
        />
        <Input
          name='email'
          title={LL.peppolTab.notificationEmail.title()}
          disabled={loading || !inEdit}
        />
        <TextArea
          name='apiToken'
          title={LL.peppolTab.apiToken.title()}
          bodyClassName={styles.apiTokenField}
          disabled={loading || !inEdit}
        />
        <Checkbox
          disabled={!inEdit}
          className={styles.checkboxWrapper}
          checked={validateMessages}
          onChange={() => setValidateMessages(!validateMessages)}
          // className={selectedDocument.completed ? styles.hidden : styles.storeCheckbox}
          children='Validate Messages'
          tooltip='Validate uploaded XML messages before sending them to Peppol'
        />
      </Form>
      {!missingConfig && <EditFooter
        inEdit={inEdit}
        onChange={setInEdit}
        loading={loading}
        onSumbit={startUpdate}
      />}
    </Card>
  )
}
