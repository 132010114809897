import React from 'react';
import { Avatar, Caption, Label } from '@sis-lab/web-ui-components';
import { ISignatureDetailed } from 'apis/contract';
import Stamp from 'components/Stamp/Stamp';
import { transformDate } from 'utils';
import styles from './SignatureRow.module.scss';

interface Props {
  signature: ISignatureDetailed
}

export default function SignatureRow({ signature }: Props) {
  return <div className={styles.row}>
    <Avatar width={40} name={signature.creator.name} />
    <div className={styles.nameColumn}>
      <Label>{signature.creator.name}</Label>
      <Caption small>{signature.creator.email}</Caption>
    </div>
    <Stamp
      status={signature.status === 'accept' ? 'signed' : 'rejected'}
      children={transformDate(signature.createdAt).substring(0, 16)}
    />
  </div>
} 
