import React from 'react';
import { Avatar, Sider, Title } from '@sis-lab/web-ui-components';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useI18nContext } from 'i18n/i18n-react';
import { UserDetailed } from 'apis/auth';
import { ApplicationState } from 'modules';
import corposignLogo from 'images/corposign-logo.svg';
import canUserDo from 'utils/unique/canUserDo';
import styles from './CorpoSider.module.scss';
import { navigationRoutes } from '../navigationRoutes';


interface Props {
  user?: UserDetailed
  documentInvitesCount?: number
  prmInvitesCount?: number
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.authentication.user,
  documentInvitesCount: state.authentication.documentInvitesCount,
  prmInvitesCount: state.authentication.prmInvitesCount
});


function CorpoSider({ user, documentInvitesCount, prmInvitesCount }: Props) {
  if (!user) return <div/>
  const { LL } = useI18nContext()
  const history = useHistory();
  const location = useLocation();
  const allowedRoutes = navigationRoutes.filter(({ protection }) => !protection || canUserDo(user, protection))

  return (
    <Sider
      className={styles.sider}
      onTitleClick={() => history.push('/documents')}
      title={<Title seo='h1' styling='h6'>CorpoSign</Title>}
      logo={<img src={corposignLogo} width={40} height={40} alt='logo'/>}
      selectedKey={location.pathname}
      items={allowedRoutes.map(route => {
        if ('isDivider' in route) return {
          isDivider: true,
          className: styles.divider,
        }

        if (route.route === '/profile/0') return {
          key: route.route,
          prefix: <Avatar className={styles.avatar} width={24} name={user.sub} />,
          className: styles.profile,
          content: LL.navigation[(route.content as 'documents')](),
          onClick: () => history.push(route.route!),
        }

        if (route.route === '/invites') return {
          key: route.route,
          prefix: route.prefix,
          content: LL.navigation[(route.content as 'documents')](),
          onClick: () => history.push(route.route!),
          suffix: !!documentInvitesCount && (
            <div className={styles.badge}>
              <span>
                  {documentInvitesCount > 9 ? '9+' : documentInvitesCount}
              </span>
            </div>
          )
        }

        if (route.route === '/payments') return {
          key: route.route,
          prefix: route.prefix,
          content: LL.navigation[(route.content as 'documents')](),
          onClick: () => history.push(route.route!),
          suffix: !!prmInvitesCount && (
            <div className={styles.badge}>
              <span>
                {prmInvitesCount > 9 ? '9+' : prmInvitesCount}
              </span>
            </div>
          )
        }

        return {
          key: route.route,
          prefix: route.prefix,
          content: LL.navigation[(route.content as 'documents')](),
          onClick: () => history.push(route.route!)
        }
      })}
    />
  );
}

export default connect(mapStateToProps)(CorpoSider);
