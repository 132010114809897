import { PRM, PrmFilter } from "apis/prmApi";
import { createContext } from "react";


export type PaymentsContextState = {
  filter?: PrmFilter,
  showIncoming: boolean
  reset: boolean
  abortSignal?: AbortSignal
  // payments: PRM[],
  // loading: boolean,
  // isFullyLoaded: boolean,
  // updatePayments: (newPrm: PRM) => void
  // fetchPayments: (signal?: AbortSignal) => Promise<void>
  setReset: (val: boolean) => void
  setAbortSignal: (val: AbortSignal) => void
  setShowIncoming: (val: boolean) => void
  setFilter: (val: PrmFilter) => void
}

export default createContext<PaymentsContextState>({
  filter: undefined,
  showIncoming: true,
  reset: false,
  // payments: [],
  // loading: false,
  // isFullyLoaded: true,
  // fetchPayments: () => new Promise(resolve => { resolve() }),
  // updatePayments: () => null,
  setShowIncoming: () => null,
  setReset: () => null,
  setAbortSignal: () => null,
  setFilter: () => null,
});
