import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  Divider,
  Form,
  Input,
  message,
  useForm,
} from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import useFetchIdentity from './useFetchIdentity';
import styles from './CertCard.module.scss';

export default function CertCard() {
  const [identity, error] = useFetchIdentity();
  const { LL } = useI18nContext()
  const form = useForm();

  if (identity) {
    form.setValue('id', identity?.certificateId)
    form.setValue('isValid', LL.certCard.validUntil(identity.notValidAfter.substring(0, 10)))
    // form.setValue('isValid', `Valid until: ${identity?.notValidAfter.substring(0, 10)}`)
    form.setValue('company', identity?.organizationName)
    form.setValue('companyID', identity?.registrationNumber)
    form.setValue('position', identity?.position)
    form.setValue('parentSub', identity?.parentSub)
  }

  if (error) return <ErrorPage errorMessage={error.message} />
  return (
    <Card
      className={styles.card}
      loading={!identity}
      size='small'
    >
      <CardHeader
        title={LL.certCard.title()}
        suffix={
          <Button
            type='secondary'
            mobileIcon='content_copy'
            children={LL.common.certificate()}
            suffix='content_copy'
            className={styles.copyBtn}
            copyText={identity?.certificates[identity.certificateId]}
          />
        }
      />
      <Form size='medium' disabled formHook={form} className={styles.inputLayout}>
        <Input
          name='id'
          title={LL.commonFields.certificateFingerprint.title()}
          tooltip={LL.commonFields.certificateFingerprint.tooltip()}
        />
        <Input name='isValid' title={LL.certCard.certificateValidity.title()} />
        <Divider size='tiny' className={identity?.parentSub ? '' : styles.hidden} />
        <div className={identity?.parentSub ? styles.companyRow : styles.hidden}>
          <Input name='company' title={LL.commonFields.companyName.title()} />
          <Input name='companyID'  title={LL.commonFields.companyId.title()} />
        </div>
        <Input
          name='parentSub'
          wrapperClassName={identity?.parentSub ? '' : styles.hidden}
          title={LL.commonFields.parentSub.title()}
          suffix='content_copy'
          onSuffix={() => {
            navigator.clipboard.writeText(identity?.parentSub || '');
            message.success(LL.common.textCopied(), 1)
          }}
        />
        <Input
          name='position'
          wrapperClassName={identity?.position ? '' : styles.hidden}
          title={LL.commonFields.position.title()}
        />
      </Form>
    </Card>
  );
}
