import React, { useState } from 'react';
import { useI18nContext } from 'i18n/i18n-react';
import PageHeader from 'components/PageHeader/PageHeader';
import DocumentCard from './components/DocumentCard/DocumentCard';
import { DocumentInfo } from './components/types';
import DocumentUploadCard from './components/DocumentUploadCard/DocumentUploadCard';
import HistoryCard from './components/HistoryCard/HistoryCard';
import styles from './UploadPage.module.scss'


export default function UploadPage() {
  const [selectedDoc, setSelectedDoc] = useState<DocumentInfo>()
  const { LL } = useI18nContext()

  return (
    <div>
      <PageHeader title={LL.uploadPage.title()} />
      <div className={styles.body}>
        <DocumentUploadCard
          selectedDoc={selectedDoc}
          onSelect={doc => setSelectedDoc(doc ? JSON.parse(JSON.stringify(doc)) : undefined)}
        />
        {selectedDoc && (
          <DocumentCard
            selectedDocument={selectedDoc}
            onEdit={(updatedDoc) => setSelectedDoc(updatedDoc)}
          />
        )}
        {!selectedDoc && <HistoryCard />}
      </div>
    </div>
  )
}
