import { instance, setAccessToken } from './instance';
import {
  findKeyByXml,
  transformXmlWithXslt,
  visualizeXmlWithXslt,
  transformXmlIntoPrm,
} from './services';

export default {
  instance,
  findKeyByXml,
  transformXmlWithXslt,
  visualizeXmlWithXslt,
  transformXmlIntoPrm,
  setAccessToken,
}
