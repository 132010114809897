import { useEffect, useState } from 'react';
import aiApi from 'apis/aiApi';
import { message } from '@sis-lab/web-ui-components';

const ALLOWED_TYPES = [
  "application/pdf",
  "image/gif",
  "image/tiff",
  "image/jpeg",
  "image/png",
  "image/bmp",
  "image/webp"
];


const useGetSummary = (fileBlob?: Blob) => {
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allowed, setAllowed] = useState(false);

  useEffect(() => {
    if (!fileBlob) return;
    setAllowed(ALLOWED_TYPES.includes(fileBlob.type))
  }, [fileBlob])

  const reset = () => {
    setDone(false);
    setAllowed(false);
    setLoading(false);
  }

  const getSummary = async (fileName: string) => {
    if (done) return '';
    if (!fileBlob) return '';
    if (!ALLOWED_TYPES.includes(fileBlob.type)) return '';
    try {
      setLoading(true);
      const { data: summary } = await aiApi.getSummary(fileBlob, fileName);
      setDone(true);
      return summary;
    } catch (e) {
      message.error(`Could not get summary for your document`)
      return '';
    } finally {
      setLoading(false);
    }
  }

  return [getSummary, reset, loading, done, allowed] as const;
}

export default useGetSummary;
