import React, { useContext } from 'react';
import { Card, Form, Input, TextArea, message, useForm, Divider, CardHeader, Skeleton } from '@sis-lab/web-ui-components';
import prmApi from 'apis/prmApi';
import CorpoFilePreview from 'components/CorpoFilePreview/CorpoFilePreview';
import { useI18nContext } from 'i18n/i18n-react';
import PrmContext from '../PrmContext/PrmContext';
import StatusField from './components/StatusField/StatusField';
import QrCodeButton from './components/QrCodeButton/QrCodeButton';
import styles from './PaymentInfoCard.module.scss';


export default function PaymentInfoCard() {
  const { prm } = useContext(PrmContext)
  const { LL } = useI18nContext()
  const form = useForm();
  const showQrButton = ['created', 'invited', undefined].includes(prm?.status)
  
  return (
    <Card className={styles.card}  size='small'>
      <CardHeader title={LL.paymentPage.infoCard.title()} suffix={showQrButton && <QrCodeButton />} />
      {!prm && <Skeleton count={5} style={{ marginBottom: 20 }} />}
      <Form disabled className={[styles.prmInfo, !prm && styles.hidden].join(' ')} formHook={form} size='medium'>
        <div className={styles.amountRow}>
          <Input
            name='amount'
            prefix={'euro' as 'add'}
            title={LL.paymentCommonFields.amount.title()}
            value={prm?.amount.toFixed(2)}
            bodyClassName={styles.currency}
          />
          {prm && <StatusField status={prm.status} updatedAt={prm.updatedAt} />}
        </div>
        <TextArea
          name='description'
          title={LL.paymentCommonFields.description.title()}
          value={prm?.description}
          wrapperClassName={!prm?.description ? styles.hidden : ''}
        />
        {prm && (
          <CorpoFilePreview
            fetchFile={async () => prmApi.getPrmDocument(prm.id)}
            documentId={prm.id}
          />
        )}
        <Divider size='small'/>
        <Input
          name='id'
          title={LL.paymentPage.infoCard.requestIdField.title()}
          tooltip={LL.paymentPage.infoCard.requestIdField.tootlip()}
          value={prm?.id}
          suffix={'content_copy' as 'arrow_down'}
          onSuffix={() => {
            navigator.clipboard.writeText(prm?.id || '');
            message.success(LL.common.textCopied(), 1)
          }}
        />
      </Form>
    </Card>
  )
}
