import { RcFile } from "@sis-lab/web-ui-components"
import contract from "apis/contract"
import aiApi, { AiPreferences } from "apis/aiApi"
import { useI18nContext } from "i18n/i18n-react"
import { useState } from "react"
import { getFileFingerprint, localeContains } from "utils"
import { ApplicationState } from "modules"

import { useSelector } from "react-redux"
import { NewDocumentInfo } from "../../types"


const useFileUpload = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [document, setDocument] = useState<NewDocumentInfo>()
  const { LL } = useI18nContext()
  const aiPreferences = useSelector<ApplicationState, AiPreferences | undefined>(state => state.authentication.aiPreferences)
  let documentInfo: NewDocumentInfo | undefined

  const uploadFile = async (file: RcFile) => {
    setLoading(true)
    setError(undefined)
    setDocument(undefined)

    try {
      if (file.size / 1048576 > 50)
        throw new Error(`File ${file.name} is too big`)

      const fingerprint = await getFileFingerprint(file);
      const { data: distinctivness } = await contract.checkFingerprints([fingerprint])

      if (distinctivness[fingerprint].exists)
        throw new Error(LL.errors.uploadPage.fileAlreadyCreated(file.name))

      const buffer = await file.arrayBuffer();
      const format = file.name.split('.').pop()?.toLowerCase() || ''
      documentInfo = {
        blob: file,
        buffer,
        format,
        fingerprint,
        title: file.name,
        data: Array.from(new Uint8Array(buffer)),
        shortTitle: file.name.length > 30 ? `${file.name.substring(0, 28)} ... .${format}` : undefined
      } as NewDocumentInfo

      if (aiPreferences?.enabled) {
        const { data: predictedType } = await aiApi.classifyByFileName(file.name);
        documentInfo.type = predictedType;
      }

      // if ((localeContains(file.name, 'Nurašym') || localeContains(file.name, 'Atsarg')) && documentTypes.writeOff) {
      //   documentInfo.type = 'writeOff'
      // } else if ((
      //   localeContains(file.name, 'Degalu') || localeContains(file.name, 'žurnal')) && documentTypes.petroleumProductsJournal
      // ) {
      //   documentInfo.type = 'petroleumProductsJournal'
      // } else if (localeContains(file.name, 'inventor') && documentTypes.inventory) {
      //   documentInfo.type = 'inventory'
      // }

    } catch (err: unknown) {
      if (err instanceof Error)
        setError(err.message)
    }

    setLoading(false)
    setDocument(documentInfo)
    return documentInfo
  }

  return [uploadFile, error, loading, document] as const
}

export default useFileUpload
