import axios from "axios"
import { useEffect, useState } from "react"

import prmApi, { RtpConfig } from "apis/prmApi"
import { message } from "@sis-lab/web-ui-components";


const useGetRtpConfig = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [rtpConfig, setRtpConfig] = useState<RtpConfig>();

  useEffect(() => {
    prmApi.getRtpConfig()
      .then(({ data }) => setRtpConfig(data))
      .catch((err) => {
        if (!axios.isAxiosError(err)) return

        if (err.response?.status !== 404) {
          message.error('Something went wrong while loading RTP config')
          console.log(err)
        } else {
          message.warning(`RTP config is not setup`);
        }
      })
      .finally(() => setLoading(false))
  }, [])
  
  const updateRtpConfig = async (newConfig: RtpConfig) => {
    setLoading(true)
    prmApi.updateRtpConfig(newConfig)
      .then(() => setRtpConfig({ ...rtpConfig, ...newConfig }))
      .catch(err => {
        if (!axios.isAxiosError(err)) return
        message.error('Something went wrong while updating RTP config')
        console.log(err)
      })
      .finally(() => setLoading(false))
  }

  return [rtpConfig, loading, updateRtpConfig] as const
}

export default useGetRtpConfig
