/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button, Input, useForm, SelectInstance, FormSelectAsync } from "@sis-lab/web-ui-components";
import { useI18nContext } from "i18n/i18n-react";
import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useSelector } from "react-redux";
import { ApplicationState } from "modules";
import authApi from "apis/auth";
import inviteeValidation from "./inviteValidation";
import styles from './InviteSelector.module.scss';

interface Props {
  invitees: string[]
  owner: string
  currentParticipants: string[]
  errorMessage?: string
  updateList: (invitees: string[]) => void 
  clearErrors?: () => void
}

// * sort values so groups would be first
// * value: email split with ,
// * DONE: on validation - accept comma-separated values
// * DONE: what if one of group is already invited????
// * DONE: after validation - return type? and add multiple values

export default function InviteSelector({ invitees, currentParticipants, owner, updateList, errorMessage, clearErrors }: Props) {
  const form = useForm();
  const { LL } = useI18nContext();
  const userSub = useSelector((state: ApplicationState) => state.authentication.user?.sub);

  const selectRef = useRef<SelectInstance<false> | null>(null);
  const abortControllerRef = useRef(new AbortController());
  
  const [inputValue, setInputValue] = useState<string>();
  const [localErrorMessage, setLocalErrorMessage] = useState<string>();

  const loadSuggestions = useCallback(async (search: string, _: any, additional: any) => {
    abortControllerRef.current.abort()
    abortControllerRef.current = new AbortController()

    const [mailGroupsResp, membersResp] = await Promise.all([
      authApi.getMailGroups(
        { bookmark: additional?.mailGroupsBookmark, substring: search, pageSize: 50, order: 'asc', sortBy: 'name' },
        abortControllerRef.current.signal
      ),
      authApi.getMembers(
        { bookmark: additional?.membersBookmark, substring: search, pageSize: 7, order: 'asc', sortBy: 'name' },
        abortControllerRef.current.signal
      )
    ])

    const { data: mailGroups } = mailGroupsResp;
    const { data: members } = membersResp;

    // TODO: clean up at backend + store session in redis with common bookmark and what is filtered? check in outlook
    const cleanedMembers = members.items.filter(member => (
        member.sub !== userSub
        && !invitees.includes(member.email)
        && !currentParticipants.includes(member.email)
    ));

    const uniqueMemberEmails = [...new Set(cleanedMembers.map(member => member.email))];

    const options = []
    
    if (additional?.shouldLoadGroups) {
      options.push(
        ...mailGroups.items.map(mailGroup => ({ label: `👥 ${mailGroup.name}`, value: mailGroup.emails.join(',') }))
      )
    }

    options.push(
      ...uniqueMemberEmails.map(email => ({ label: email, value: email }))
    );
    
    return {
      options,
      hasMore: members.items.length > 10 || members.bookmark !== '',
      additional: {
        membersBookmark: members.bookmark,
        mailGroupsBookmark: mailGroups.bookmark,
        shouldLoadGroups: false,
      },
    };
  }, [abortControllerRef, invitees])

  const addInvitee = () => {
    form.clearErrors();
    setLocalErrorMessage(undefined);

    if (!inputValue) return;
    const newInvitees: string[] = [];
    let errorMsg = ''

    // * ";" "," and " " are treated as separators for multi email input, also trim excess spaces on sides
    const inputValues = [
      ...new Set(
        inputValue
          .replace(';', ',')
          .replace(' ', ',')
          .split(',')
          .map(s => s.trim())
      )
    ]
    inputValues.forEach(invitee => {
      const error = inviteeValidation(invitee, owner, invitees, currentParticipants)
      if (!error) {
        newInvitees.push(invitee);
      } else {
        errorMsg += `${error}; `;
      }
    })

    if (errorMsg) {
      setLocalErrorMessage(errorMsg);
      form.setError('invitee', { message: errorMsg });
    }
    updateList([...invitees, ...newInvitees]);
    setInputValue(undefined);
    selectRef.current?.clearValue()
  }

  return (
    <div className={styles.wrap}>
      <div className={[styles.addLine, !form.formState.isValid && styles.error].join(' ')}>
        {/* <Input
          name='invitee'
          title={LL.documentsPage.inviteTitle()}
          placeholder={LL.documentsPage.invitePlaceholder()}
          errorMessage={uploadError}
          onChange={() => { form.clearErrors(); if (clearErrors) clearErrors() }}
          formHook={form}
        /> */}
        <FormSelectAsync
          name='invitee'
          formHook={form}
          isMulti={false}
          isCreatable={false}
          // value={inputValue && { value: inputValue, label: inputValue } as any}
          inputValue={inputValue}
          className={styles.select}
          placeholder={LL.documentsPage.invitePlaceholder()}
          noOptionsMessage={() => null}
          selectRef={selectRef}
          debounceTimeout={300}
          cacheUniqs={[invitees]}
          loadOptions={loadSuggestions}
          additional={{ membersBookmark: '', mailGroupsBookmark: '', shouldLoadGroups: true }}
          onChange={(val, action) => action.action !== 'clear' ? val && setInputValue(val.value) : setInputValue(undefined)}
          onInputChange={(val, action) => action.action !== 'input-blur' && action.action !== 'menu-close' && setInputValue(val)}
          errorMessage={localErrorMessage || errorMessage}
          title={LL.documentsPage.inviteTitle()}
        />
        <Button icon='add' onClick={addInvitee} />
      </div>
      {invitees.length > 0  && (
        <div className={styles.list}>
          {invitees.map(invitee => (
            <Input
              key={invitee}
              disabled
              name={invitee}
              formHook={form}
              value={invitee}
              suffix='close'
              onSuffix={() => updateList(invitees.filter(email => email !== invitee))}
            />
          ))}
        </div>
      )}
    </div>
  );
}
