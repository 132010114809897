import { AxiosError } from 'axios';
import React, { useContext, useState } from 'react';
import {
  Button,
  Form,
  Icon,
  Input,
  Modal,
  useForm
} from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import { EMAIL_REG_EXP } from 'utils/consts';
import auth from 'apis/auth';
import { errorParser } from 'utils/requests/errorParser';
import SealsContext from '../SealsContext/SealsContext';
import styles from './NewSealModal.module.scss';

interface Props {
  open: boolean
  onCancel: () => void
}


export default function NewSealModal({ open, onCancel }: Props) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<AxiosError>()
  const { seals, setSeals } = useContext(SealsContext)
  const { LL } = useI18nContext()
  const form = useForm();

  const handleClose = () => {
    setError(undefined)
    form.clearErrors()
    form.setValue('email', undefined)
    form.setValue('name', undefined)
    onCancel()
  }

  const onCreate = async () => {
    const newErrors: { name: string, message: string }[] = []
    form.clearErrors()
    setError(undefined)

    const email = form.getValues('email') as string
    const sealName = form.getValues('name') as string

    if (!email || !email.match(EMAIL_REG_EXP)) newErrors.push({ name: 'email', message: LL.errors.common.invalidEmail() })
    if (!sealName) newErrors.push({ name: 'name', message: 'Missing Seal title' })

    if (newErrors.length !== 0) {
      newErrors.map(({ name, message }) => form.setError(name, { message }))
      return
    }

    setLoading(true)
    await auth.createSeal(email, sealName)
      .then(({ data: newSeal }) => {
        setSeals([newSeal, ...seals])
        handleClose()
      })
      .finally(() => setLoading(false))
      .catch(e => errorParser(e, err => setError(err)))
  }

  return (
    <Modal
      open={open}
      wrapClassName={styles.sealModal}
      footer={null}
      onCancel={handleClose}
      title={
        <div className={styles.modalHeader}>
          <Icon className={styles.icon} name={'add' as 'arrow_down'} />
          <h3>Create new Seal</h3>
        </div>
      }
    >
      <Form className={styles.form} formHook={form}>
        <Input
          name='email'
          title={LL.commonFields.email.title()}
          placeholder={LL.commonFields.email.placeholder()}
        />
        <Input
          name='name'
          title='Seal title'
          placeholder='Enter Seal Title'
        />
        <p className={styles.error}>{error?.response?.status} {error?.response?.statusText}</p>
      </Form>

      <div className={styles.modalFooter}>
        <Button
          onClick={handleClose}
          type='secondary'
          disabled={loading}
          children={LL.commonButtons.cancel()}
        />
        <Button
          disabled={loading}
          prefix={loading ? 'loading' : undefined}
          onClick={onCreate}
          children={LL.commonButtons.create()}
        />
      </div>
    </Modal>
  )
}
