import { Locale } from "@sis-lab/web-ui-components";
import TypesafeI18n from "i18n/i18n-react";
import { loadLocaleAsync } from "i18n/i18n-util.async";
import React, { ReactNode, useEffect, useState } from "react";
import { detectLocale, localStorageDetector } from "typesafe-i18n/detectors";

interface Props {
  children: ReactNode
}

export const availableLocales: Locale[] = ['en', 'lt', 'ru', 'de', 'lv', 'ee'] 
export const detectedLocale = detectLocale<Locale>('en', availableLocales,  localStorageDetector)


export default function LocalizationProvider({ children }: Props) {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    loadLocaleAsync(detectedLocale).then(() => setLoaded(true))
  }, [detectedLocale])

  if (!loaded) return null
  return <TypesafeI18n locale={detectedLocale}  children={children}/>
}
