import React from 'react';
import { Menu, Modal } from '@sis-lab/web-ui-components';
import { useHistory, useLocation } from 'react-router-dom';
import { useI18nContext } from 'i18n/i18n-react';
import { UserDetailed } from 'apis/auth';
import { ApplicationState } from 'modules';
import { logoutAction } from 'modules/authentication/actions';
import { connect, useDispatch } from 'react-redux';
import canUserDo from 'utils/unique/canUserDo';
import { navigationRoutes } from '../navigationRoutes';
import styles from './MobileModal.module.scss';

interface Props {
  isOpen: boolean
  user?: UserDetailed
  onClose: () => void
  documentInvitesCount?: number
  prmInvitesCount?: number
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.authentication.user,
  documentInvitesCount: state.authentication.documentInvitesCount,
  prmInvitesCount: state.authentication.prmInvitesCount
});

function MobileModal({ user, isOpen, onClose, documentInvitesCount, prmInvitesCount }: Props) {
  if (!user) return <div/>
  const { LL } = useI18nContext()
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const allowedRoutes = navigationRoutes.filter(({ protection }) => !protection || canUserDo(user, protection))

  const onNavigate = (route: string) => {
    history.push(route)
    onClose(); 
  }

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      onOk={() => logoutAction()(dispatch)}
      okText={LL.commonButtons.logout()}
      cancelText={LL.commonButtons.cancel()}
      title={(
        <div className={styles.header}>
          <h1 className={styles.title}>CorpoSign</h1>
        </div>
    )}
    >
      <Menu
        className={styles.menu}
        items={allowedRoutes.map(route => {
          if ('isDivider' in route) return {
            isDivider: true,
            className: styles.divider,
          }

          if (route.route === '/invites') return {
            key: route.route,
            prefix: route.prefix,
            content: LL.navigation[(route.content as 'documents')](),
            onClick: () => onNavigate(route.route!),
            suffix: !!documentInvitesCount && (
              <div className={styles.badge}>
                <span>
                  {documentInvitesCount > 9 ? '9+' : documentInvitesCount}
                </span>
              </div>
            )
          }

          if (route.route === '/payments') return {
            key: route.route,
            prefix: route.prefix,
            content: LL.navigation[(route.content as 'documents')](),
            onClick: () => onNavigate(route.route!),
            suffix: !!prmInvitesCount && (
              <div className={styles.badge}>
                <span>
                  {prmInvitesCount > 9 ? '9+' : prmInvitesCount}
                </span>
              </div>
            )
          }

          return {
            key: route.route,
            prefix: route.prefix,
            content: LL.navigation[(route.content as 'documents')](),
            onClick: () => onNavigate(route.route!),
          }
        })}
        selectedKey={location.pathname}
        size='medium'
      />
    </Modal>
  );
}

export default connect(mapStateToProps)(MobileModal);
