import React from 'react';
import { ColumnsType } from '@sis-lab/web-ui-components';
import { TranslationFunctions } from 'i18n/i18n-types';
import { IDocumentBasic } from 'apis/contract';
import { transformDate } from 'utils';
import Stamp from 'components/Stamp/Stamp';
import styles from './DefaultColumns.module.scss';

export const DefaultColumns = (LL: TranslationFunctions): ColumnsType<IDocumentBasic> => [
  {
    title: 'Title',
    key: 'title',
    width:  'auto',
    ellipsis: true,
    render: (_text, record) => (
      <div className={styles.title}>
        <h3>{record.title}</h3>
        <p>{LL.documentTypes[record.type as 'workOrder']() || record.type}</p>
      </div>
    ),
  },
  {
    title: 'Status',
    key: 'status',
    width: '225px',
    // REFACTOR: It should not require status check!
    render: (_text, document) => document.status !== 'created' ? (
      <Stamp
        children={transformDate(document.createdAt).substring(0, 16)}
        invites={document.invites}
        signatures={document.signatures || []}
        status={document.status}
      />
    ) : (
      <Stamp
        children={transformDate(document.createdAt).substring(0, 16)}
        status={document.status}
        />
    )
    // render: (_text, document) => (
    //   <Stamp
    //     children={transformDate(document.createdAt).substring(0, 16)}
    //     invites={document.invites}
    //     signatures={document.signatures}
    //     status={document.status}
    //   />
    // )
  },
];

export default DefaultColumns
