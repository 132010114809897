import { Button, Modal, TextArea, useForm } from '@sis-lab/web-ui-components';
import documents, { signDocument, SignDocumentError } from 'apis/contract';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { useI18nContext } from 'i18n/i18n-react';
import { getUserLocation } from 'utils';
import { ApplicationState } from 'modules';
import { setUserErrorAction, setDocumentInvitesCountAction } from "modules/authentication/actions";
import DocumentContext from 'pages/DocumentPage/components/DocumentContext/DocumentContext';
import styles from './SignModal.module.scss';

interface Props {
  action?: 'accept' | 'reject'
  onCancel: () => void
  fingerprint: string
}

interface FooterProps {
  onCancel: () => void
  onOk: () => void
  action?: 'accept' | 'reject'
  loading?: boolean
}


function ModalFooter({ action, onCancel, onOk, loading }: FooterProps) {
  const { LL } = useI18nContext()

  return <div className={styles.footer}>
    <Button
      disabled={loading}
      type='secondary'
      children={LL.commonButtons.cancel()}
      onClick={onCancel}
    />
    <Button
      disabled={loading}
      children={LL.participantTab[action === 'accept' ? 'signDocument' : 'rejectDocument']()}
      state={action === 'reject' ? 'warning' : undefined}
      suffix={loading ? 'loading' : undefined}
      onClick={onOk}
    />
  </div>
}


export default function SignModal({ action, onCancel, fingerprint }: Props) {
  const [loading, setLoading] = useState(false);
  const { fetchDocument } = useContext(DocumentContext)
  const { LL } = useI18nContext()
  const metadata = useSelector((state: ApplicationState) => state.authentication.metadata)
  const form = useForm();
  const dispatch = useDispatch();

  const sign = async () => {
    if (!action) return;
    form.clearErrors();
    setLoading(true);

    const signatureData = {
      notes: form.getValues('notes') || undefined,
      status: action,
      location: metadata?.preferences.provideLocation ? await getUserLocation() : undefined,
    };

    try {
      await signDocument(fingerprint, signatureData); 
      documents.getMyInvitesCount()
        .then(({ data }) => setDocumentInvitesCountAction(data.count)(dispatch))
        .catch(e => setUserErrorAction(e)(dispatch));

      await fetchDocument()
      onCancel()
    } catch(e) {
      if (!axios.isAxiosError(e)) return      
      form.setError('notes', {
        message: LL.errors.contract.signDocumentErrors[e.response?.data.message as SignDocumentError]()
      })
    };
    setLoading(false)
  }

  return (
    <Modal  
      footer={<ModalFooter onOk={sign} onCancel={onCancel} action={action} loading={loading} />}
      onCancel={loading ? undefined : onCancel}
      open={!!action}
      title={LL.participantTab[action === 'accept' ? 'signModalTitle' : 'rejectModalTitle']()}
      size='small'
    >
      <TextArea
        name='notes'
        placeholder={LL.commonFields.notes.placeholder()}
        formHook={form}
        rules={{ maxLength: { value: 200, message: 'Maximum note length is 200' }}}
      />
    </Modal>
  )
}
