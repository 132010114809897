import React from "react";
import { Icon } from "@sis-lab/web-ui-components";
import { useI18nContext } from "i18n/i18n-react";
import styles from './NoHistory.module.scss'


export default function NoHistory() {
  const { LL } = useI18nContext()

  return (
    <div className={styles.wrapper}>
      <Icon name='info_outline' />
      <p>{LL.uploadPage.noHistoryText()}</p>
    </div>
  )
}
