import React from "react";
import PaymentsProvider from "./components/PaymentsProvider";
import PaymentsPage from "./PaymentsPage";

export default function PaymentsPageWrapper() {
  return (
    <PaymentsProvider>
      <PaymentsPage/>
    </PaymentsProvider>
  )
}