import React from 'react';
import { Button, Card, CardHeader, Icon } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import useDownloadCertificate from '../DocumentValidityCard/useDownloadCertificate';
import styles from './DownloadCertificateCard.module.scss'

interface Props {
  fingerprint: string
}


export default function DownloadCertificateCard({ fingerprint }: Props) {
  const [download, loading] = useDownloadCertificate(fingerprint);
  const { LL } = useI18nContext()
  
  return (
    <Card className={styles.certificateCard} size='small'>
      <CardHeader title={ LL.downloadCertificateCard.title()} />
      <ul>
        <li>
          <Icon className={styles.certIcon} name='check_circle_outline' />
          {LL.downloadCertificateCard.firstRow()}
        </li>
        <li>
          <Icon className={styles.certIcon} name='check_circle_outline'/>
          {LL.downloadCertificateCard.secondRow()}
        </li>
        <li>
          <Icon className={styles.certIcon} name='check_circle_outline'/>
          {LL.downloadCertificateCard.thirdRow()}
        </li>
      </ul>
      <ul>
        <li className={styles.infoCert}>
          <Icon className={styles.certIcon} name='info_outline' />
          {LL.downloadCertificateCard.fourthRow()}
        </li>
      </ul>
      <div className={styles.download}>
        <Button
          className={styles.downloadBtn}
          disabled={loading}
          onClick={download}
          children={LL.downloadCertificateCard.downloadButton.text()}
          suffix={loading ? 'loading' : 'download'}
        />
      </div>
    </Card>
  )
}
