import { instance } from '../instance';
import { GenerateTokenRequest, GenerateTokenReturn, Token } from './types';

export const generateToken = (req: GenerateTokenRequest) => (
  instance.post<GenerateTokenReturn>('/tokens', req)
)

export const getTokens = () => (
  instance.get<{ tokens: Token[] }>('/tokens')
)

export const getToken = (id: string) => (
  instance.get<Token>(`/token/${id}`)
)

export const updateToken = (id: string, paused: boolean) => (
  instance.put(`/tokens/${id}`, { paused })
)

export const deleteToken = (id: string) => (
  instance.delete(`/tokens/${id}`)
)
