/* eslint-disable no-restricted-syntax */
import { useState } from "react"
import contract, { ICreateDocumentRequest } from "apis/contract";
import config from "config";
import axios from "axios";
import { useI18nContext } from "i18n/i18n-react";
import { ApplicationState } from "modules";
import { useSelector } from "react-redux";
import { Position, getUserLocation } from "utils";
import { DocumentInfo } from "../../types"


const createDocument = async (doc: DocumentInfo, sign: boolean, location?: Position) => {
    const document: ICreateDocumentRequest = {
      sign,
      location, 
      fingerprint: doc.fingerprint,
      format: doc.format,
      notes: doc.notes !== '' ? doc.notes : undefined,
      title: doc.title,
      type: doc.type || 'other',
      invitees: doc.invitees,
    };

    if (doc.saveForPreview) {
      const documentMeta = await contract.addDocument({
        fileName: doc.title,
        fingerprint: doc.fingerprint,
        documentContent: {
          name: doc.title,
          format: doc.format,
          data: doc.data,
        },
      });
      document.uri = `${config.storageApiUrl}/files/${documentMeta.data.id}`;
    }

    await contract.createDocument(document);
}


const useCreateDocuments = (list: DocumentInfo[]) => {
  const [loading, setLoading] = useState(false)
  const { LL } = useI18nContext()
  const [signOnUpload, provideLocation] = useSelector((state: ApplicationState) => {
    const hasPreferences = state.authentication.user?.permissions
    const allowedToSign = hasPreferences ? state.authentication.user?.permissions?.allowedToSign : true
    const prefersToSignOnUpload = state.authentication.metadata?.preferences?.signOnUpload
    return [allowedToSign && prefersToSignOnUpload, state.authentication.metadata?.preferences?.provideLocation]
  })

  const createDocuments = async () => {
    setLoading(true)
    const updatedList = list.slice();
    const location = provideLocation ? await getUserLocation() : undefined

    const promises = list.map((doc, index) => {
      if (doc.completed) return new Promise<void>((resolve) => { resolve() })
      return createDocument(doc, signOnUpload || false, location)
        .then(() => { updatedList[index].completed = true })
        .catch((e) => {
          if (!axios.isAxiosError(e)) return;
          if (e.response?.status === 409) updatedList[index].error = LL.errors.contract.createDocument.alreadyCreated()
        }
      )
    })

    await Promise.all(promises)
    setLoading(false)
    return updatedList
  }

  return [createDocuments, loading] as const
}

export default useCreateDocuments


