/* eslint-disable react/jsx-no-constructed-context-values */
import React, { ReactNode } from "react";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import SealsContext from "./SealsContext";
import useFetchSeals from "./useFetchSeals";

interface Props {
  children: ReactNode
}

export default function SealsProvider({ children }: Props) {
   const {
    seals,
    loading,
    fullyLoaded,
    error,
    setSeals,
    getMoreSeals,
    searchSeals
  } = useFetchSeals()

if (error) return <ErrorPage errorMessage={error} />
  return (
    <SealsContext.Provider 
      value={{
        seals,
        loading,
        fullyLoaded,
        setSeals,
        getMoreSeals,
        searchSeals
      }}
    >
      {children}
    </SealsContext.Provider>
  )
}