import auth, {Seal, SealSearch } from "apis/auth";
import { ApplicationState } from "modules";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { errorParser } from "utils/requests/errorParser";


export default function useFetchSeals() {
  const [seals, setSeals] = useState<Seal[]>([])
  const [fullyLoaded, setFullyLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const userSub = useSelector((state: ApplicationState) => state.authentication.user?.sub) 
  const bookmarkRef = useRef<string>()
  const abortControllerRef = useRef(new AbortController())

  const getMoreSeals = useCallback(async (search: SealSearch) => {
    abortControllerRef.current.abort()
    abortControllerRef.current = new AbortController()

    setLoading(true)
    auth.getSeals({ bookmark: bookmarkRef.current, ...search }, abortControllerRef.current.signal)
      .then(({ data }) => {
        if (data.items.length < 10 || data.bookmark === '') setFullyLoaded(true)
        setSeals(seals.concat(data.items))
        bookmarkRef.current = data.bookmark
      })
      .finally(() => setLoading(false))
      .catch(e => errorParser(e, err => setError(err.response?.data)))
  }, [seals, bookmarkRef.current, abortControllerRef])

  const searchSeals = useCallback(async (search: SealSearch) => {
    abortControllerRef.current.abort()
    abortControllerRef.current = new AbortController()
    setFullyLoaded(false)
    setSeals([])

    setLoading(true)
    auth.getSeals({ ...search }, abortControllerRef.current.signal)
    .then(({ data }) => {
      if (data.items.length < 10) setFullyLoaded(true)
      setSeals(data.items)
      bookmarkRef.current = data.bookmark
    })
    .finally(() => setLoading(false))
    .catch(e => errorParser(e, err => setError(err.response?.data)))
  }, [abortControllerRef])

  useEffect(() => {
    if (!userSub) return
    auth.getSeals({ }, abortControllerRef.current.signal)
      .then(({ data: sealList }) => {
        if (sealList.items.length < 10 || sealList.bookmark === '') setFullyLoaded(true)
        setSeals(sealList.items)
        bookmarkRef.current  = sealList.bookmark
      })
    .finally(() => setLoading(false))
    .catch(e => errorParser(e, err => setError(err.response?.data)))
  }, [userSub])

  return {
    seals,
    loading,
    fullyLoaded,
    error,
    setSeals,
    getMoreSeals,
    searchSeals
  }
}
