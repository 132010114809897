import React, { useState } from 'react';
import { Button, Card, CardHeader, message } from '@sis-lab/web-ui-components';
import auth from 'apis/auth';
import { useHistory, useParams } from 'react-router-dom';
import { useI18nContext } from 'i18n/i18n-react';
import { IdenfySelector, MobileSelector, SmartSelector } from './components';
import styles from './VerificationPage.module.scss';

type RouteParams = {
  sub?: string
}


export default function VerificationPage() {
  const [method, setMethod] = useState<'smartID' | 'mobileID' | 'idenfy'>()
  const { sub } = useParams<RouteParams>()
  const { LL } = useI18nContext();
  const history = useHistory();

  const onContinue = async () => {
    if (method === 'idenfy' && sub) {
      const idenfyPage = window.open(`/redirect-idenfy`, '_blank')
      if (idenfyPage) {
        try {
          const { data } = await auth.idenfyInit({ sub });
          idenfyPage.location.href = data.redirectLink
          history.push('/idenfy-email')
        } catch (error) {
          idenfyPage.close()
          message.error('Try again or choose different method')
        }
      } else {
        message.error('Try again or choose different method')
      }
    }
    else {
      history.push(`/identity-verification/${method}/${sub}`)
    }
  }

  return (
    <Card className={styles.card}>
      <CardHeader title={ <h2>{LL.identityVerificationPage.title()}</h2>} />
      <div className={styles.body}>
        <div className={styles.eu}>
          <SmartSelector onClick={() => setMethod('smartID')} selected={method === 'smartID'}/>
          <MobileSelector onClick={() => setMethod('mobileID')} selected={method === 'mobileID'} />
          <p>{LL.identityVerificationPage.smartIdText()}</p>
        </div>
        <div className={styles.idenfy}>
          <IdenfySelector onClick={() => setMethod('idenfy')} selected={method === 'idenfy'} />
          <p>{LL.identityVerificationPage.idenfyText()}</p>
        </div>
        <Button
          disabled={!method}
          className={styles.btn}
          onClick={onContinue}
          children={LL.commonButtons.continue()}
        />
      </div>
    </Card>
  )
}
