import React from "react";
import SealsProvider from "./components/SealsContext/SealsProvider";
import SealsPage from "./SealsPage";

export default function SealsWrapper() {
  return (
    <SealsProvider>
      <SealsPage />
    </SealsProvider>
  )
}
