import axios from "axios";
import { Dispatch } from "react";
import { UserDetailed, UserMetadata } from "apis/auth";
import { AiPreferences } from "apis/aiApi";
import {
  ActionTypes,
  AuthenticateAction,
  InitializeAction,
  LogoutAction,
  ResetInitAction,
  SetMetadataAction,
  SetUserAction,
  SetUserError,
  SetDocumentInvitesCountAction,
  SetPrmInvitesCountAction,
  SetDocumentTypesAction,
  SetAiPreferencesAction
} from "./types";


export const initializeAction = () => (dispatch: Dispatch<InitializeAction>) => {
  dispatch({ type: ActionTypes.INITIALISE })
}

export const resetInitAction = () => (dispatch: Dispatch<ResetInitAction>) => {
  dispatch({ type: ActionTypes.RESET })
}

export const authenticateAction = (accessToken: string) => (dispatch: Dispatch<AuthenticateAction>) => {
  dispatch({ type: ActionTypes.AUTHENTICATE, accessToken })
}

export const setUserInfoAction = (user: UserDetailed) => (dispatch: Dispatch<SetUserAction>) => {
  dispatch({ type: ActionTypes.SET_USER_INFO, user })
}

export const setUserErrorAction = (error: unknown) => (dispatch: Dispatch<SetUserError>) => {
  if (axios.isAxiosError(error)) dispatch({ type: ActionTypes.SET_USER_ERROR, error })
}

export const logoutAction = () => (dispatch: Dispatch<LogoutAction>) => {
  dispatch({ type: ActionTypes.LOG_OUT })
}

export const setMetadataAction =
  (metadata: UserMetadata) => (dispatch: Dispatch<SetMetadataAction>) => {
    dispatch({ type: ActionTypes.SET_METADATA, metadata })
  }

export const setAiPreferencesAction =
  (aiPreferences: AiPreferences) => (dispatch: Dispatch<SetAiPreferencesAction>) => {
    dispatch({ type: ActionTypes.SET_AI_PREFERENCES, aiPreferences })
  }

export const setDocumentInvitesCountAction =
  (documentInvitesCount: number) => (dispatch: Dispatch<SetDocumentInvitesCountAction>) => {
    dispatch({ type: ActionTypes.SET_DOCUMENT_INVITES_COUNT, documentInvitesCount })
  }

export const setDocumentTypesAction =
  (documentTypes: { [key: string]: boolean }) => (dispatch: Dispatch<SetDocumentTypesAction>) => {
    dispatch({ type: ActionTypes.SET_DOCUMENT_TYPES, documentTypes })
  }

export const setPrmInvitesCountAction =
  (prmInvitesCount: number) => (dispatch: Dispatch<SetPrmInvitesCountAction>) => {
    dispatch({ type: ActionTypes.SET_PRM_INVITES_COUNT, prmInvitesCount })
  }
