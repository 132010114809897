import React from "react";
import { Button, CardFooter, Form, Input, Modal, TextArea, useForm } from "@sis-lab/web-ui-components";
import { MessageStatus } from "apis/eDelivery";
import { useI18nContext } from "i18n/i18n-react";

interface Props {
  status?: MessageStatus
  onClose: () => void
}


export default function StatusModal({ status, onClose }: Props) {
  const { LL } = useI18nContext()
  const form = useForm()

  return (
    <Modal
      title={LL.peppolTab.checkStatus.title()}
      footer={null}
      open={!!status}
      onCancel={onClose}
    >
      <Form disabled formHook={form}>
        <Input
          name='status'
          title={LL.commonFields.status.title()}
          value={status?.status}
        />
        <TextArea
          name='id'
          title={LL.peppolTab.checkStatus.messageId.title()}
          value={status?.messageId}
        />
        <TextArea
          name='signalId'
          title={LL.peppolTab.checkStatus.signalId.title()}
          value={status?.signalId}
        />
        <TextArea
          title={LL.peppolTab.checkStatus.errors.title()}
          name='error'
          value={status?.errors?.toString() || LL.peppolTab.checkStatus.errors.noErrors()}
        />
      </Form>
      <CardFooter>
        <Button  
          copyText={status?.nonRepudiationInformation}
          children={LL.peppolTab.checkStatus.copyTokenButton()}
          suffix='content_copy'
          type='secondary'
        />
      </CardFooter>
    </Modal>
  )
}
