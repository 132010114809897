import React from 'react';
import { Avatar, ColumnsType } from '@sis-lab/web-ui-components';
import { TranslationFunctions } from 'i18n/i18n-types';
import { Member } from 'apis/auth';
import styles from './MemberColumns.module.scss';


export const MemberColumns = (userId: string, LL: TranslationFunctions):ColumnsType<Member> => [
  {
    title: 'Icon',
    key: 'icon',
    width:  '50px',
    render: (_text, { sub }) => <Avatar className={styles.avatar} name={sub} />,
  },
  {
    title: 'name',
    key: 'firstName',
    render: (_text, { firstName, lastName, email, sub }) => {
      if (userId === sub) return (
        <div className={styles.title}>
          <h3>You</h3>
          <p>{email}</p>
        </div>
      )
      if (!firstName && !lastName) return (
        <div className={styles.title}>
          <h3>{email}</h3>
          <p>{LL.adminDashboard.unconfirmedIdentity()}</p>
        </div>
      )
      return (
        <div className={styles.title}>
          <h3>{firstName} {lastName}</h3>
          <p>{email}</p>
        </div>
      )
    }
  },
  {
    title: 'Position',
    key: 'position',
    width:  'auto',
    render: (_text, { position }) => (
      <div className={styles.position}>
        <h3>{position}</h3>
      </div>
    ),
  },
  {
    title: 'role',
    key: 'role',
    width:  '120px',
    render: (_text, { role }) => (
      <div className={styles.role}>
        <div className={[styles.tag, styles[role]].join(' ')}>
          {LL.roles[role]()}
        </div>
      </div>

    ),
  },
];


export const NameEmailColumn = (userId: string):ColumnsType<Member> => [
  {
    title: 'Icon',
    key: 'icon',
    width:  '50px',
    render: (_text, { sub }) => <Avatar className={styles.avatar} name={sub} />,
  },
  {
    title: 'name',
    key: 'firstName',
    width: 'auto',
    render: (_text, { firstName, lastName, email, sub }) => {
      if (userId === sub) return (
        <div className={styles.title}>
          <h3>You</h3>
          <p>{email}</p>
        </div>
      )
      if (!firstName && !lastName) return (
        <div className={styles.title}>
          <h3>{email}</h3>
          <p>Unconfirmed</p>
        </div>
      )
      return (
        <div className={styles.title}>
          <h3>{firstName} {lastName}</h3>
          <p>{email}</p>
        </div>
      )
    }
  },
];
