import React, { useState } from 'react';
import { Button, Card, CardHeader, Checkbox, Skeleton, Timeline } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import PaymentEvent from './components/PaymentEvent/PaymentEvent';
import useFetchPrmStatus from './components/useFetchPrmStatus';
import styles from './TimelineCard.module.scss';
import useRetryEdelivery from './components/useRetryEdelivery';

interface Props {
  prmId: string
}


export default function TimelineCard({ prmId }: Props) {
  const [prmStatuses, statusesLoading, fetchPrmStatuses] = useFetchPrmStatus(prmId);
  const [retryEdelivery, retryLoading] = useRetryEdelivery(prmId, fetchPrmStatuses);
  const [showAdvanced, setShowAdvanced] = useState(false)
  const { LL } = useI18nContext()

  // const 

  return (
    <Card className={styles.card} loading={statusesLoading || retryLoading} size='small'>
      <CardHeader
        title={LL.paymentPage.timeLineCard.title()}
        suffix={prmStatuses?.find(status => status.status === 'log') &&
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px'}}>
            <Checkbox
              children={LL.paymentPage.timeLineCard.showAdvanced()}
              value={showAdvanced}
              onChange={() => setShowAdvanced(!showAdvanced)}
              className={styles.checkbox}
            />
            {showAdvanced &&
              <Button
              // state='warning'
              type='secondary'
              children='Retry'
              suffix={retryLoading ? 'loading' : undefined}
              // disabled={loading || accepted}
              onClick={() => retryEdelivery()}
              // children='Retry'
            />}
            <Button
              // state='warning'
              type='secondary'
              // disabled={loading || accepted}
              onClick={() => fetchPrmStatuses()}
              // * different icon here
              // * on retry status 'initiated retry'
              // * on any button click - block card
              // * after successfull click on retry - update timeline
              // * add to jira tasks to make tooltip a universal wrapper, not hardcoded icon
              // * after that wrap buttons with tooltips
              // * add check fgp to logs
              icon={ statusesLoading ? 'loading' : 'autorenew' as 'add'}
            />
        </div>
        }
      />
      {!prmStatuses && <Skeleton />}
      <Timeline className={styles.timeline}>
        {prmStatuses?.map(status => (status.status !== 'log' || showAdvanced) && (
          <PaymentEvent
            key={status.timestamp}
            status={status}
          />
        ))}
      </Timeline>
    </Card>
  )
}
