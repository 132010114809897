import React, { useContext } from 'react';
import { Card, CardHeader, Form, Input, Skeleton, TextArea, useForm } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import { transformDate } from 'utils';
import { getStoredDocumentByUri } from 'apis/corposign-storage';
import CorpoFilePreview from 'components/CorpoFilePreview/CorpoFilePreview';
import DocumentTypeSelector from 'components/DocumentTypeSelector/DocumentTypeSelector';
import { ApplicationState } from 'modules';
import { useSelector } from 'react-redux';
import DocumentContext from '../DocumentContext/DocumentContext';
import styles from './DocumentInfoCard.module.scss';


export default function DocumentInfoCard() {
  const { document, updating, updateDocument } = useContext(DocumentContext)
  const { LL } = useI18nContext()
  const user = useSelector((state: ApplicationState) => state.authentication.user)
  const form = useForm();

  return (
    <Card size='small' className={styles.card} loading={updating}>
      <CardHeader title={LL.documentInfoCard.title()} />
      {!document && <Skeleton count={4} style={{ marginBottom: 10 }} />}
      <Form disabled className={document ? styles.docInfo : styles.hidden} formHook={form} size='medium'>
        <Input
          name='created_at'
          title={LL.documentInfoCard.creationDate.title()}
          value={transformDate(document?.createdAt || '')}
        />
        <Input
          name='creator'
          title={LL.documentInfoCard.title()}
          value={document?.creator.name}
        />
        <Input
          name='format'
          title={LL.commonFields.documentFormat.title()}
          value={document?.format}
        />
        <DocumentTypeSelector<false>
          name='type'
          isDisabled={document?.creator.sub !== user?.sub}
          onChange={(newType) => newType?.value !== document?.type && updateDocument({ type: newType?.value }) }
          isClearable={false}
          className={styles.type}
          title={LL.commonFields.documentType.title()}
          value={document?.type ? {
            value: document.type,
            label: LL.documentTypes[document.type as 'other' || 'other']() || document.type,
          } : undefined}
        />
        {/* <Input
          name='type'
          title={LL.commonFields.documentType.title()}
          value={LL.documentTypes[document?.type as 'workOrder' || 'other']() || document?.type}
        /> */}
        <TextArea
          name='notes'
          title={LL.commonFields.notes.title()}
          value={document?.notes}
          wrapperClassName={!document?.notes ? styles.hidden : ''}
        />
        <CorpoFilePreview 
          fetchFile={async () => getStoredDocumentByUri(document?.uri || '')}
          documentId={document?.uri || ''}
        />
      </Form>
    </Card>
  )
}
