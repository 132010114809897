/* eslint-disable no-param-reassign */
import { message } from '@sis-lab/web-ui-components';
import { getOrganizationDocuments, IDocumentsSearch, MyDocumentsResponse } from 'apis/contract';
import { useEffect, useState } from 'react';

const useFetchDocuments = () => {
  const [response, setResponse] = useState<MyDocumentsResponse>();
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchDocuments = async (searchParams: IDocumentsSearch, reset?: boolean) => {
    setLoading(true);
    searchParams.bookmark = reset ? undefined : response?.bookmark
    searchParams.pageSize = 10;

    try {
      const { data } = await getOrganizationDocuments(searchParams);
      if (!reset && response?.documents) data.documents = response.documents.concat(data.documents);
      setResponse(data);
      setFullyLoaded(!data.bookmark);
      
    } catch (err) {
      message.error(`${err}`, 3);
    } 

    setLoading(false);
  };

  useEffect(() => {
    fetchDocuments({})
  }, [])

  return [response?.documents || [], loading, fullyLoaded, fetchDocuments] as const
}

export default useFetchDocuments;
