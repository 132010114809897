import React, { useCallback, useContext, useState } from 'react';
import { Button, Card, CardHeader, Form, FormSelect, Input, useForm } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'modules';
import { MemberSearchParams } from 'apis/auth';
import InfiniteTable from 'components/InfiniteTable/InfiniteTable';
import SealContext from 'pages/SealPage/components/SealContext/SealContext';
import { MemberColumns } from './components/columns/MemberColumns';
import styles from './MembersTable.module.scss';
import NewMemberModal from './components/NewMemberModal/NewMemberModal';


type TypedFormValues = {
  substring?: string
  roles?: { value: 'admin' | 'member', label: string }[]
}

export default function MembersTable() {
  const [newMemberOpen, setNewMemberOpen] = useState(false)
  const { members, loading, fullyLoaded, getMoreMembers, searchMembers } = useContext(SealContext)
  const { LL } = useI18nContext();
  
  const userSub = useSelector((state: ApplicationState) => state.authentication.user?.sub)
  const form = useForm();


  const handleSearch = () => {
    const { roles, substring } = form.getValues() as TypedFormValues
    const search: MemberSearchParams = {}
    if (roles) search.role = roles.map(role => role.value) 
    // ! if person cleared the search input - refetch
    search.substring = substring
    searchMembers(search)
  }

  const handleLoad = useCallback(async () => {
    const { roles, substring } = form.getValues() as TypedFormValues
    const search: MemberSearchParams = {}
    if (roles) search.role = roles.map(role => role.value) 
    if (substring) search.substring = substring 
    await getMoreMembers(search)
  }, [getMoreMembers])

  if (!userSub) return <div />
  return (
    <Card className={styles.wrapper}>
      <CardHeader title={LL.sealPage.membersCard.title()} />
      <Form formHook={form} className={styles.search}>
        <Input
          onChange={handleSearch}
          name='substring'
          placeholder={LL.search.mainPlaceholder()}
        />
        <FormSelect
          isMulti
          fixedHeight
          name='roles'
          onChange={handleSearch}
          placeholder={LL.roles.role()}
          options={[
            { value: 'member', label: LL.roles.member() },
            { value: 'admin', label: LL.roles.admin() }
          ]}
        />
        <Button icon='add' onClick={() => setNewMemberOpen(true)} />
      </Form>
      <InfiniteTable
        loadMore={handleLoad}
        showHeader={false}
        pagination={false}
        fullyLoaded={fullyLoaded}
        loading={loading}
        columns={MemberColumns(userSub, LL)}
        dataSource={members}
        rowKey={({ sub }) => sub}
        rowClassName={({ enabled, sub }) => (!enabled || sub === userSub) ? styles.disabled : ''}
      />
      <NewMemberModal open={newMemberOpen} onClose={() => setNewMemberOpen(false)} />
    </Card>
  )
}
