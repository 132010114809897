import { objectToQuery } from "utils";
import { AxiosResponse } from "axios";
import { instance } from "../instance";
import {
  GeneratePrmParams,
  GeneratePrmReturn,
  GetGatewayTokenReturn,
  InviteToPayBody,
  InviteToPayReturn,
  PRM,
  PrmListReturn,
  PrmStatusObject,
  PrmStatusQueryParams,
  ResponseToPrmBody,
  PrmListQueryParams
} from "./types";


export const generatePrm = async (data: GeneratePrmParams) => (
  instance.post<GeneratePrmReturn, AxiosResponse<PRM>>('/prm', data)
)

export const getPrmList = async (filter?: PrmListQueryParams, signal?: AbortSignal) => (
  instance.get<PrmListReturn>(`/prm${objectToQuery(filter || {})}`, { signal })
)

export const getPrm = async (prmId: string) => (
  instance.get<PRM>(`/prm/${prmId}`)
)

export const initiatePrm = async (prmId: string) => (
  instance.post<{ gatewayLink: string }>(`/prm/${prmId}/init`)
)

export const inviteToPay = async (prmId: string, recipient: InviteToPayBody) => (
  instance.post<InviteToPayReturn>(`/prm/${prmId}/invite`, { recipient })
)

export const responseToPrm = async (prmId: string, data: ResponseToPrmBody) => (
  instance.post<PrmStatusObject>(`/prm/${prmId}/response`, data)
)

export const getPrmLatestStatus = async (prmId: string, query?: PrmStatusQueryParams) => (
  instance.get<PrmStatusObject>(`/prm/${prmId}/status${objectToQuery({ history: false, ...query })}`)
)

export const getPrmStatuses = async (prmId: string, query?: PrmStatusQueryParams) => (
  instance.get<PrmStatusObject[]>(`/prm/${prmId}/status${objectToQuery({ history: true, ...query })}`)
)

export const getPrmDocument = async (prmId: string) => (
  instance.get<ArrayBuffer>(`/prm/${prmId}/document`, { responseType: 'arraybuffer' })
)

export const getPrmQrCode = async (prmId: string) => (
  instance.get<ArrayBuffer>(`/prm/${prmId}/qr`, { responseType: 'arraybuffer' })
)

export const getGatewayToken = async (prmId: string) => (
  instance.get<GetGatewayTokenReturn>(`/prm/${prmId}/token?clientKey=KEY_1`)
)

export const getInvitedPrmsCount = () => (
  instance.get<{ count: number }>(`/prm/invites/count`)
)

export const retryEdelivery = (prmId: string) => (
  instance.post(`/prm/${prmId}/edelivery`)
)
