import { addDocument } from 'apis/corposign-storage'
import {
  checkFingerprints, createDocument, getMyDocuments, getMyInvitesCount,
  getDocumentTypes, patchDocument, eIdPrepareDocument, eIdGetDocument
} from './documents'
import { getIdentity } from './identities'

export * from './identities'
export * from './instance'
export * from './documents'
export * from './signatures'
export * from './organizations'
export * from './types'


const contract = {
  patchDocument,
  createDocument,
  addDocument,
  checkFingerprints,
  getMyDocuments,
  getMyInvitesCount,
  getIdentity,
  getDocumentTypes,
  eIdPrepareDocument,
  eIdGetDocument
}

export default contract
