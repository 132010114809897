import React from 'react';
import { Button, Card, CardFooter, CardHeader, message } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import { useQuery } from 'utils';
import { useHistory } from 'react-router-dom';
import verifiedImg from '../../../images/account-verified.svg';
import styles from '../IdentityConfirmedPage.module.scss'

export default function MemberFlow() {
  const { LL } = useI18nContext() 
  const query = useQuery()
  const history = useHistory()

  // ! resetPasswordUrl should be an internal url without hostname
  const redirectToResetPasswordPage = () => {
    const resetPasswordUrl = query.get('resetPasswordUrl') || '';
    if (resetPasswordUrl) {
      history.push(resetPasswordUrl)
    } else {
      message.error('Some error occured, use "Forgot password" page to set a password')
    }
  }

  return (
    <Card className={styles.card}>
      <CardHeader
        title={<h2>{LL.confirmedPage.memberFlow.title()}</h2>}
      />
      <div className={styles.body}>
        <img src={verifiedImg} alt='success'/>
        <p>{LL.confirmedPage.memberFlow.explanation()}</p>
        <span>{LL.confirmedPage.memberFlow.prompt()}</span>
      </div>
      <CardFooter className={styles.footer}>
        <Button onClick={redirectToResetPasswordPage} children={LL.confirmedPage.memberFlow.buttonText()} />
      </CardFooter>
    </Card>
  )
}
