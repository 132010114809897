import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authentication from 'modules/authentication/reducer'
import certificates from 'modules/certificates/reducer';
import documents from 'modules/documents/reducer';
import signatures from 'modules/signatures/reducer';
import history from 'history';

const createRootReducer = (historyValue: history.History) => combineReducers({
  authentication,
  documents,
  certificates,
  signatures,
  router: connectRouter(historyValue),
});

export default createRootReducer;
