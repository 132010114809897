import React, { useState } from 'react';
import { Button, Input, Skeleton, useBreakpoints, useForm } from '@sis-lab/web-ui-components';
import { ApplicationState } from "modules";
import { useSelector } from "react-redux";
import BindingCard from './components/BindingCard/BindingCard';
import styles from './CredentialsTab.module.scss'
import IssuanceCard from './components/IssuanceCard/IssuanceCard';

export default function CredentialsTab() {
  const breakpoints = useBreakpoints() || {};
  const user = useSelector((state: ApplicationState) => state.authentication.user);
  // console.log(breakpoints);
  // console.log(user);
  return (
    <div className={styles.credentialsTab}>
      {
        user?.did
          ? <IssuanceCard/>
          : <BindingCard/>
      }
    </div>
  )
}