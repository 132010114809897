import React from 'react';
import { Button, Header } from '@sis-lab/web-ui-components';
import { useHistory, useLocation } from 'react-router-dom';
import { useI18nContext } from 'i18n/i18n-react';
import corposignLogo from 'images/corposign-logo.svg';
import styles from './AuthHeader.module.scss';


export default function AuthHeader() {
  const { LL } = useI18nContext()
  const history = useHistory();
  const location = useLocation();

  return (
    <Header
      className={styles.header}
      onTitleClick={() => history.push('/')}
      logo={corposignLogo}
      title={<h1 className={styles.title}>CorpoSign</h1>}
    >
      <Button
        className={location.pathname !== '/' ? styles.hidden : ''}
        suffix='login'
        children={LL.commonButtons.login()}
        onClick={() => history.push('/login')}
      />
    </Header>
  );
}
