import { setAuthAccessToken } from 'apis/auth';
import xmlApi from 'apis/xml';
import issuerApi from 'apis/issuerApi';
import aiApi from 'apis/aiApi';
import { setContractAccessToken } from 'apis/contract';
import { setStorageAccessToken } from 'apis/corposign-storage';
import { setCryptoAccessToken } from 'apis/crypto/instance';
import { setGatewayAccessToken } from 'apis/gateway/instance';
import { setPrmAccessToken } from 'apis/prmApi/instance';
import { setEdeliveryAccessToken } from 'apis/eDelivery/instance';
import { ActionTypes, AuthenticationAction, AuthenticationState } from './types';

const initialState: AuthenticationState = { authenticated: false, initialized: false };

const setAccessToken = (token: string) => {
  setAuthAccessToken(token)
  setContractAccessToken(token)
  setStorageAccessToken(token)
  setCryptoAccessToken(token)
  setGatewayAccessToken(token)
  setPrmAccessToken(token)
  setEdeliveryAccessToken(token)
  xmlApi.setAccessToken(token)
  aiApi.setAuthAccessToken(token)
  issuerApi.setAuthAccessToken(token)
}

const authentication = (state = initialState, action: AuthenticationAction): AuthenticationState => {
  switch (action.type) {
    case ActionTypes.INITIALISE:
      return {
        initialized: true,
        authenticated: state.authenticated,
        user: state.user,
        documentTypes: state.documentTypes,
        prmInvitesCount: state.prmInvitesCount,
        documentInvitesCount: state.documentInvitesCount,
        aiPreferences: state.aiPreferences,
        metadata: state.metadata
      };

    case ActionTypes.RESET:
      return {
        initialized: false,
        authenticated: false,
        user: undefined,
        documentTypes: undefined,
        documentInvitesCount: undefined,
        prmInvitesCount: undefined,
        aiPreferences: undefined,
        metadata: state.metadata
      };

    case ActionTypes.AUTHENTICATE:
      setAccessToken(action.accessToken)
      return {
        initialized: true,
        authenticated: true,
        user: state.user,
        documentTypes: state.documentTypes,
        prmInvitesCount: state.prmInvitesCount,
        documentInvitesCount: state.documentInvitesCount,
        aiPreferences: state.aiPreferences,
        metadata: state.metadata
      };

    case ActionTypes.SET_USER_INFO:
      return {
        initialized: true,
        authenticated: true,
        user: action.user,
        documentTypes: state.documentTypes,
        prmInvitesCount: state.prmInvitesCount,
        documentInvitesCount: state.documentInvitesCount,
        aiPreferences: state.aiPreferences,
        metadata: state.metadata
      }

    case ActionTypes.LOG_OUT:
      setAccessToken('')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('companyName')
      return {
        initialized: true,
        authenticated: false,
        user: undefined,
        documentTypes: undefined,
        documentInvitesCount: 0,
        prmInvitesCount: 0,
        aiPreferences: undefined,
        metadata: undefined
      }

    case ActionTypes.SET_USER_ERROR:
      return {
        authenticated: true,
        error: action.error,
        initialized: true,
        user: undefined,
        documentTypes: state.documentTypes,
        prmInvitesCount: state.prmInvitesCount,
        documentInvitesCount: state.documentInvitesCount,
        aiPreferences: state.aiPreferences,
        metadata: state.metadata
      }

    case ActionTypes.SET_METADATA:
      return {
        initialized: state.initialized,
        authenticated: state.authenticated,
        error: state.error,
        user: state.user,
        documentTypes: state.documentTypes,
        prmInvitesCount: state.prmInvitesCount,
        documentInvitesCount: state.documentInvitesCount,
        aiPreferences: state.aiPreferences,
        metadata: action.metadata
      }

    case ActionTypes.SET_DOCUMENT_INVITES_COUNT:
      return {
        initialized: state.initialized,
        authenticated: state.authenticated,
        error: state.error,
        user: state.user,
        metadata: state.metadata,
        documentTypes: state.documentTypes,
        prmInvitesCount: state.prmInvitesCount,
        aiPreferences: state.aiPreferences,
        documentInvitesCount: action.documentInvitesCount
      }

    case ActionTypes.SET_PRM_INVITES_COUNT:
      return {
        initialized: state.initialized,
        authenticated: state.authenticated,
        error: state.error,
        user: state.user,
        metadata: state.metadata,
        documentTypes: state.documentTypes,
        documentInvitesCount: state.documentInvitesCount,
        aiPreferences: state.aiPreferences,
        prmInvitesCount: action.prmInvitesCount
      }

    case ActionTypes.SET_DOCUMENT_TYPES:
      return {
        initialized: state.initialized,
        authenticated: state.authenticated,
        error: state.error,
        user: state.user,
        metadata: state.metadata,
        documentInvitesCount: state.documentInvitesCount,
        prmInvitesCount: state.prmInvitesCount,
        aiPreferences: state.aiPreferences,
        documentTypes: action.documentTypes
      }

    case ActionTypes.SET_AI_PREFERENCES:
      return {
        initialized: state.initialized,
        authenticated: state.authenticated,
        error: state.error,
        user: state.user,
        metadata: state.metadata,
        documentInvitesCount: state.documentInvitesCount,
        prmInvitesCount: state.prmInvitesCount,
        documentTypes: state.documentTypes,
        aiPreferences: action.aiPreferences,
      }

    default: return state;
  }
};

export default authentication
