import React from "react";
import { Button, Card, CardFooter, CardHeader, Divider } from "@sis-lab/web-ui-components";
import { useHistory } from "react-router-dom";
import { useI18nContext } from "i18n/i18n-react";
import { transformDate } from "utils";
import HistoryRow from "./components/HistoryRow/HistoryRow";
import NoHistory from "./components/NoHistory/NoHistory";
import SkeletonList from "./components/SkeletonList/SkeletonList";
import useFetchHistory from "./components/useFetchHistory";
import styles from './HistoryCard.module.scss'


export default function HistoryCard() {
  const [documents, loading, error] = useFetchHistory()
  const { LL } = useI18nContext()
  const history = useHistory()
  const isEmpty = documents.length === 0 && !loading

  return (
    <Card  className={!error ? styles.card : styles.hidden}> 
      <CardHeader title={LL.uploadPage.historyCardTitle()} />
      <div className={styles.body}>
        {isEmpty && <NoHistory />}
        {documents.map(doc => (
          <HistoryRow
            key={doc.fingerprint}
            onClick={() => history.push(`/documents/${doc.fingerprint}`)}
            title={doc.title}
            date={transformDate(doc.createdAt).substring(0, 10)}
          />)
        )}
        {loading && <SkeletonList />}
      </div>
      <CardFooter>
        <Button
          disabled={loading}
          className={!isEmpty ? styles.button : styles.hidden}
          onClick={() => history.push('/documents')}
          type='secondary'
          children={LL.uploadPage.showMoreButton.text()}
        />
      </CardFooter>
    </Card>
  )
}
