import prmApi, { PrmStatusObject } from "apis/prmApi"
import { useContext, useEffect, useState } from "react"
import PrmContext from "../../PrmContext/PrmContext"


const useFetchPrmStatus = (prmId: string) => {
  const [prmStatuses, setPrmStatuses] = useState<PrmStatusObject[]>()
  const [loading, setLoading] = useState(true)
  const { prm } = useContext(PrmContext)

  const fetchStatuses = () => {
    // use signal to cancel request?
    setLoading(true)
    prmApi.getPrmStatuses(prmId)
      .then(({ data }) => setPrmStatuses(data))
      .finally(() => setLoading(false))
      .catch(() => null)
  }

  useEffect(fetchStatuses, [prmId, prm])

  return [prmStatuses, loading, fetchStatuses] as const
}

export default useFetchPrmStatus
