import React, { useState } from 'react';
import sha256 from 'crypto-js/sha256';
import { useHistory } from 'react-router-dom';
import { arrayBufferToWordArray } from 'utils';

import {
  Input,
  Card,
  Button,
  useForm,
  Upload,
  Icon,
  useBreakpoints,
  Divider,
} from '@sis-lab/web-ui-components';

import { checkFingerprints } from 'apis/contract';
import PageHeader from 'components/PageHeader/PageHeader';

import { useI18nContext } from 'i18n/i18n-react';
import styles from './ValidatorPage.module.scss';

function ValidatorPage() {
  const [loading, setLoading] = useState(false);
  const { mobile } = useBreakpoints() || {};
  const { LL } = useI18nContext()
  const history = useHistory();
  const form = useForm();

  const startDocumentValidation = async (hash: string) => {
    setLoading(true);
    form.setValue('hash', hash);
    form.clearErrors();

    try {
      const { data: checkResults } = await checkFingerprints([hash]);
      if (checkResults[hash].exists) {
        history.push(`/validate/${hash}`);
      } else {
        form.setError('hash', { message: LL.errors.contract.getDocumentErrors.documentNotFound() })
      }
    } catch (err) {
      form.setError('hash', { message: LL.errors.contract.getDocumentErrors.documentNotFound() })
    } finally {
      setLoading(false)
    }
  };

  const onUpload = async (file: any) => {
    const hash: string = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        resolve(sha256(arrayBufferToWordArray(reader.result)).toString());
      };
    });
    await startDocumentValidation(hash);
    return false;
  }
  
  return (
    <div className={styles.page}>
      <PageHeader title={LL.validatorPage.title()} />
      <div className={styles.body}>
        <Card className={styles.card}>
          <div className={styles.description}>
            <Icon className={styles.descrIcon} name='info_outline' />
            <p>{LL.validatorPage.description()}</p>
          </div>
          <Upload
            className={styles.upload}
            disabled={loading}
            icon='find'
            name='file'
            beforeUpload={onUpload}
            size='small'
            helpText={LL.commonFields.upload.helpText()}
            text={mobile ? LL.commonFields.upload.prompt_mobile() : LL.commonFields.upload.prompt()} 
            title={LL.commonFields.upload.title()}
          />
          <Divider text={LL.common.dividerOR()} size='small' className={styles.divider} />
          <Input
            disabled={loading}
            formHook={form}
            name='hash'
            placeholder={LL.validatorPage.searchInput.placeholder()}
            title={LL.validatorPage.searchInput.title()}
            tooltip={LL.validatorPage.searchInput.tooltip()}
          />
          <Button
            children={LL.validatorPage.validateButton()}
            className={styles.validateBtn}
            disabled={loading || !form.watch('hash')}
            htmlType='submit'
            onClick={form.handleSubmit((data) => startDocumentValidation(data.hash))}
            type='primary'
          />
        </Card>
      </div>
    </div>
  );
}

export default ValidatorPage;
