import { ActionTypes, DocumentsAction, DocumentsState } from './types';

const initialState: DocumentsState = {
  loading: false,
  documents: [],
  bookmark: '',
  publicDocuments: [],
  referencedDocuments: [],
  publicDocumentsTable: {},
};

const documents = (state = initialState, action: DocumentsAction): DocumentsState => {
  switch (action.type) {
    // ====> DOCUMENTS <====
    case ActionTypes.REQUEST_QUERY_DOCUMENTS:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.SUCCESS_QUERY_DOCUMENTS:
      return {
        ...state,
        loading: false,
        documents: action.documents,
        error: undefined,
      };
    case ActionTypes.ERROR_QUERY_DOCUMENTS:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

      // ====> DOCUMENT <====
    case ActionTypes.REQUEST_GET_DOCUMENTS:
      return {
        ...state,
        documents: [],
        loading: true,
      };
    case ActionTypes.SUCCESS_GET_DOCUMENTS:
      return {
        ...state,
        loading: false,
        documents: action.documents,
        bookmark: action.bookmark,
        pageSize: action.pageSize,
        count: action.count,
        error: undefined,
      };
    case ActionTypes.ERROR_GET_DOCUMENTS:
      return {
        ...state,
        loading: false,
        documents: [],
        error: action.error,
      };

      // ====> DOCUMENT <====
    case ActionTypes.REQUEST_GET_REFERENCED_DOCUMENTS:
      return {
        ...state,
        referencedDocuments: [],
        loading: true,
      };
    case ActionTypes.SUCCESS_GET_REFERENCED_DOCUMENTS:
      return {
        ...state,
        loading: false,
        referencedDocuments: action.referencedDocuments,
        error: undefined,
      };
    case ActionTypes.ERROR_GET_REFERENCED_DOCUMENTS:
      return {
        ...state,
        loading: false,
        referencedDocuments: [],
        error: action.error,
      };
    case ActionTypes.CLEAR_REFERENCED_DOCUMENTS:
      return {
        ...state,
        loading: false,
        referencedDocuments: [],
        error: action.error,
      };

      // ====> PUBLIC DOCUMENTS <====
    case ActionTypes.REQUEST_GET_PUBLIC_DOCUMENTS:
      return {
        ...state,
        publicDocuments: [],
        loading: true,
      };
    case ActionTypes.SUCCESS_GET_PUBLIC_DOCUMENTS:
      return {
        ...state,
        loading: false,
        publicDocuments: action.publicDocuments,
        error: undefined,
      };
    case ActionTypes.ERROR_GET_PUBLIC_DOCUMENTS:
      return {
        ...state,
        loading: false,
        publicDocuments: [],
        error: action.error,
      };

      // ====> PUBLIC DOCUMENTS TABLE <====
    case ActionTypes.REQUEST_GET_PUBLIC_DOCUMENTS_TABLE:
      return {
        ...state,
        publicDocumentsTable: {},
        loading: true,
      };
    case ActionTypes.SUCCESS_GET_PUBLIC_DOCUMENTS_TABLE:
      return {
        ...state,
        loading: false,
        publicDocumentsTable: action.publicDocumentsTable,
        error: undefined,
      };
    case ActionTypes.ERROR_GET_PUBLIC_DOCUMENTS_TABLE:
      return {
        ...state,
        loading: false,
        publicDocumentsTable: {},
        error: action.error,
      };

      // ====> DOCUMENT <====
    case ActionTypes.CLEAR_DOCUMENT:
      return {
        ...state,
        document: undefined,
        loading: false,
      };
    case ActionTypes.REQUEST_GET_DOCUMENT:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.SUCCESS_GET_DOCUMENT:
      return {
        ...state,
        loading: false,
        document: action.document,
        error: undefined,
      };
    case ActionTypes.ERROR_GET_DOCUMENT:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

      // ====> vaLIDATION RESULTS <====
    case ActionTypes.CLEAR_VALIDATION_RESULTS:
      return {
        ...state,
        validationResults: undefined,
      };
    case ActionTypes.SET_VALIDATION_RESULTS:
      return {
        ...state,
        validationResults: action.validationResults,
      };

      // ====> DOCUMENT <====
    default:
      return state;
  }
};

export default documents;
