import axios, { AxiosError } from 'axios';
import config from '../../config';

export const instance = axios.create({
  baseURL: config.services.issuerApi.url,
});

instance.interceptors.response.use(value => value, (error: AxiosError) => {
  // LOGOUT IF FORBIDDEN
  if (!error.response?.status) return
  if ([401, undefined].includes(error.response?.status)) {
    localStorage.removeItem('refreshToken');
    window.location.reload();
  }

  throw error
})

export const setAuthAccessToken = (token?: string) => {
  if (token) {
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete instance.defaults.headers.common.Authorization;
  }
};

export const setAuthAcceptLanguageHeader = (language: string) => {
  instance.defaults.headers.common['Accept-Language'] = language;
};
