import { UserDetailed } from "apis/auth";

export type Protections = 'allowedToSign' | 'allowedToUpload' | 'allowedToView' | 'admin'

export default function canUserDo(user: UserDetailed, protection: Protections) {
  return (
    (user.role === 'owner' || user.role === 'admin') ||
    (protection === 'allowedToView' && user.permissions?.allowedToView) ||
    (protection === 'allowedToSign' && user.permissions?.allowedToSign) ||
    (protection === 'allowedToUpload' && user.permissions?.allowedToUpload)
  )
}
