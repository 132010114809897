import React from 'react';
import { useParams } from 'react-router-dom';

import PaymentInfoCard from './components/PaymentInfoCard/PaymentInfoCard';
import TimelineCard from './components/TimelineCard/TimelineCard';
import PayerCard from './components/PayerCard/PayerCard';
import PrmProvider from './components/PrmContext/PrmProvider';
import PaymentPageHeader from './components/PaymentPageHeader/PaymentPageHeader';
import styles from './PaymentPage.module.scss';

interface RouterParams {
  prmId: string;
}


export default function PaymentPage() {
  const { prmId } = useParams<RouterParams>();

  return (
    <PrmProvider prmId={prmId}>
      <div className={styles.page}>
        <PaymentPageHeader />
        <div className={styles.body}>
          <div className={styles.column}>
            <PaymentInfoCard />
          </div>
          <div className={styles.column}>
            <PayerCard />
            <TimelineCard prmId={prmId} />
          </div>
        </div>
      </div>
    </PrmProvider>
  );
}
