import { Card, CardHeader, Select, Skeleton } from "@sis-lab/web-ui-components";
import React, { useState } from "react";
import { PaymentProvider } from "apis/prmApi/types";
import { useI18nContext } from "i18n/i18n-react";
import KevinForm from "./components/KevinForm";
import MontonioForm from "./components/MontonioForm";
import useProvidersConfigs from "./components/useProvidersConfigs";

const providers: PaymentProvider[] = ['KEVIN', 'MONTONIO'];

export default function ProvidersCard () {
  const [selectedProvider, setSelectedProvider] = useState<PaymentProvider>('KEVIN');
  const { LL } = useI18nContext();
  const [montonioConfig, kevinConfig, updateMontonioConfig, updateKevinConfig, loading] = useProvidersConfigs();

  const showSkeleton = !kevinConfig && loading

  return (
    <Card>
      <CardHeader title={LL.paymentsTab.paymentsTitle()} />
      { showSkeleton && <Skeleton count={6} style={{ marginBottom: '20px' }} /> }
      <Select
        name='provider'
        title={LL.paymentsTab.providerSelect.title()}
        isMulti={false}
        isClearable={false}
        defaultValue={{ value: 'KEVIN', label: 'KEVIN' }}
        tooltip='To do'
        style={{ display: showSkeleton ? 'none' : undefined }}
        options={providers.map(provider => ({ value: provider, label: provider }))}
        onChange={option => setSelectedProvider(option?.value as PaymentProvider)}
      />
      <KevinForm
        loading={loading}
        updateKevinConfig={updateKevinConfig}
        kevinConfig={kevinConfig}
        hidden={selectedProvider !== 'KEVIN' || showSkeleton}
      />
      <MontonioForm
        loading={loading}
        updateMontonioConfig={updateMontonioConfig}
        montonioConfig={montonioConfig}
        hidden={selectedProvider !== 'MONTONIO' || showSkeleton}
      />
    </Card>
  )
}
