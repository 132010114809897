import React, { ReactChild } from "react"
import ErrorPage from "pages/ErrorPage/ErrorPage";
import DocumentContext from "./DocumentContext"
import useFetchDocument from "./useFetchDocument";
import useUpdateDocument from "./useUpdateDocument";

interface Props {
  children: ReactChild
  fingerprint: string
}

export default function DocumentProvider({ children, fingerprint }: Props) {
  const [document, error, setDocument, fetching, fetchDocument] = useFetchDocument(fingerprint);
  const [updating, updateDocument] = useUpdateDocument(fingerprint, setDocument);

  if (error) return <ErrorPage errorMessage={error} />
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <DocumentContext.Provider value={{ document, updating, fetching, setDocument, updateDocument, fetchDocument }}>
      {children}
    </DocumentContext.Provider>
  )
}
