import { ICertificate } from 'types';

export enum ActionTypes {
  REQUEST_GET_CERTIFICATE = '@@certificates/REQUEST_GET_CERTIFICATE',
  SUCCESS_GET_CERTIFICATE = '@@certificates/SUCCESS_GET_CERTIFICATE',
  ERROR_GET_CERTIFICATE = '@@certificates/ERROR_GET_CERTIFICATE',
}

export interface CertificateAction {
  type: ActionTypes,
  loading?: boolean,
  certificate?: ICertificate,
  error?: Error
}

export interface CertificatesState {
  readonly loading: boolean;
  readonly certificate?: ICertificate,
  readonly error?: Error,
}
