import { Button, Card, CardHeader, Divider, Icon } from "@sis-lab/web-ui-components";
import React, { useEffect, useState } from "react";
import { useI18nContext } from "i18n/i18n-react";
import DocumentUpload from "./components/DocumentUpload/DocumentUpload";
import { DocumentInfo } from "../types";
import ListRow from "./components/ListRow/ListRow";
import styles from './DocumentUploadCard.module.scss'
import useCreateDocuments from "./components/useCreateDocuments";

interface Props {
  selectedDoc?: DocumentInfo
  onSelect: (doc?: DocumentInfo) => void
}


export default function DocumentUploadCard({ selectedDoc, onSelect }: Props) {
  const [documentList, setDocumentList] = useState<DocumentInfo[]>([])
  const [createDocuments, loading] = useCreateDocuments(documentList)
  const { LL } = useI18nContext()

  useEffect(() => {
    if (!selectedDoc) return
    const selectIndex = documentList.findIndex(doc => doc.fingerprint === selectedDoc.fingerprint)
    setDocumentList(documentList.map((doc, index) => selectIndex === index ? selectedDoc : doc))
  }, [selectedDoc])

  const afterUpload = (list: DocumentInfo[]) => {
    const newlist = documentList.concat(list)
    setDocumentList(newlist)
    onSelect(newlist.at(-1))
  }

  const onDelete = (target: DocumentInfo) => {
    const newList = documentList.filter(doc => doc.fingerprint !== target.fingerprint)
    if (target.fingerprint === selectedDoc?.fingerprint) onSelect(newList.at(-1))
    setDocumentList(newList)
  }

  const onReset = () => {
    setDocumentList([])
    onSelect(undefined)
  }

  const onCreate = async () => {
    const updatedList  = await createDocuments()
    setDocumentList(updatedList)
    onSelect(updatedList.find((doc) => doc.fingerprint === selectedDoc?.fingerprint))
  }

  return (
    <Card className={styles.card}>
      <CardHeader prefix={<Icon name='add' />} title={ LL.uploadPage.uploadCardTitle() } />
      <DocumentUpload currentList={documentList} afterUpload={afterUpload} />
      <div className={styles[documentList.length > 0 ? 'documentList' : 'hidden']}>
        {documentList.map((document) => (
          <ListRow
            key={document.fingerprint}
            document={document}
            loading={loading}
            selected={document.fingerprint === selectedDoc?.fingerprint}
            onSelect={onSelect}
            onDelete={onDelete}
          />
        ))}
      </div>
      <Divider size='tiny' className={documentList.length === 0 ? styles.hidden : undefined}  />
      <div className={styles[documentList.length > 0 ? 'footer' : 'hidden']}>
        <Button
          children={LL.uploadPage.resetButton.text()}
          type='secondary'
          disabled={loading}
          onClick={onReset}
        />
        <Button
          children={LL.uploadPage.uploadDocumentsButton.text()}
          mobileIcon='upload'
          disabled={loading}
          onClick={onCreate}
        />
      </div>
    </Card>
  )
}
