import {
  getKevinConfig,
  getMontonioConfig,
  getRtpConfig,
  updateKevinConfig,
  updateMontonioConfig,
  updateRtpConfig
} from "./account"
import {
  getPrm,
  getPrmList,
  generatePrm,
  getPrmDocument,
  getPrmQrCode,
  getPrmLatestStatus,
  getPrmStatuses,
  initiatePrm,
  responseToPrm,
  inviteToPay,
  getGatewayToken,
  getInvitedPrmsCount,
  retryEdelivery
} from "./prm"

export * from './types'

const prmApi = {
  generatePrm,
  getGatewayToken,
  getKevinConfig,
  getMontonioConfig,
  getPrm,
  getPrmDocument,
  getPrmLatestStatus,
  getPrmList,
  getPrmQrCode,
  getPrmStatuses,
  getRtpConfig,
  initiatePrm,
  inviteToPay,
  responseToPrm,
  updateKevinConfig,
  updateMontonioConfig,
  updateRtpConfig,
  getInvitedPrmsCount,
  retryEdelivery
}

export default prmApi
