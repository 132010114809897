import React from 'react'
import { Icon } from '@sis-lab/web-ui-components'
import { useI18nContext } from 'i18n/i18n-react';
import businessImg from '../../../../images/business.svg'
import accountImg from '../../../../images/account.svg'
import styles from './AccountTypeBox.module.scss';

interface Props {
  type: 'personal' | 'business'
  onClick: () => void
  selected?: boolean
}

export default function AccountTypeBox({ selected, onClick, type }: Props) {
  const { LL } = useI18nContext()

  return (
    <button type='button' className={[styles.typeBox, selected && styles.selected].join(' ')} onClick={onClick} >
      {selected && <Icon className={styles.check} name={'check' as 'arrow_down'} />}
      <img src={type === 'personal' ? accountImg : businessImg}  alt={type} />
      <p>{type === 'personal' ? LL.signupPage.personalAccount() : LL.signupPage.businessAccount()}</p>
    </button>
  )
}

