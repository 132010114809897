import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ApplicationState } from 'modules';
import { useSelector } from 'react-redux';
import DocumentInfoCard from './components/DocumentInfoCard/DocumentInfoCard';
import DocumentValidityCard from './components/DocumentValidityCard/DocumentValidityCard';
import ReferencedDocsCard from './components/ReferencedDocsCard/ReferencedDocsCard';
import SignaturesCard from './components/SignaturesCard/SignaturesCard';
import DownloadCertificateCard from './components/DownloadCertificateCard/DownloadCertificateCard';
import DocumentProvider from './components/DocumentContext/DocumentProvider';
import DocumentHeader from './components/DocumentHeader/DocumentHeader';
import styles from './DocumentPage.module.scss';
import EidEasySignCard from './components/EidEasySignCard/EidEasySignCard';

interface RouterParams {
  fingerprint: string;
}


export default function DocumentPage() {
  const { fingerprint } = useParams<RouterParams>();
  const location = useLocation();
  const allowEidEasySignatures = useSelector((state: ApplicationState) =>
    state.authentication.metadata?.preferences.allowEidEasySignatures
  )
  
  return (
    <DocumentProvider fingerprint={fingerprint}>
      <div className={styles.page}>
        <DocumentHeader />
        <div className={styles.body}>
          <div className={styles.column}>
            <DocumentInfoCard/>
            <ReferencedDocsCard />
            <DocumentValidityCard
              isValidator={location.pathname.includes('validate')}
              isHidden={!window.location.pathname.includes('validate')}
              fingerprint={fingerprint} 
            />
          </div>
          <div className={styles.column}>
            <SignaturesCard
              isValidator={location.pathname.includes('validate')}
              fingerprint={fingerprint}
            />
            {allowEidEasySignatures && <EidEasySignCard
              fingerprint={fingerprint}
            />
            }
            {window.location.pathname.includes('validate') ?
              <DownloadCertificateCard fingerprint={fingerprint} /> :
              <DocumentValidityCard fingerprint={fingerprint} />
            }
          </div>
        </div> 
      </div>
    </DocumentProvider>
  );
}
