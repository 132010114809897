import React, { useState } from 'react';
import { Button, Card, CardFooter, CardHeader, Icon, message } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'utils';
import { startPasswordReset } from 'apis/auth';
import mailboxImg from '../../../../images/mailbox.svg';
import styles from './ForgotPasswordSuccess.module.scss';


export default function ForgotPasswordSuccess() {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const history = useHistory();
  const { LL } = useI18nContext()
  const username = query.get('username') || '';  

  const onResend = async () => {
    try {
      setLoading(true)
      await startPasswordReset(username)
      message.success('Resent')
    } catch (err) {
      message.error(err as string);
    } finally {
      setLoading(false)
    }
  }
  
  return (
    <Card className={styles.card}>
      <CardHeader
        title={LL.forgotPassword.titleSuccess()}
        prefix={<Icon onClick={() => history.push('/forgot-password')} name='arrow_left' />}
      />
      <div className={styles.cardBody}>
        <img src={mailboxImg} alt='Check your email'/>
        <p>{LL.forgotPassword.successText()}</p>
        <small>
          {LL.forgotPassword.resendText()}
        </small>
      </div>
      <CardFooter
        className={styles.footer}
      >
        <Button 
          disabled={loading}
          suffix={loading ? 'loading' : 'mail' as 'loading'}
          onClick={
            () => username
              ? onResend()
              : message.error('Username can not be extracted, start the reset password process again.')
          }
          children={LL.forgotPassword.resendButton()}
        />
      </CardFooter>
    </Card>
  )
}
