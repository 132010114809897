import CryptoJS from 'crypto-js';

export const arrayBufferToWordArray = (ab: any) => {
  const i8a = new Uint8Array(ab);
  const a = [];
  for (let i = 0; i < i8a.length; i += 4) {
    a.push(
      // eslint-disable-next-line no-bitwise
      (i8a[i] << 24) | (i8a[i + 1] << 16) | (i8a[i + 2] << 8) | i8a[i + 3],
    );
  }
  return CryptoJS.lib.WordArray.create(a, i8a.length);
};

export const downloadBlobFile = (fileData: Blob, fileName: string) => {
  const url = window.URL.createObjectURL(fileData);
  const a = document.createElement('a');
  a.href = url;
  a.setAttribute('download', fileName);
  a.click();
};


export const file2Base64 = (file: File): Promise<string> => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result?.toString() || '');
  reader.onerror = (error) => reject(error);
});

export const file2Blob = (file: File): Blob => new Blob([file], { type: file.type })
