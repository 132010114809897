/* eslint-disable no-alert */
import React from 'react';
import { Button, Card, CardFooter, CardHeader, message } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import { useQuery } from 'utils';
import mailboxImg from 'images/mailbox.svg';
import { resendSuccessCreateUser } from 'apis/auth';
import styles from './VerificationEmail.module.scss';


export default function VerificationEmail() {
  const query = useQuery();
  const { LL } = useI18nContext();
  const email = query.get('email') || '';

  const onResend = async () => {
    try {
      message.loading('Resending', 1)
      await resendSuccessCreateUser(email)
      message.success('Resent', 1)
    } catch (err) {
      message.error(err as string);
    }
  }

  return (
    <Card className={styles.card}>
      <CardHeader
        title={<h2>{LL.verifyEmailPage.title()}</h2>}
      />
      <div className={styles.body}>
        <img src={mailboxImg} alt='Verify your email'/>
        <p>{LL.verifyEmailPage.text()}</p>
        <small>
          {LL.verifyEmailPage.resendText()}
        </small>
      </div>
      <CardFooter
        className={styles.footer}
      >
        <Button 
          // disabled={isLoading}
          onClick={() => email ? onResend() : message.error('Email not found')}
          children={LL.verifyEmailPage.resendButton()}
        />
      </CardFooter>
    </Card>
  )
}
