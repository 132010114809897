import { Seal, SealSearch } from "apis/auth";
import { createContext } from "react";


export type SealsContextType = {
  seals: Seal[]
  loading: boolean
  fullyLoaded: boolean
  getMoreSeals: (search: SealSearch) => Promise<void> 
  searchSeals: (search: SealSearch) => Promise<void> 
  setSeals: (seals: Seal[]) => void
}

export default createContext<SealsContextType>({
  seals: [],
  loading: true,
  fullyLoaded: false,
  setSeals: () => null,
  getMoreSeals: () => new Promise(resolve => { resolve() }),
  searchSeals: () => new Promise(resolve => { resolve() }),
})
