import React, { useState } from 'react';
import { Button, useBreakpoints } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';

import NewDocumentModal from 'pages/DocumentsPage/components/NewDocumentModal/NewDocumentModal';
import DetailedDocumentsTable from 'components/DetailedDocumentTable/DetailedDocumentTable';
import TableFooter from 'components/TableFooter/TableFooter';
import Search from 'components/Search/Search';
import PageHeader from 'components/PageHeader/PageHeader';
import { DefaultColumns } from 'components/DetailedDocumentTable/columns';
import { UserDetailed } from 'apis/auth';
import { ApplicationState } from 'modules';
import canUserDo from 'utils/unique/canUserDo';
import { connect } from 'react-redux';
import useFetchDocuments from './components/useFetchDocuments';
import styles from './DocumentsPage.module.scss';

interface Props {
  user?: UserDetailed
}

const mapStateToProps = (state: ApplicationState) => ({ user: state.authentication.user });


function DocumentsPage({ user }: Props) {
  const [documents, loading, isFullyLoaded, fetchDocuments] = useFetchDocuments();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { mobile } = useBreakpoints() || {};
  const { LL } = useI18nContext();

  return (
    <div className={styles.page}>
      <PageHeader title={LL.documentsPage.title()}>
        <Button
          children={LL.common.new()}
          className={user?.permissions && !canUserDo(user, 'allowedToUpload') ? styles.hidden : ''}
          mobileIcon='add'
          suffix='add'
          onClick={() => setIsModalOpen(true)}
        />
      </PageHeader>
      <div className={styles.body}>
        <Search onSearch={fetchDocuments} />
        <DetailedDocumentsTable
          loading={loading}
          data={documents}
          columns={mobile ? [DefaultColumns(LL)[0]] : DefaultColumns(LL)}
        />
        <TableFooter
          isFullyLoaded={isFullyLoaded}
          isLoading={loading}
          onLoadMore={() => fetchDocuments({})}
        />
      </div>
      <NewDocumentModal
        documents={documents}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      />
    </div>
  );
}

export default connect(mapStateToProps)(DocumentsPage);
