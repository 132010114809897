import { compose, createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createRootReducer from 'reducers';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
// import { loadState } from './utils/localStorage';

// const persistedState = loadState();

export const history = createBrowserHistory();

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reduxStore = createStore(
  createRootReducer(history),
  // persistedState,
  composeEnhancers(applyMiddleware(routerMiddleware(history), thunkMiddleware)),
);

export const store = reduxStore;
