/* eslint-disable no-await-in-loop */
/* eslint-disable no-alert */
import React, { useState } from 'react';
import {
  Button, Card, Form, CardFooter, FormSelectAsync, useForm, TextArea
} from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import issuerApi from 'apis/issuerApi';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from "modules";
import { errorParser } from 'utils/requests/errorParser';
import { setUserInfoAction } from 'modules/authentication/actions';
import QrCodeModal from './components/QrCodeModal/QrCodeModal';

import styles from './IssuanceCard.module.scss';

export default function IssuanceCard() {
  const form = useForm();
  const dispatch = useDispatch();
  const { LL } = useI18nContext();
  const [loading, setLoading] = useState(false);
  const [deeplink, setDeeplink] = useState<string>('');
  const [modalOpen, setModalOpen] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const user = useSelector((state: ApplicationState) => state.authentication.user);

  const resetDid = () => {
    if (user){
      setResetLoading(true);
      const { did, ...userWithoutDid } = user;
      issuerApi.removeWalletBind()
        .then(() => setUserInfoAction(userWithoutDid)(dispatch))
        .catch((error) => errorParser(error, err => alert(err.response?.data || 'Some error occured')))
        .finally(() => setResetLoading(false));
    }
  }

  // const checkCredentialOfferStatus = async (credentialOfferId: string) => {
  //   try {
  //     // * do at least once, because useState does not set deeplink value immediately
  //     // * stop checking if user has closed the modal
  //     await new Promise((resolve) => setTimeout(resolve, 2000))
  //     console.log(modalOpen)
  //     if (modalOpen) {
  //       console.log(modalOpen)
  //       const { data } = await issuerApi.getCredentialOffer(credentialOfferId);
  //       if (!data) {
  //         message.success('Credential was successfully received!')
  //         setModalOpen(false);
  //         setDeeplink('');
  //       } else {
  //         checkCredentialOfferStatus(credentialOfferId);
  //       }
  //     }
  //   } catch (error) {
  //       errorParser(error, err => alert(err.response?.data|| 'Some error occured'))
  //   }
  // }

  const onSubmit = async () => {
    // * fields validation
    form.clearErrors();
    const { credentialType } = form.getValues();
    if (!credentialType) {
      form.setError('credentialType', { message: 'Choose Credential Type' });
      return;
    };

    try {
      setLoading(true);
      const { data } = await issuerApi.initiateCredentialOffer(credentialType.value);
      setDeeplink(data);

      const credentialOfferIdMatches = data.match(/offers%2F([0-9a-f]+)/i);
      if (!credentialOfferIdMatches) {
        alert('Broken deeplink, no credential offer id');
        return;
      }

      setModalOpen(true);
      // checkCredentialOfferStatus(credentialOfferIdMatches[1]);
      // setTimeout(() => checkCredentialOfferStatus(credentialOfferIdMatches[1]), 2000);
    } catch (error) {
      errorParser(error, err => alert(err.response?.data || 'Some error occured'));
    } finally {
      setLoading(false);
    }
  }

  const loadCredentialTypes = async () => {
    const { data } = await issuerApi.getCredentialTypes();

    return {
      options: data.map(type => ({value: type, label: type})),
      hasMore: false
    }
  }

  return (
    <Card bodyClassName={styles.card}>
      <QrCodeModal deeplink={deeplink} isOpen={modalOpen} onClose={() => { setDeeplink(''); setModalOpen(false)}} />
      <Form formHook={form} className={styles.searchWrapper}>
        <TextArea
          disabled
          fitContent
          name='did'
          title='Your did'
          defaultValue={user?.did}
        />
        <FormSelectAsync
          isClearable
          name='credentialType'
          formHook={form}
          isMulti={false}
          isCreatable={false}
          loadOptions={loadCredentialTypes}
          placeholder='Choose credential type'
        />
      </Form>
      <CardFooter>
        <Button
          disabled={resetLoading}
          state='warning'
          onClick={resetDid}
          suffix={resetLoading ? 'loading' : 'delete_outline'}
        >
          Reset DID
        </Button>
        <Button
          disabled={loading}
          type='primary'
          onClick={onSubmit}
          suffix={loading ? 'loading' : 'add'}
        >Issue
        </Button>
      </CardFooter>
    </Card>
  )
}