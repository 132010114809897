import React from 'react';
import { ColumnsType, Table } from '@sis-lab/web-ui-components';
import { IDocumentBasic } from 'apis/contract';
import { useHistory } from 'react-router-dom';
import styles from './DetailedDocumentTable.module.scss';

interface Props {
  columns: ColumnsType<IDocumentBasic>
  data?: IDocumentBasic[]
  loading: boolean
}


export default function DetailedDocumentsTable({ columns, data, loading }: Props) {
  const history = useHistory();

  return (
    <Table
      loading={loading}
      columns={columns}
      rowKey={document => document.fingerprint}
      showHeader={false}
      className={loading ? styles.loadingTable : ''}
      onRow={record => ({ onClick: () => history.push(`/documents/${record.fingerprint}`) })}
      dataSource={data}
      pagination={false}
    />
  );
}
