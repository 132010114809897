import React from 'react';
import { Icon } from '@sis-lab/web-ui-components';
import smartLogo from '../../../../images/smart_id_logo.svg'
import mobileLogo from '../../../../images/mobil-id-logo.svg'
import idenfyLogo from '../../../../images/idenfy-logo.svg'
import styles from './VerificationSelector.module.scss';

interface Props {
  onClick: () => void
  selected: boolean
}


export function SmartSelector({ onClick, selected }: Props) {
  return (
    <button type='button' className={[styles.smart, selected && styles.selected].join(' ')} onClick={onClick}>
      <Icon name={'check' as 'arrow_down'} className={selected ? styles.check : styles.hidden} />
      <img alt='smartId verification' src={smartLogo}  />
    </button>
  )
}

export function MobileSelector({ onClick, selected }: Props) {
  return (
    <button type='button' className={[styles.mobile, selected && styles.selected].join(' ')} onClick={onClick}>
      <Icon name={'check' as 'arrow_down'} className={selected ? styles.check : styles.hidden} />
      <img alt='mobileId verification' src={mobileLogo} />
    </button>
  ) 
}

export function IdenfySelector({ onClick, selected }: Props) {
  return (
    <button type='button' className={[styles.idenfy, selected && styles.selected].join(' ')} onClick={onClick}>
      <Icon name={'check' as 'arrow_down'} className={selected ? styles.check : styles.hidden} />
      <img alt='idenfy verification' src={idenfyLogo} />
    </button>
  )
}
