import { UserDetailed } from "apis/auth";
import { PRM } from "apis/prmApi";


const calculatePrmPermissions = (user: UserDetailed, prm?: PRM) => {
  if (!prm) return [false, false, false]
  const { status, payer, payee } = prm
  const paymentCreated = status === 'created' || status === 'invited' || status === 'assigned'
  const paymentNotPaid =
    [
      'created', 'invited', 'accepted', 'received', 'canceled',
      'assigned', 'sent', 'delivered', 'responded_ok', 'responded_warning', 'responded_error'
    ].includes(status)

  const userIsPayee = user.sub === payee.id
  const allowedToInvite = userIsPayee && !payer && paymentCreated;
  const allowedToPay = !userIsPayee && (!payer || payer?.email === user?.email) && paymentNotPaid

  return [allowedToInvite, allowedToPay, userIsPayee] as const
}

export default calculatePrmPermissions
