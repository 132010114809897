// import axios from 'axios';
import config from 'config';
import qs from 'qs'
import { AccessTokenInfo, KeycloakCredentials } from './types';
import { instance } from './instance';

export const getKeycloakToken = async (credentials: KeycloakCredentials) => {
  const { data } = await instance.post<AccessTokenInfo>(config.keycloakUrl,
    qs.stringify({
      username: credentials.username,
      password: credentials.password,
      audience: 'account',
      client_id: 'corposign',
      grant_type: 'password',
      scope: 'access_token',
    }),
    { headers: { 'content-type': 'application/x-www-form-urlencoded' }}
  )

  return data
}

export const refreshToken = async (token: string) => {
  const { data } = await instance.post<AccessTokenInfo>(config.keycloakUrl,
    qs.stringify({
      audience: 'account',
      client_id: 'corposign',
      grant_type: 'refresh_token',
      refresh_token: token,
      scope: 'access_token',
    }),
    { headers: { 'content-type': 'application/x-www-form-urlencoded' }}
  )

  return data
}


const keycloak = {
  getKeycloakToken,
  refreshToken,
}

export default keycloak
