import prmApi from "apis/prmApi"
import { useState } from "react"


const useRetryEdelivery = (prmId: string, onSuccess?: () => void) => {
  const [loading, setLoading] = useState(false)

  const retryEdelivery = () => {
    // use signal to cancel request?
    setLoading(true)
    prmApi.retryEdelivery(prmId)
      .then(() => onSuccess && onSuccess())
      .catch(() => null)
      .finally(() => setLoading(false))
  }


  return [retryEdelivery, loading] as const
}

export default useRetryEdelivery
