import { Button } from "@sis-lab/web-ui-components";
import React, { useContext, useEffect, useState } from "react";
import prmApi from "apis/prmApi";
import PrmContext from "pages/PaymentPage/components/PrmContext/PrmContext";
import { errorParser } from "utils/requests/errorParser";
import config from "config";
import { useI18nContext } from "i18n/i18n-react";


export default function QrCodeButton() {
  const [token, setToken] = useState<string>()
  const [loading, setLoading] = useState(false)
  const { prm } = useContext(PrmContext)
  const { LL } = useI18nContext()

  useEffect(() => {
    const timeout = setTimeout(() => token && setToken(undefined), 5 * 60 * 1000)
      
    return () => clearTimeout(timeout)
  }, [token])


  const openGateway = async () => {
    if (!token) {
      try {
        setLoading(true)
        const { data } = await prmApi.getGatewayToken(prm?.id || '')
        setToken(data.paymentToken)
        const url = `${config.gatewayAppUrl}/?paymentToken=${data.paymentToken}`;
        window.open(url, '_blank')
      } catch (e) {
        // eslint-disable-next-line no-alert
        errorParser(e, () => alert('Something went wrong :('))
      } finally {
        setLoading(false)
      }
    
    } else {
      const url = `https://gateway.corposign.net/?paymentToken=${token}`;
      window.open(url, '_blank')
    }
  }

  return (
    <Button
      children={LL.paymentPage.infoCard.gatewayButton()}
      suffix={loading ? 'loading' : 'qr_code' as 'add'}
      mobileIcon={'qr_code' as 'add'}
      disabled={loading}
      type='secondary'
      onClick={openGateway}
    />
  )
} 
