import React from 'react';
import { Button, Card, CardFooter, CardHeader } from '@sis-lab/web-ui-components';
import { useHistory } from 'react-router-dom';
import { useI18nContext } from 'i18n/i18n-react';
import verifiedImg from '../../../images/account-verified.svg';
import styles from '../IdentityConfirmedPage.module.scss'

export default function KycFlow() {
  const { LL } = useI18nContext() 
  const history = useHistory()

  return (
    <Card className={styles.card}>
      <CardHeader
        title={<h2>{LL.confirmedPage.kycFlow.title()}</h2>}
      />
      <div className={styles.body}>
        <img src={verifiedImg} alt='success'/>
        <p>{LL.confirmedPage.kycFlow.explanation()}</p>
        <span>{LL.confirmedPage.kycFlow.prompt()}</span>
      </div>
      <CardFooter className={styles.footer}>
        <Button onClick={() => history.push('/login')} children={LL.confirmedPage.kycFlow.buttonText()} />
      </CardFooter>
    </Card>
  )
}
