/* eslint-disable no-nested-ternary */
import React from "react";
import { RcFile, Upload, useBreakpoints } from "@sis-lab/web-ui-components";
import { useI18nContext } from "i18n/i18n-react";
// import { ApplicationState } from "modules";
// import { useSelector } from "react-redux";
// import { DocumentInfo } from "../../../types";
import useFileUpload from "./useFileUpload";
import { NewDocumentInfo } from "../../types";

interface Props {
  afterUpload: (documentInfo: NewDocumentInfo) => void
}

export default function FileUpload({ afterUpload }: Props) {
  // const saveForPreview = useSelector((state: ApplicationState) => state.authentication.metadata?.preferences.saveForPreview)
  const [uploadFile, error, loading, document] = useFileUpload()
  const { mobile } = useBreakpoints() || {};
  const { LL } = useI18nContext()

  const beforeUpload = async (file: RcFile) => {
    const documentInfo = await uploadFile(file)
    if (documentInfo) afterUpload(documentInfo)
    return false; // to prevent default form submit
  };

  return (
    <Upload
      name='file'
      size='small'
      disabled={loading}
      icon={
        loading ? 'loading' :
          document ? 'document' :
            'find'
      }
      beforeUpload={beforeUpload}
      errorMessage={error}
      text={
        loading ? LL.uploadPage.selectLoading() :
          document ? (document.shortTitle || document.title) :
            mobile ? LL.commonFields.upload.prompt_mobile() :
              LL.commonFields.upload.prompt()
      }
      helpText={LL.commonFields.upload.helpText()}
    />
  )
}
