const ascii = (s: string) => s.normalize("NFKD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

/**
 * 
 * The check is case INsensitive!
 * @param target 
 * @param sub 
 * @returns true/false
 */
export const localeContains = (target: string, sub: string) => {
  if (sub === "") return true;
  if (!sub || !target.length) return false;
  if (sub.length > target.length) return false;
  return ascii(target.toLocaleLowerCase()).includes(ascii(sub.toLocaleLowerCase()));
}

export default localeContains;