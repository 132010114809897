import PasswordValidator from 'password-validator';

// eslint-disable-next-line import/prefer-default-export
export const passwordValidator = new PasswordValidator();

passwordValidator
  .is().min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits(1)
  .has()
  .not()
  .spaces();
