
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, FormSelect, Input, useForm } from '@sis-lab/web-ui-components';
import { SealSearch } from 'apis/auth';
import InfiniteTable from 'components/InfiniteTable/InfiniteTable';
import SealsContext from '../SealsContext/SealsContext';
import { SealColumns } from './components/SealsColumns';
import styles from './SealsTable.module.scss';

type TypedFormValues = {
  substring?: string
  sort?: { value: 'createdAt' | 'name', label: string }
}

export default function SealsTable() {
  const { seals, loading, fullyLoaded, getMoreSeals, searchSeals } = useContext(SealsContext)
  const history = useHistory()
  const form = useForm();

  const handleSearch = () => {
    const { substring, sort } = form.getValues() as TypedFormValues
    const search: SealSearch = {}
    if (sort) search.sortBy = sort.value
    search.substring = substring
    searchSeals(search)
  }

  const handleLoad = useCallback(async () => {
    const { substring, sort } = form.getValues() as TypedFormValues
    const search: SealSearch = {}
    if (sort) search.sortBy = sort.value
    if (substring) search.substring = substring 
    await getMoreSeals(search)
  }, [getMoreSeals])

  return (
    <div className={styles.wrapper}>
      <Form formHook={form} className={styles.search}>
        <Input
          onChange={handleSearch}
          name='substring'
          placeholder='Search'
        />
        <FormSelect
          fixedHeight
          name='sort'
          onChange={handleSearch}
          placeholder='Sort by'
          options={[
            { value: 'createdAt', label: 'Sort by date'},
            { value: 'name', label: 'Sort by name' }
          ]}
        />
      </Form>
      <InfiniteTable
        loadMore={handleLoad}
        showHeader={false}
        pagination={false}
        fullyLoaded={fullyLoaded}
        loading={loading}
        columns={SealColumns()}
        dataSource={seals}
        rowKey={({ sub }) => sub}
        onRow={seal => ({ onClick: () => history.push(`/seals/${seal.sub}`) })}
      />
    </div>
  )
}
