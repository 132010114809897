/* eslint-disable no-param-reassign */
import { message } from '@sis-lab/web-ui-components';
import { getMyDocuments, getSealDocuments, IDocumentsSearch, MyDocumentsResponse } from 'apis/contract';
import { ApplicationState } from 'modules';
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";

const useFetchDocuments = () => {
  const [response, setResponse] = useState<MyDocumentsResponse>();
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state: ApplicationState) => state.authentication.user)

  const fetchDocuments = async (searchParams: IDocumentsSearch, reset?: boolean) => {
    setLoading(true);
    searchParams.bookmark = reset ? undefined : response?.bookmark
    searchParams.pageSize = 10;

    try {
      const { data } = user?.sealSub
        ? await getSealDocuments(user.sealSub, searchParams)
        : await getMyDocuments(searchParams);
      if (!reset && response?.documents) data.documents = response.documents.concat(data.documents);
      setResponse(data);
      setFullyLoaded(!data.bookmark);
    } catch (err) {
      message.error(`${err}`, 3);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchDocuments({})
  }, [])

  return [response?.documents || [], loading, fullyLoaded, fetchDocuments] as const
}

export default useFetchDocuments;
