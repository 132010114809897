import React from 'react';
import { ColumnsType } from '@sis-lab/web-ui-components';
import {Seal } from 'apis/auth';
import styles from './SealsColumns.module.scss';


export const SealColumns = ():ColumnsType<Seal> => [
  {
    title: 'name',
    key: 'name',
    render: (_text, { name, email }) => (
      <div className={styles.title}>
        <h3>{name}</h3>
        <p>{email}</p>
      </div>
    )
  },
];