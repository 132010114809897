import { PaginatedResponse } from 'types';
import { objectToQuery } from 'utils';
import { instance } from '../instance';
import { CreateMemberRequest, Member, MemberSearchParams, Organization, UpdateMemberRequest } from '../types';

export const getOrganization = () => (
  instance.get<Organization>(`/organizations`)
)

export const updateOrganization = () => (
  instance.put<Organization>(`/organizations`)
)

export const deleteOrganization = () => (
  instance.delete(`/organizations`)
)

// ==== Members ====
export const createMember = (request: CreateMemberRequest) => (
  instance.post(`/organizations/members`, request)
)

export const resendSuccessCreateMember = (email: string) => (
  instance.post(`/organizations/members/email`, { email })
)

export const getMember = (sub: string) => (
  instance.get<Member>(`/organizations/members/${sub}`)
)

export const getMembers = (searchParams: MemberSearchParams, signal?: AbortSignal) => (
  instance.get<PaginatedResponse<Member>>(`/organizations/members${objectToQuery(searchParams)}`, { signal })
)

export const updateMember = (sub: string, request: UpdateMemberRequest) => (
  instance.put<Member>(`/organizations/members/${sub}`, request)
)

export const deleteMember = (sub: string) => (
  instance.delete(`/organizations/members/${sub}`)
)
