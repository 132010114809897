import xmlApi from "apis/xml";
import { message } from "@sis-lab/web-ui-components";
import { CorpoFile } from "../types";

type AfterTransform = (doc: CorpoFile) => void;

const visualizeXml = async (file: CorpoFile, afterTransform: AfterTransform, targetSub?: string) => {
  try {
    const xmlTransformResponse = await xmlApi.visualizeXmlWithXslt(file.blob, targetSub, file.type);

    const enc = new TextEncoder();
    const xmlTransformedAB = enc.encode(xmlTransformResponse.data);
    const tranformedBlob = new Blob([xmlTransformedAB], { type: 'text/html' })
    const transformedFileName = `${file.fileName.split('.', 2)[0]}.html`

    afterTransform({ blob: tranformedBlob, fileName: transformedFileName })
  } catch (err) {
    message.error('Error during XML vizualization for preview')
  }
}
export default visualizeXml;