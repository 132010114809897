import { useEffect, useState } from 'react';
import { message } from '@sis-lab/web-ui-components';
import { IDocumentDetailed, validateDocument } from 'apis/contract';

const useFetchDocument = (fingerprint?: string) => {
  const [document, setDocument] = useState<IDocumentDetailed>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!fingerprint) return;
    const fetchDocument = async () => {
      try {
        setIsLoading(true);
        const { data } = await validateDocument(fingerprint);
        setDocument(data);
      } catch (err) {
        message.error('Encountered error loading the signature');
      } finally {
        setIsLoading(false);
      }
    };
    fetchDocument();
  }, [fingerprint]);

  return [document, isLoading] as const
};

export default useFetchDocument;
