import axios from "axios";
import { useEffect, useState } from "react";

import prmApi from "apis/prmApi";
import { KevinConfig, MontonioConfig } from "apis/prmApi/types";
import { message } from "@sis-lab/web-ui-components";


const useGProvidersConfigs = () => {
  const [kevingLoading, setMontonioLoading] = useState<boolean>(true);
  const [montonioLoading, setKevinLoading] = useState<boolean>(true);
  const [montonioConfig, setMontonioConfig] = useState<MontonioConfig>();
  const [kevinConfig, setKevinConfig] = useState<KevinConfig>();

  const getKevinConfig = async () => {
    try {
      const { data } = await prmApi.getKevinConfig();
      setKevinConfig(data);
    } catch (err) {
      if (!axios.isAxiosError(err)) return;
      if (err.response?.status !== 404) message.error('Something went wrong when loading Kevin config')
      else message.warning(`Kevin config is not setup`);
    } finally {
      setKevinLoading(false);
    }
  }

  const getMontonioConfig = async () => {
    try {
      const { data } = await prmApi.getMontonioConfig();
      setMontonioConfig(data);
    } catch (err) {
      if (!axios.isAxiosError(err)) return;
      if (err.response?.status !== 404) message.error('Something went wrong when loading Montonio config')
      else message.warning(`Montonio config is not setup`);
    } finally {
      setMontonioLoading(false);
    }
  }
  
  const updateMontonioConfig = async (newConfig: MontonioConfig) => {
    setMontonioLoading(true)
    prmApi.updateMontonioConfig(newConfig)
      .then(() => setMontonioConfig({ ...montonioConfig, ...newConfig }))
      .catch(err => {
        if (!axios.isAxiosError(err)) return
        message.error('Something went wrong while updating RTP config')
        console.log(err)
      })
      .finally(() => setMontonioLoading(false))
  }
  
  const updateKevinConfig = async (newConfig: KevinConfig) => {
    setKevinLoading(true)
    prmApi.updateKevinConfig(newConfig)
      .then(() => setKevinConfig({ ...kevinConfig, ...newConfig }))
      .catch(err => {
        if (!axios.isAxiosError(err)) return
        message.error('Something went wrong while updating RTP config')
        console.log(err)
      })
      .finally(() => setKevinLoading(false))
  }

  useEffect(() => {
      getMontonioConfig();
      getKevinConfig();
    }, []);

  return [montonioConfig, kevinConfig, updateMontonioConfig, updateKevinConfig, montonioLoading || kevingLoading] as const;
}

export default useGProvidersConfigs;
