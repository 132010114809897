import React, { useEffect, useState } from "react"
import { Form, Input, useForm } from "@sis-lab/web-ui-components"

import { MontonioConfig } from "apis/prmApi"
import EditFooter from "../../EditFooter/EditFooter"

import styles from './ProviderForm.module.scss'

interface Props {
  montonioConfig?: MontonioConfig
  updateMontonioConfig: (newConfig: MontonioConfig) => Promise<void>
  hidden: boolean
  loading: boolean
}


export default function MontonioForm({ montonioConfig, updateMontonioConfig, hidden, loading }: Props) {
  const [inEdit, setInEdit] = useState(false);
  const form = useForm();

  useEffect(() => {
    form.clearErrors()
    form.setValue('clientKey', montonioConfig?.clientKey)
    form.setValue('clientSecret', montonioConfig?.clientSecret)
    form.setValue('name', montonioConfig?.name)
    form.setValue('redirectUrl', montonioConfig?.redirectUrl)
  }, [montonioConfig, inEdit]);

  const startConfigUpdate = async () => {
    form.clearErrors()
    const newConfig = form.getValues() as MontonioConfig
    const errors: { field: string, message: string }[] = []

    if (!newConfig.clientKey) errors.push({ field: 'clientKey', message: 'Please provide Client Key'})
    if (!newConfig.clientSecret) errors.push({ field: 'clientSecret', message: 'Please provide Client Secret'})
    if (!newConfig.name) errors.push({ field: 'name', message: 'Please provide Name'})
    if (!newConfig.redirectUrl) errors.push({ field: 'redirectUrl', message: 'Please provide Redirect Url'})

    if (errors.length > 0) {
      errors.forEach(({ field, message }) => form.setError(field, { message }))
      return
    }

    await updateMontonioConfig(newConfig)
    setInEdit(false)
  }

  return (
    <Form formHook={form} disabled={!inEdit || loading} className={[styles.form, hidden && styles.hidden].join(' ')}>
      <Input
        name='clientKey'
        title='Client Key'
        tooltip='To do'
        defaultValue={montonioConfig?.clientKey}
      />
      <Input
        name='clientSecret'
        title='Client Secret'
        tooltip='To do'
        defaultValue={montonioConfig?.clientSecret}
      />
      <Input
        name='name'
        title='Name'
        tooltip='To do'
        defaultValue={montonioConfig?.name}
      />
      <Input
        name='redirectUrl'
        title='Redirect Url'
        tooltip='To do'
        defaultValue={montonioConfig?.redirectUrl}
      />
      <EditFooter
        inEdit={inEdit}
        loading={loading}
        onChange={isInEdit => setInEdit(isInEdit)}
        onSumbit={startConfigUpdate}
      />
    </Form>
  )
}
