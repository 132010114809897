export const transformDate = (originalDate: string | number) => {
  // eslint-disable-next-line eqeqeq
  const date = originalDate == +originalDate ? +originalDate : originalDate;
  return (`${new Date(date).toLocaleDateString('lt-lt')} ${new Date(date).toLocaleTimeString('lt-lt')}`);
};

export const getCurrentTimeDate = () => {
  const current = new Date();

  const date = `0${current.getDate()}`.slice(-2);
  const month = `0${current.getMonth() + 1}`.slice(-2);
  const year = current.getFullYear();

  const hours = `0${current.getHours()}`.slice(-2);
  const minutes = `0${current.getMinutes()}`.slice(-2);
  const seconds = `0${current.getSeconds()}`.slice(-2);

  return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
};
