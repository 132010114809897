import { Card, CardHeader, Spin } from '@sis-lab/web-ui-components'
import React from 'react'
import styles from './RedirectPage.module.scss'

interface Props {
  partner: string
}


// ? do we need to specify a partner?
function RedirectPage({ partner }: Props) {
  return (
    <Card className={styles.card}>
      <CardHeader title={<h2>Redirecting you...</h2>} />
      {/* <p>{LL.idenfyEmailPage.completeMessage()}</p> */}
      <Spin size='large'/>
    </Card>
  )
}

export default RedirectPage