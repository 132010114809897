import React from "react"
import { Avatar, Caption, Label } from "@sis-lab/web-ui-components"
import { PrmPayee } from "apis/prmApi"
import styles from './PayeeRow.module.scss'

interface Props {
  payee: PrmPayee
}

export default function PayeeRow({ payee }: Props) {
  return (
    <div className={styles.payeeRow}>
      <Avatar width={40} name={payee.id} />
      <div>
        <Label>{payee.name}</Label>
        <Caption small>{payee.email}</Caption>
      </div>
    </div>
  )
}
