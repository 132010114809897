/* eslint-disable no-param-reassign */
import { message } from '@sis-lab/web-ui-components';
import { useEffect, useState } from 'react';
import { getMySignatures, getSealSignatures, ISignaturesSearch, MySignaturesResponse } from 'apis/contract';
import { ApplicationState } from 'modules';
import { useSelector } from "react-redux";

const useFetchSignatures = () => {
  const [response, setResponse] = useState<MySignaturesResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [isFullyLoaded, setIsFullyLoaded] = useState(false);
  const user = useSelector((state: ApplicationState) => state.authentication.user)

  const fetchSignatures = async (searchParams: ISignaturesSearch, reset?: boolean, signal?: AbortSignal) => {
    setIsLoading(true);
    searchParams.bookmark = reset ? undefined : response?.bookmark
    searchParams.pageSize = 10;

    try {
      const { data } = user?.sealSub
        ? await getSealSignatures(user.sealSub, searchParams, signal)
        : await getMySignatures(searchParams, signal);
      if (!reset && response?.signatures) data.signatures = response.signatures.concat(data.signatures);
      setResponse(data);
      setIsFullyLoaded(!data.bookmark);
    } catch (err) {
      message.error(`${err}`, 3);
    } finally {
      setIsLoading(true)
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchSignatures({});
  }, []);

  return [response?.signatures || [], isLoading, isFullyLoaded, fetchSignatures] as const;
};

export default useFetchSignatures;
