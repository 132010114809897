/* eslint-disable react/jsx-props-no-spreading */
import { Table } from "@sis-lab/web-ui-components";
import { TableProps } from "@sis-lab/web-ui-components/dist/components/Table/Table";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import LoadingFooter from "./LoadingFooter/LoadingFooter";

interface Props<T extends object> extends Omit<TableProps<T>, 'loading'> {
  fullyLoaded: boolean
  loadMore: () => Promise<void>
  loading: boolean
}

// eslint-disable-next-line max-len
export default function InfiniteTable<T extends object>({ loadMore, onRow, loading, dataSource, fullyLoaded, ...rest }: Props<T>) {
  const [inViewRef, inView] = useInView();

  useEffect(() => {
    if (inView && !fullyLoaded && !loading) {
      loadMore()
    }
  }, [inView])
  

  //  old implementation
  // const targetRef = useRef<HTMLElement>(); // reference to the row we want to observe
  // const observerRef = useRef<IntersectionObserver>();

  // useEffect(() => {
  //   // console.log('1st useeffect with disconnecting observer')
  //   // console.log(targetRef.current)
  //   observerRef.current = new IntersectionObserver((entries) => {
  //     if (!entries[0].isIntersecting) return;
  //     // console.log('observerRef before disconnection')
  //     // console.log(observerRef)
  //     // console.log(entries)
  //     observerRef.current?.disconnect()       // * disconnect observer
  //     targetRef.current = undefined           // * remove old row from ref, new one will be added inside onRow below
  //     console.log('1st useeffect loading more')
  //     loadMore()
  //   })
  // }, [loadMore])

  // Observe
  // useEffect(() => {
  //   // console.log('2nd useeffect with hanging observer')
  //   // console.log(targetRef.current)
  //   // console.log(observerRef.current)
  //   if (targetRef.current && observerRef.current && !fullyLoaded) {
  //     // console.log('2nd useeffect hanged observer')
  //     observerRef.current.observe(targetRef.current)
  //   }

  //   return () => observerRef.current?.disconnect()
  // }, [targetRef.current, observerRef, fullyLoaded, dataSource])

  // * check the current row and if it is the last 2nd - hang ref with observer on it + default onRow
  // * onRow is more of "put this attributes on row" instead of "do it when row clicked"
  // * onRow returns an object of attributes
  // * https://ant.design/components/table#onrow-usage

  // * antd wants a function returning a component, just tested memoization here, leaving some notes
  // * MemoizedLoadingFooter is to avoid creation of new anonymous function every time when Loader is needed
  const MemoizedLoadingFooter = React.useCallback(() => <LoadingFooter />, [])
  // * MemoizedLoadingFooter is kinda to avoid unnecessary recalculation if loading has not changed
  // * BUT it will still be changed a lot, because that's the point of LoadingFooter, so IMHO no benefit in useMemo
  // const MemoizedFooter = React.useMemo(() => loading ? MemoizedLoadingFooter : undefined, [loading]);

  return (
    <Table
      footer={loading ? MemoizedLoadingFooter : undefined}
      // footer={MemoizedFooter}
      // footer={loading ? () => <LoadingFooter /> : undefined}
      onRow={(record, index) => {
        if (dataSource?.length && dataSource.length - 3 === index && !fullyLoaded) {
          return {
            // ref: targetRef,
            ref: inViewRef,
            ...(onRow && onRow(record, index)), // * spread the returned attributes into the object next to the ref
          }
        }
        if (onRow) return onRow(record, index)  // * do not spread, just return the object
        return {}                               // * return empty object of attributes
      }}
      dataSource={dataSource}
      {...rest}
    />
  )
}
