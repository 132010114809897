import React from 'react';
import CertCard from './components/CertCard/CertCard';
import ProfileCard from './components/ProfileCard/ProfileCard';
import styles from './ProfileTab.module.scss';


export default function ProfileTab() {
  return (
    <div className={styles.profileTab}>
      <ProfileCard />
      <CertCard />
    </div>
  )
}
