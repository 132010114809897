import React from 'react';
import LangCard from './component/LangCard/LangCard';
import PasswordCard from './component/PasswordCard/PasswordCard';
import UploadSettingsCard from './component/UploadSettingsCard/UploadSettingsCard';
import styles from './SecurityTab.module.scss';

export default function SecurityTab() {
  return <div className={styles.securityTab}>
    <PasswordCard />
    <div className={styles.column}>
      <UploadSettingsCard />
      <LangCard />
    </div>
  </div>
}
