import React from "react";
import { Icon } from "@sis-lab/web-ui-components"
import { Protections } from "utils/unique/canUserDo";


type Route = {
  route: string,
  content: string,
  prefix: JSX.Element,
  suffix?: JSX.Element,
  protection?: Protections
}

type DividerRoute = { 
  isDivider: boolean
  protection?: Protections
}

export type NavigationRoute = Route | DividerRoute

export const navigationRoutes: NavigationRoute[] = [
  {
    route: '/upload',
    prefix: <Icon name={'upload_file' as 'arrow_down'} />,
    content: 'upload',
    protection: 'allowedToUpload',
  },
  {
    route: '/documents',
    prefix: <Icon name='inbox' />,
    content: 'documents',
  },
  {
    prefix: <Icon name={'upcoming' as 'arrow_down'} />,
    route: '/invites',
    content: 'invites',
  },
  {
    prefix: <Icon name='verified' />,
    route: '/signatures',
    content: 'signatures',
  },
  { isDivider: true, protection: 'allowedToView' },
  {
    prefix: <Icon name={'manage_accounts' as 'arrow_down'} />,
    route: '/organization',
    protection: 'admin',
    content: 'admin',
  },
  {
    prefix: <Icon name={'domain' as 'arrow_down'} />,
    route: '/seals',
    protection: 'admin',
    content: 'seals',
  },
  {
    prefix: <Icon name='inbox' />,
    route: '/organization/documents',
    protection: 'allowedToView',
    content: 'orgDocuments',
  },
  {
    prefix: <Icon name='verified' />,
    route: '/organization/signatures',
    protection: 'allowedToView',
    content: 'orgSignatures',
  },
  { isDivider: true },
  {
    prefix: <Icon name={'account_balance_wallet' as 'arrow_down'} />,
    route: '/payments',
    content: 'payments',
  },
  { isDivider: true },
  {
    prefix: <Icon name='troubleshoot' />,
    route: '/validate',
    content: 'validator',
  },
  {
    prefix: <Icon name='account_circle' />,
    route: '/profile/0',
    content: 'account',
  },
];
