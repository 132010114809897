import React, { useState } from 'react';
import PasswordLogin from './components/PasswordLogin/PasswordLogin';

import VpLogin from './components/VpLogin/VpLogin';


export default function LoginPage() {
  const [loginType, setLoginType] = useState<'password' | 'vp'>('password');

  return (
    loginType === 'password'
      ? <PasswordLogin switchToVpLogin={() => setLoginType('vp')} />
      : <VpLogin  switchToPasswordLogin={() => setLoginType('password')} />
  );
}
