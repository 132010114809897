import { useState } from 'react';
import { addDocument } from 'apis/corposign-storage';
import { getUserLocation } from 'utils';
import config from 'config';
import { createDocument, ICreateDocumentRequest } from 'apis/contract';
import { IReferencedDocument } from 'types';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'modules';


const useCreateDocument = (referencedDocs: IReferencedDocument[], invitees: string[], uploadFile: boolean) => {
  const [loading, setLoading] = useState(false);
  const signOnUpload = useSelector((state: ApplicationState) => {
    const allowedToSign = state.authentication.user?.permissions?.allowedToSign
    const prefersToSignOnUpload = state.authentication.metadata?.preferences.signOnUpload
    return allowedToSign && prefersToSignOnUpload
  })

  const postDocument = async (documentData: any, fileData: any) => {
    setLoading(true);

    try {
      const document: ICreateDocumentRequest = {
        fingerprint: documentData.fingerprint,
        format: documentData.format,
        notes: documentData.notes !== '' ? documentData.notes : undefined,
        referencedDocuments: referencedDocs,
        sign: signOnUpload,
        title: documentData.title,
        type: documentData?.type?.value || 'other',
        invitees,
      };

      await getUserLocation().then((res) => { document.location = res;});

      if (uploadFile) {
        const documentMeta = await addDocument({
          fileName: documentData.title,
          fingerprint: documentData.fingerprint,
          documentContent: {
            name: documentData.title,
            format: documentData.format,
            data: fileData,
          },
        });
        document.uri = `${config.storageApiUrl}/files/${documentMeta.data.id}`;
      }

      await createDocument(document);
      setLoading(false);
      
    } catch (e) {
      setLoading(false)
      throw(e);
    }
  }

  return [postDocument, loading] as const;
}

export default useCreateDocument;
