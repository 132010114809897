import { instance } from '../instance'
import { User, UserDetailed } from '../types'

export const getUserInfo = async () => (
  instance.get<UserDetailed>('/oidc/userinfo')
)

export const getVpSessionUsers = async (session: string) => (
  instance.get<User[]>(`/oidc/vp/sessions/${session}/users`)
)

export const getVpSessionUserToken = async (session: string, sub: string) => (
  instance.post<any>(`/oidc/vp/sessions/${session}/token`, { sub })
)
