import React from "react";
import AdminDashboard from "./AdminDashboard";
import MembersProvider from "./components/MemberContext/MembersProvider";


export default function AdminWrapper() {
  return (
    <MembersProvider>
      <AdminDashboard/>
    </MembersProvider>
  )
}