import React from 'react';
import { Card, CardHeader, Switch } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import { ApplicationState } from 'modules';
import auth, { UserMetadata } from 'apis/auth';
import { connect, useDispatch } from 'react-redux';
import { setMetadataAction } from 'modules/authentication/actions';
import styles from './UploadSettingsCard.module.scss';

interface Props {
  metadata?: UserMetadata
}

const mapStateToProps = (state: ApplicationState) => ({
  metadata: state.authentication.metadata
});


function UploadSettingsCard({ metadata }: Props) {
  const { LL } = useI18nContext();
  const dispatch = useDispatch()

  const onToogle = async (field: 'signOnUpload' | 'saveForPreview' | 'provideLocation' | 'allowEidEasySignatures') => {
    if (!metadata) return;
    const { preferences } = metadata

    const newMetadata: UserMetadata = {
      ...metadata,
      preferences: {
        homePage: '/documents',
        language: preferences?.language,
        signOnUpload: field === 'signOnUpload' ? !preferences.signOnUpload :  preferences.signOnUpload,
        saveForPreview: field === 'saveForPreview' ? !preferences.saveForPreview :  preferences.saveForPreview,
        provideLocation: field === 'provideLocation' ? !preferences.provideLocation : preferences.provideLocation,
        allowEidEasySignatures: field === 'allowEidEasySignatures'
          ? !preferences.allowEidEasySignatures
          : preferences.allowEidEasySignatures,
      }
    }

    setMetadataAction(newMetadata)(dispatch)
    await auth.updateUserMetadata(newMetadata)
  }

  return (
    <Card size='small' className={styles.card}>
      <CardHeader title={LL.uploadSettingsCard.title()} />
      <Switch
        reversed
        checked={metadata?.preferences.signOnUpload}
        className={styles.switch}
        children={LL.uploadSettingsCard.signOnUpload()}
        onChange={() => onToogle('signOnUpload')}
      />
      <Switch 
        reversed
        checked={metadata?.preferences.saveForPreview}
        className={styles.switch}
        onChange={() => onToogle('saveForPreview')}
        children={LL.uploadSettingsCard.storeDocumentFile()}
      />
      <Switch 
        reversed
        checked={metadata?.preferences.provideLocation}
        className={styles.switch}
        onChange={() => onToogle('provideLocation')}
        children={LL.uploadSettingsCard.provideLocation()}
      />
      <Switch 
        reversed
        checked={metadata?.preferences.allowEidEasySignatures}
        className={styles.switch}
        onChange={() => onToogle('allowEidEasySignatures')}
        children={LL.uploadSettingsCard.allowEidEasySignatures()}
      />
    </Card>
  )
}

export default connect(mapStateToProps)(UploadSettingsCard);
