import { objectToQuery } from 'utils';
import { instance } from './instance';
import { ISignature, ISignatureDetailed, ISignaturesSearch, MySignaturesResponse, SignatureDetails } from './types';

export const getMySignatures = (searchParams: ISignaturesSearch, signal?: AbortSignal) => (
  instance.get<MySignaturesResponse>(`/signatures${objectToQuery(searchParams)}`, { signal })
)

export const getSignature = (id: string) => (
  instance.get<ISignatureDetailed>(`/signatures/${id}`)
)

export const signDocument = (fingerprint: string, signature: SignatureDetails) => (
  instance.post<ISignature>(`/documents/${fingerprint}/signatures`, signature)
)
