import { Invite, ISignature, ISignatureDetailed } from 'apis/contract';
import React from 'react';
import styles from './Counter.module.scss';

interface Props {
  invites: Invite[]
  signatures: ISignature[] | ISignatureDetailed[]
}


export default function Counter({ invites, signatures }: Props) {
  const signedCount = signatures.length
  const invitedCount = invites.reduce((sum, invite) => invite.status === 'waiting' ? sum + 1 : sum, 0)
  const total = invitedCount + signedCount
  if (invitedCount === 0 && signedCount !== 0) return <span className={styles.counterSigned}>{signedCount}/{total}</span>
  return <span className={styles.counter}>{signedCount}/{total}</span>
}
