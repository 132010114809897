import React, { useContext, useEffect, useRef } from 'react';
import { Form, FormSelect, Input, RangePicker, RangeValue, useForm } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import { PrmStatus } from 'apis/prmApi/prm/types';
import * as _ from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import {
  useQueryParams,
  StringParam,
  ArrayParam,
} from 'use-query-params';
import { useLocation } from 'react-router-dom';

import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import PaymentsContext from '../PaymentsContext';
import styles from './PaymentSearch.module.scss';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

interface FormFields {
  status: { value: PrmStatus, label: string }[];
  date: RangeValue
  search: string
}

type QueryParams = {
  from?: string
  to?: string
  status?: string[]
  search?: string
}

const statusOptions =
  [
    'created', 'started', 'canceled', 'rejected', 'received',
    'accepted', 'paid', 'invited', 'assigned', 'sent', 'delivered',
    'responded_error', 'responded_warning', 'responded_ok'
  ]

const validateQuery = (query: QueryParams) => {
  // TODO
  // * search does not matter
  // * status only valid items, drop invalid ones
  // * from / to should be only iso
}

export default function PaymentSearch() {
  const location = useLocation();
  const [query, setQuery] = useQueryParams({
    from: StringParam,
    to: StringParam,
    search: StringParam,
    status: ArrayParam,
  });

  const form = useForm({ defaultValues: { status: undefined, search: undefined, date: undefined } });
  const { LL } = useI18nContext()
  const { filter, setReset, setFilter, setAbortSignal } = useContext(PaymentsContext)
  
  const abortControllerRef = useRef(new AbortController())
  const setTimeoutRef = useRef<NodeJS.Timeout>()

  const watch = form.watch() as FormFields

  useEffect(() => {
    form.reset({}, { keepValues: true })
  }, [location]);

  useEffect(() => {
    const subscription = form.watch(formValues => {
      const typedValues = formValues as FormFields;

      if (_.isEmpty(form.formState.dirtyFields)) return;

      const newFilter = {
        search: typedValues.search?.trim() || undefined,
        status: typedValues.status?.map(i => i.value),
        from: typedValues.date?.[0]?.startOf('day').toISOString(),
        to: typedValues.date?.[1]?.endOf('day').toISOString(),
      }

      // * checking if form values did not change - do not reset / change state
      // console.log(`_.isEqual(query, newFilter): ${_.isEqual(query, newFilter)} \n query:${JSON.stringify(query)} \n newFilter: ${JSON.stringify(newFilter)}`)
      if (_.isEqual(query, newFilter)) return;
      // console.log(`passed form watch new query check, old ${JSON.stringify(query)}; new: ${JSON.stringify(newFilter)}`)
      // * if user continued writing - drop previously scheduled filter update
      if (setTimeoutRef.current) clearTimeout(setTimeoutRef.current);
      const { pathname } = location;

      // * set timeout allows user to write more, but at the same time delay before reset is noticeable
      setTimeoutRef.current = setTimeout(() => {
        if (window.location.pathname === pathname) setQuery(newFilter)
      }, 500)
    })

    return () => subscription.unsubscribe();
  }, [watch])

  useEffect(() => {
    const newFilter = {
      search: query.search || undefined,
      status: query.status as PrmStatus[] || [],
      from: query.from || undefined,
      to: query.to || undefined,
    }

    // console.log(`_.isEqual(filter, newFilter): ${_.isEqual(filter, newFilter)}`);
    if (_.isEqual(filter, newFilter)) return;
    // console.log(`passed query watch new filter check, old ${JSON.stringify(filter)}; new: ${JSON.stringify(newFilter)}`);

    const date: (Dayjs | undefined)[] = [undefined, undefined]

    if (query.from) {
      date[0] = dayjs(query.from);
    }
    if (query.to) {
      date[1] = dayjs(query.to);
    }

    form.setValue('search', query.search)
    form.setValue('status', query.status?.map((val) => ({ label: val, value: val })))
    form.setValue('date', date)

    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();

    setAbortSignal(abortControllerRef.current.signal);
    setReset(true);
    setFilter(newFilter);
  }, [query])

  return (
    <Form formHook={form} className={styles.searchWrapper}>
      <div className={styles.searchAndToogle}>
      {/* removed incoming/putgoing filter, more info inside useGetPaymentList */}
        {/* <PaymentSwitch
          className={styles.toogle}
        /> */}
        <Input
          debaunceTimeMs={500}
          name='search'
          placeholder={LL.paymentsPage.search.placeholder()}
          wrapperClassName={styles.searchField}
          suffix='search'
        />
      </div>
      <FormSelect
        isMulti
        fixedHeight
        name='status'
        placeholder={LL.paymentsPage.statusFilter.placeholder()}
        className={styles.status}
        options={statusOptions.map(s => ({ value: s, label: s }))}
      />
      <RangePicker
        name='date'
        placeholder={[LL.paymentsPage.dateFilter.startDate(), LL.paymentsPage.dateFilter.endDate()]} 
      />
    </Form>
  )
}

// * comment below is redundant, code is fixed, but leaving it for the "future generations to learn"

// * initially the filter was planned as 4 separate vars - from, to, search and status with separate "setValue"
// * and without isEqual it resulted in the following behaviour:
// * when you click on the input and click outside not modifying - "watch" is still triggered
// * meaning setReset is trigered and each filter was rewritten even if it was not changed!
// * useEffect in useGetPaymentList listened to each of the filters, and if you had only string values
// * new data was not fetched cz "string" is a simple type and useEffect did not consider same value as a change
// * old data reset, new not fetched, result - empty payment list and "no data" message!
// * NOTE: status was undefined by default and if was touched at least once - on map it was generated and was always caught by useEffect