import { DocumentTypes } from "apis/contract";

// eslint-disable-next-line max-len
export const EMAIL_REG_EXP =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const DOCUMENT_TYPES: DocumentTypes[] = [
  'agreementLetter' ,
  'contract' ,
  'employeeOfferLetter' ,
  'employeeOnboarding' ,
  'engagementLetter' ,
  'evaluationForms' ,
  'insuranceForms' ,
  'invoice' ,
  'nonDisclosureAgreement' ,
  'other',
  'inventory',
  'writeOff',
  'petroleumProductsJournal',
  'proposal' ,
  'separationAgreement' ,
  'statementOfWork' ,
  'workOrder'
]

// eslint-disable-next-line max-len
export const ALLOWED_FORMATS = ['bmp', 'csv', 'odt', 'doc', 'docx', 'gif', 'htm', 'html', 'jpg', 'jpeg', 'pdf', 'png', 'ppt', 'pptx', 'tiff', 'txt', 'xls', 'xlsx']

export const SITE_KEY = '6LdagAMfAAAAAAYzyJEjXiF-SO9U7zWH887Y57FA';
