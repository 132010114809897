/* eslint-disable react/jsx-no-constructed-context-values */
import React, { ReactNode, useState } from "react";
import { PrmFilter } from "apis/prmApi";
import PaymentsContext from "./PaymentsContext";
// import useGetPaymentList from "./useGetPaymentList";

interface Props {
  children: ReactNode
}

export default function PaymentsProvider({ children }: Props) {
  const [filter, setFilter] = useState<PrmFilter>();
  const [showIncoming, setShowIncoming] = useState(true);
  const [reset, setReset] = useState(false);
  const [abortSignal, setAbortSignal] = useState<AbortSignal>();

  // ? for some reason when reset or filter are updated, useEffects inside useGetPaymentList do not catch it
  // ? maybe cz useGetPaymentList is initialized once with initial values here, but still, strange
  // ? https://www.reddit.com/r/reactjs/comments/17eh3nm/is_useeffect_really_the_best_way_to_handle/
  // const [fetchPayments, updatePayments, payments, loading, isFullyLoaded] = useGetPaymentList();

  return (
    <PaymentsContext.Provider 
      value={{
        abortSignal,
        filter,
        reset,
        showIncoming,
        setAbortSignal,
        setFilter,
        setReset,
        setShowIncoming,
        // payments,
        // loading,
        // isFullyLoaded,
        // fetchPayments,
        // updatePayments,
        // payments: [],
        // loading: false,
        // isFullyLoaded: false,
        // fetchPayments: () => new Promise(resolve => { resolve() }),
        // updatePayments: () => null,
      }}
    >
      { children }
    </PaymentsContext.Provider>
  )
}