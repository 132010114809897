import { Button } from "@sis-lab/web-ui-components"
import React from "react"
import { useI18nContext } from "i18n/i18n-react"
import styles from './EditFooter.module.scss'

interface Props {
  inEdit: boolean
  loading: boolean
  onChange: (inEdit: boolean) => void
  onSumbit: () => void
  onCancel?: () => void
}


export default function EditFooter({ inEdit, loading, onChange, onCancel, onSumbit }: Props) {
  const { LL } = useI18nContext()

  return (
    <div className={styles.footer}>
      <Button
        children={LL.commonButtons.update()}
        type='secondary'
        disabled={loading}
        className={inEdit && styles.hidden || ''}
        onClick={() => onChange(true)}
      />
      <Button
        children={LL.commonButtons.cancel()}
        type='secondary'
        disabled={loading}
        className={!inEdit && styles.hidden || ''}
        onClick={() => {
          onChange(false);
          if (onCancel) onCancel();
        }}
      />
      <Button
        children={LL.paymentsTab.saveButton()}
        className={!inEdit && styles.hidden || ''}
        disabled={loading}
        onClick={onSumbit}
      />
    </div>
  )
}
