/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Icon } from '@sis-lab/web-ui-components';
import { IconsType } from '@sis-lab/web-ui-components/dist/components/Icon/Icon';
import { useI18nContext } from 'i18n/i18n-react';
import Counter from 'components/Counter/Counter';
import { Props, RevokeProps, StampProps, StatusType, UnsignedProps } from './types';
import styles from './Stamp.module.scss';

const getIconName = (status: StatusType): IconsType => {
  switch (status.toLowerCase()) {
    case 'signed': return 'check_circle_outline';
    case 'rejected': return 'close_circle_outline';
    case 'created': return 'post_add';
    case 'invited': return 'person_add_alt' as 'person_add';
    case 'unsigned': return 'access_time' as 'arrow_down'
    default: return 'help_outline';
  }
};

function DefaultStamp({ status, children }: StampProps) {
  const { LL } = useI18nContext()

  return (
    <div className={[styles[status], styles.stamp].join(' ')}>
      <Icon className={styles.icon} name={getIconName(status)} />
      <p>{LL?.stamp[status]()}</p>
      <small>{children}</small>
    </div>
  );
} 

function UnsignedStamp({ status, children, invites, signatures }: UnsignedProps) {
  const { LL } = useI18nContext()

  return (
    <div className={[styles[status], styles.hasCounter, styles.stamp].join(' ')}>
      <Counter invites={invites} signatures={signatures} />
      <p>{LL?.stamp[status]()}</p>
      <small>{children}</small>
    </div>
  );
} 

function RevokableStamp({ status, children, loading, onRevoke, onReInvite }: RevokeProps) {
  const { LL } = useI18nContext()

  return (
    <div className={[styles[status], styles.stamp].join(' ')}>
      <Icon className={styles.icon} name={getIconName(status)} />
      <div className={styles.revoke}>
        <p>{LL?.stamp[status]()}</p>
        <Icon onClick={onReInvite} name={'refresh' as 'loading'} style={{ display: loading ? 'none': undefined }} />
        <Icon onClick={onRevoke} name='close_circle_outline' style={{ display: loading ? 'none': undefined }} />

        {/* SHOW ON LOAD */}
        <div style={{ display: !loading ? 'none': undefined }}/>
        <Icon name='loading' style={{ display: !loading ? 'none': undefined }} />
      </div>
      <small>{children}</small>
    </div>
  );
} 

export default function Stamp(props: Props) {
  if ('signatures' in props) return <UnsignedStamp {...props} />; 
  if ('onRevoke' in props) return <RevokableStamp {...props}  />
  return <DefaultStamp {...props} />
}
