import { Switch } from "@sis-lab/web-ui-components"
import { useI18nContext } from "i18n/i18n-react"
import { Member } from "apis/auth"
import React, { useState } from "react"
import styles from './RightsSelector.module.scss'


const useRightsSelector = (member?: Member, disabled?: boolean) => {
  const [isAdmin, setIsAdmin] = useState(member?.role === 'admin' || member?.role === 'owner')
  const [allowedToView, setAllowedToView] = useState(!!member?.allowedToView)
  const [allowedToSign, setAllowedToSign] = useState(!!member?.allowedToSign)
  const [allowedToUpload, setAllowedToUpload] = useState(!!member?.allowedToUpload)
  const { LL } = useI18nContext()

  const permissions = {
    isAdmin,
    allowedToView: allowedToView || isAdmin,
    allowedToSign: allowedToSign || isAdmin,
    allowedToUpload: allowedToUpload || isAdmin,
  }

  // eslint-disable-next-line react/function-component-definition
  const Selector = () => (
    <div className={styles.rights}>
      <Switch
        checked={isAdmin}
        children={LL.adminDashboard.admin()}
        className={[styles.switch, styles.admin].join(' ')}
        disabled={disabled}
        onChange={() => setIsAdmin(state => !state)}
        tooltip={LL.adminDashboard.adminTooltip()}
      />
      <Switch
        checked={isAdmin || allowedToView}
        children={LL.adminDashboard.allowedToView()}
        className={styles.switch}
        disabled={isAdmin || disabled}
        onChange={() => setAllowedToView(state => !state)}
        tooltip={LL.adminDashboard.allowedToViewTooltip()}
      />
      <Switch
        checked={isAdmin || allowedToSign}
        children={LL.adminDashboard.allowedToSign()}
        className={styles.switch}
        disabled={isAdmin || disabled}
        onChange={() => setAllowedToSign(state => !state)}
        tooltip={LL.adminDashboard.allowedToSignTooltip()}
      />
      <Switch
        checked={isAdmin || allowedToUpload}
        children={LL.adminDashboard.allowedToUpload()}
        className={styles.switch}
        disabled={isAdmin || disabled}
        onChange={() => setAllowedToUpload(state => !state)}
        tooltip={LL.adminDashboard.allowedToUploadTooltip()}
      />
    </div>
  )

  return [Selector, permissions] as const
}

export default useRightsSelector
