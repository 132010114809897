import '@eid-easy/eideasy-widget';
import config from 'config';
import { WidgetSettings } from '../types';

const initWidget = (settings: WidgetSettings, holderId: string) => {
  const widgetHolder = document.getElementById(holderId);

  const eidEasyWidget = document.createElement('eideasy-widget');
  const globalSettings = {
    language: 'en',
    selectedMethod: 'smartid',
    email: settings.email,
    countryCode: settings.countryCode,
    clientId: config.eIdEasy.clientId, 
    redirectUri: window.location.origin,
    enabledMethods: { identification: 'all' },
    apiEndpoints: {
      identityStart: () => `${config.authApiUrl}/eideasy/identity/start`, 
      identityFinish: () => `${config.authApiUrl}/eideasy/identity/finish`,
    },
    onSuccess: ({data}: any) => settings.onSuccess(data),
    onFail: settings.onFailure,
  };

  // Thanx for brilliant type support by eidWidget
  Object.entries(globalSettings).forEach(([key, value]) => { (eidEasyWidget as any)[key] = value });

  // root?.appendChild(widgetHolder);
  widgetHolder?.appendChild(eidEasyWidget);

  return eidEasyWidget
}; 

export default initWidget;
