import { decodeJWS } from 'utils';
import { ISignature, DocumentTypes, IIdentity, ICreator } from 'apis/contract';
import { IDecodedJWT } from 'types';
import { StatusType } from 'components/Stamp/types';

export interface SingatureTableData {
  /** Document Title */
  title: string
  /** Document type */
  type: DocumentTypes,
  uploaded: string,
  status: StatusType
  /** Document fingerprint */
  fingerprint: string
  id: string
  creator: ICreator
}

export const convertSignature = (signature: ISignature): SingatureTableData => {
  const { document } = decodeJWS(signature.jws) as unknown as IDecodedJWT;

  return {
    creator: signature.creator,
    id: signature.id,
    status: signature.status === 'accept' ? 'signed' : 'rejected',
    title: document.title,
    type: document.type,
    uploaded: signature.createdAt,
    fingerprint: signature.documentFingerprint || '',
  };
};