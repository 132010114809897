import { RcFile, Upload } from "@sis-lab/web-ui-components";
import { useI18nContext } from "i18n/i18n-react";
import React from "react";
import { ApplicationState } from "modules";
import { useSelector } from "react-redux";
import { DocumentInfo } from "../../../types";
import useFileUpload from "./useFileUpload";

interface Props {
  currentList: DocumentInfo[]
  afterUpload: (documentList: DocumentInfo[]) => void
};

export default function DocumentUpload({ currentList, afterUpload }: Props) {
  const saveForPreview = useSelector((state: ApplicationState) => state.authentication.metadata?.preferences.saveForPreview);
  const [uploadFiles, error, loading] = useFileUpload(currentList, !!saveForPreview);
  const { LL } = useI18nContext();

  // * IMPORTANT:
  // * the idea is to process a batch of files at once (checkHashes at API, etc)
  // * but since beforeUpload is triggered on each of N files, the API is called N times more,
  // * so uploadFiles is called only for the first uploaded file.
  // * ofc, we could process by 1 file, but there are 2 downsides: 
  // *    1. afterUpload could malfunction, since state is not immediately updated
  // *    1. for each file API and DB will be triggered separately, means N requests
  const beforeUpload = async (file: RcFile, files: RcFile[]) => {
    const fileIndex = files.indexOf(file);
    if (fileIndex === 0) {
      const documentList = await uploadFiles(files);
      if (documentList) afterUpload(documentList);
    }
    return false; // to prevent default form submit
  };

  return (
    <Upload
      name='file'
      icon={loading ? 'loading' : 'find'}
      multiple
      beforeUpload={beforeUpload}
      errorMessage={error}
      text={loading ? LL.uploadPage.selectLoading() : LL.commonFields.upload.prompt()}
      helpText={LL.commonFields.upload.helpText()}
    />
  )
}
