/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Icon, TimelineItem } from '@sis-lab/web-ui-components';
import { IconsType } from '@sis-lab/web-ui-components/dist/components/Icon/Icon';
import { transformDate } from 'utils';
import { PrmStatus, PrmStatusObject } from 'apis/prmApi';
import { useI18nContext } from 'i18n/i18n-react';
import StatusDetailsModal from '../StatusDetailsModal/StatusDetailsModal';
import styles from './PaymentEvent.module.scss';

interface Props {
  status: PrmStatusObject;
}

const statusToIconMapper: { [key in PrmStatus]: IconsType } = {
  created: 'add',
  invited: 'mail_outline' as 'arrow_down',
  sent: 'mail_outline' as 'arrow_down',
  delivered: 'check_circle' as 'arrow_down',
  responded_error: 'done_all' as 'arrow_down',
  responded_ok: 'done_all' as 'arrow_down',
  responded_warning: 'done_all' as 'arrow_down',
  assigned: 'mail_outline' as 'arrow_down',
  started: 'schedule_outline',
  canceled: 'close_circle_outline',
  rejected: 'close_circle_outline',
  received: 'schedule_outline',
  paid: 'check_circle',
  accepted: 'check_circle_outline',
  log: 'construction' as 'troubleshoot',
}; 

export default function PaymentEvent({ status: { status, timestamp, actor, details } }: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const { LL } = useI18nContext()

  const iconClassname = status === 'log' ? styles[`${status}-${details.status}`] : styles[status]
  

  return (
    <TimelineItem
      dot={<Icon className={[styles.dot, iconClassname].join(' ')} name={statusToIconMapper[status]} />}
      className={styles.item}
      title={status === 'log' ? [status, details.module, details.action].filter(e => e).join(" - ") : LL.paymentStatus[status]()}
      subtitle={actor}
    >
      <div className={styles.stamp}>
        <p>{transformDate(timestamp).substring(0,16)}</p>
        <Icon className={styles.icon} onClick={() => setIsVisible(true)} name='info_outline' />
      </div>
      <StatusDetailsModal
        details={details}
        visible={isVisible}
        onClose={() => setIsVisible(false)}
      />
    </TimelineItem>
  );
}
