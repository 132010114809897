import { PRM } from "apis/prmApi";
import { createContext } from "react";

export type PrmContextType = {
  prm?: PRM
  loading: boolean
  updatePrm: (newPrm?: PRM) => void
}

const PrmContext = createContext<PrmContextType>({ updatePrm: () => null, loading: false });

export default PrmContext
