import { Button } from "@sis-lab/web-ui-components";
import { useI18nContext } from "i18n/i18n-react";
import React, { useState } from "react";
import localCompanyInfo from "utils/localStorage/localCompanyInfo";
import PageHeader from "components/PageHeader/PageHeader";
import MembersTable from "./components/MembersTable/MembersTable";
import NewMemberModal from "./components/NewMemberModal/NewMemberModal";
import styles from './AdminDashboard.module.scss'


export default function AdminDashboard() {
  const [newOpen, setNewOpen] = useState(false);
  const { LL } = useI18nContext()

  const companyName = localCompanyInfo.getCompanyName()
  
  return (
    <div className={styles.page}>
      <PageHeader title={companyName || LL.adminDashboard.title()}>
        <Button
          children={LL.adminDashboard.createMemberButton()}
          suffix='add'
          mobileIcon='add'
          onClick={() => setNewOpen(true)}
        />
      </PageHeader>
      <MembersTable />
      <NewMemberModal
        open={newOpen}
        onCancel={() => setNewOpen(false)}
      />
    </div>
  )
}
