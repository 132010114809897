import React from 'react';
import { Button, useBreakpoints } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import viewOnJWT from '../../../images/viewOnJWT.svg';
import styles from './ModalFooter.module.scss';

interface Props {
  onNavigate: () => void
  jws?: string
  certificate?: string
}

export default function ModalFooter({ onNavigate, jws, certificate }: Props) {
  const { mobile } = useBreakpoints() || {};
  const { LL } = useI18nContext()

  LL.documentsPage.invitePlaceholder()

  return (
    <div className={styles.footer}>
      <Button
        onClick={onNavigate}
        className={styles.jwtWrapper}
        contentClassName={styles.jwtButton}
      >
        <img src={viewOnJWT} alt='verify with JWT.IO' />
        <p>{LL.signautureModal.viewOnJWT()}</p>
      </Button>
      <Button
        children='JWS'
        copyText={jws}
        size={mobile ? 'medium' :'large'}
        suffix='content_copy'
        type='secondary'
      />
      <Button
        size={mobile ? 'medium' :'large'}
        children={LL.common.certificate()}
        copyText={certificate}
        suffix='content_copy'
        type='secondary'
      />
    </div>
  );
}
