import { UserDetailed } from "apis/auth"
import prmApi from "apis/prmApi"
import { ApplicationState } from "modules"
import PrmContext from "pages/PaymentPage/components/PrmContext/PrmContext"
import { useContext, useState } from "react"
import { useSelector } from "react-redux"
import { errorParser } from "utils/requests/errorParser"


const useRespondToPrm = (prmId: string) => {
  const [loading, setLoading] = useState(false)
  const { prm, updatePrm } = useContext(PrmContext)
  const user = useSelector<ApplicationState>(state => state.authentication?.user) as UserDetailed


  const respondToPrm = async (response: 'accept' | 'reject', notes?: string) => {
    setLoading(true)
    prmApi.responseToPrm(prmId, { status: response, notes })
      .then(({ data: updatedStatus }) => {
        if (!prm) return
        updatePrm({ 
          ...prm,
          status: updatedStatus.status,
          payer: {
            name: user.firstName + user.lastName,
            email: user.email,
            id: user.sub
          }, 
        })
      })
      // eslint-disable-next-line no-alert
      .catch(e => errorParser(e, err => alert(err.response?.statusText || 'unknown')))
      .finally(() => setLoading(false))
  }

  return [respondToPrm, loading] as const
}

export default useRespondToPrm
