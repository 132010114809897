import axios, { AxiosError } from 'axios';
import config from '../../config';

export const instance = axios.create({
  baseURL: config.cryptoapiUrl,
  headers: { 'Content-Type': 'application/json' },
});

instance.interceptors.response.use(value => value, (error: AxiosError) => {
  // LOGOUT IF FORBIDDEN
  if (error.response?.status === 403 || error.response?.status === 401) {
    localStorage.removeItem('refreshToken');
    window.location.reload();
  }

  throw error
})

export const setCryptoAccessToken = (token?: string) => {
  if (token) {
    instance.defaults.headers.common['access-token'] = token;
  } else {
    delete instance.defaults.headers.common['access-token'];
  }
};

// const decodeAPIUrl = 'https://crypto.sis.lt/api/v1/certificates/decode';
// const decodeInstance = axios.create({
//   headers: { 'Content-Type': 'application/json', 'access-token': 'ZHRrdTJlZnlQJF4mTDlSUF41Tko0IzNvJjgkVnZX' },
// });
