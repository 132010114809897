import { message } from '@sis-lab/web-ui-components'
// import { useI18nContext } from 'i18n/i18n-react';
import React, { useEffect } from 'react'

function OfflineWatcher() {
  // const { LL } = useI18nContext()
  const key = 'goneOfflineMessage'
  useEffect(() => {
    window.addEventListener('offline', () => message.info({
      key,
      duration: 0,
      content: 'Offline!'
    }));
    window.addEventListener('online', () => {
      message.destroy(key);
      message.success('Online!', 2);
    });

  }, [])
  
  return (
    <div id='offline-watcher' />
  )
}

export default OfflineWatcher