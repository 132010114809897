import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Form,
  Input,
  Password,
  useForm,
} from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import axios from 'axios';
import { ApplicationState } from 'modules';
import { passwordValidator, transformDate } from 'utils';
import auth from 'apis/auth';
import styles from './PasswordCard.module.scss';


export default function PasswordCard() {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<string>()
  const { LL, locale } = useI18nContext()
  const user = useSelector((state: ApplicationState) => state.authentication.user)
  const form = useForm();

  const updatePassword = async () => {
    form.clearErrors();

    const currentPassword = form.getValues('currentPassword');
    const newPassword = form.getValues('newPassword');

    if (!currentPassword || !newPassword) {
      form.setError('currentPassword', {});
      form.setError('newPassword', { message: LL.errors.common.resetPasswordEmpty() });
    } else if (!passwordValidator.validate(newPassword)) {
      form.setError('newPassword', { message: LL.errors.common.passwordWeak() });
    };

    await new Promise(resolve => setTimeout(resolve, 10));
    if (Object.keys(form.formState.errors).length !== 0) return;

    try {
      setIsLoading(true);
      await auth.changePassword(currentPassword, newPassword)
      setMessage('Password changed succesfully!')
      // eslint-disable-next-line no-restricted-globals
      location.reload()
    } catch (e) {
      if (axios.isAxiosError(e)) {
        form.setError('newPassword', { message: e.message })
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className={styles.passwordCard} size='medium'>
      <CardHeader title={LL.passwordCard.title()}/>
      <Form size='medium' className={styles.inputLayout} formHook={form} onSubmit={() => null}>
        <Input
          disabled
          name='lastChanged'
          // FIIIX BY UPDATING UI-LIBRARY TO HAVE VALUE PROP
          // FIIIXED!!!
          // {...{ 
          //   value: locale && user?.updatedAt ? 
          //   LL.passwordCard.lastChanged({ date: transformDate(user?.updatedAt) }) :
          //   LL.passwordCard.lastChangedOnSignup()
          // }}
          value={locale && user?.updatedAt ? 
            LL.passwordCard.lastChanged({ date: transformDate(user?.updatedAt) }) :
            LL.passwordCard.lastChangedOnSignup()}
        />
        <Password
          name='currentPassword'
          title={LL.commonFields.password.title()}
          placeholder={LL.commonFields.password.placeholder()}
          // rules={{ required: true }}
        />
        <Password
          name='newPassword'
          title={LL.commonFields.passwordRepeat.title()}
          placeholder={LL.commonFields.passwordRepeat.placeholder()}
          helpText={ message || LL.commonFields.passwordRepeat.helpText()}
          // rules={{ required: true }}
        />
      </Form>
      <CardFooter>
        <Button
          type='secondary'
          children={LL.passwordCard.changePasswordButton()}
          onClick={updatePassword}
          disabled={isLoading}
        />
      </CardFooter>
    </Card>
  );
}
