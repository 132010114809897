import React, { useEffect, useState } from "react";

import { Card, CardHeader, Form, FormSelect, Input, Skeleton, useForm } from "@sis-lab/web-ui-components";
import { useI18nContext } from "i18n/i18n-react";
import EditFooter from "../EditFooter/EditFooter";
import useRtpConfig from "./useRtpConfig";

import styles from './RtpConfigCard.module.scss'


export default function RtpConfigCard () {
  const [rtpConfig, loading, updateRtpConfig] = useRtpConfig();
  const [inEdit, setInEdit] = useState(false)
  const { LL } = useI18nContext()
  const form = useForm()
  
  const showSkeleton = !rtpConfig && loading && !form.formState.isDirty
  
  useEffect(() => {
    form.clearErrors()
    form.setValue('partyId', rtpConfig?.party.id)
    form.setValue('partyName', rtpConfig?.party.name)
    form.setValue('partyType', { value: rtpConfig?.party.type, label: rtpConfig?.party.type })
    form.setValue('iban', rtpConfig?.bank.iban)
    form.setValue('swift', rtpConfig?.bank.swift)
  }, [rtpConfig, inEdit]);

  const startConfigUpdate = async () => {
    form.clearErrors();
    const formValues = form.getValues();
    const errors: { field: string, message: string }[] = [];

    if (!formValues.partyId) errors.push({ field: 'partyId', message: 'Please provide Id'})
    if (!formValues.partyName) errors.push({ field: 'partyName', message: 'Please provide Name'})
    if (!formValues.iban) errors.push({ field: 'iban', message: 'Please provide IBAN code'})
    if (!formValues.swift) errors.push({ field: 'swift', message: 'Please provide SWIFT code'})

    if (errors.length > 0) {
      errors.forEach(({ field, message }) => form.setError(field, { message }))
      return;
    };

    await updateRtpConfig({
      party: {
        id: formValues.partyId,
        name: formValues.partyName,
        type: formValues.partyType.value,
      },
      bank: {
        swift: formValues.swift,
        iban: formValues.iban,
      }
    });
    setInEdit(false);
  }

  return (
    <Card>
      <CardHeader title={LL.paymentsTab.rtpTitle()} />
      {loading && <Skeleton count={5} style={{ marginBottom: '20px' }} />}
      <Form formHook={form} disabled={!inEdit || loading} className={[styles.cardBody, loading && styles.hidden].join(' ')}>
        <Input
          name='partyId'
          title={LL.paymentsTab.partyId.title()}
          tooltip='to do'
        />
        <Input
          name='partyName'
          title={LL.paymentsTab.partyName.title()}
          tooltip='to do'
        />
        <FormSelect
          name='partyType'
          title={LL.paymentsTab.partyType.title()}
          tooltip='to do'
          options={[{ value: 'BCID', label: 'BCID' }, { value: 'PCID', label: 'PCID' }]}
          // defaultValue={{ value: 'BCID', label: 'BCID' }}
          isDisabled={!inEdit || loading}
        />
        <Input
          name='iban'
          title={LL.paymentsTab.iban.title()}
          tooltip='to do'
        />
        <Input
          name='swift'
          title={LL.paymentsTab.swift.title()}
          tooltip='to do'
        />
        <EditFooter
          inEdit={inEdit}
          loading={loading}
          onChange={isInEdit => setInEdit(isInEdit)}
          onSumbit={startConfigUpdate}
        />
      </Form>
    </Card>
  )
}
