/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { Button, Icon } from "@sis-lab/web-ui-components";
import { DocumentInfo } from "../../../types";
import styles from './ListRow.module.scss'

interface Props {
  document: DocumentInfo
  selected: boolean
  loading?: boolean
  onSelect: (doc: DocumentInfo) => void
  onDelete: (doc: DocumentInfo) => void
}


export default function ListRow({ document, selected, loading, onSelect, onDelete }: Props) {
  const { completed, error} = document

  return (
    <div>
      <div
        onClick={() => onSelect(document)}
        className={[
          styles.row,
          selected && styles.selected,
          !!error && styles.error,
          completed && styles.completed
        ].join(' ')}
      >
        <div className={styles.titleWrap}>
          <Icon name={selected ? 'check' as 'arrow_down' :'document'} />
          <p>{document.title}</p>
        </div>
        {completed && <Icon name='check_circle_outline' />}
        {!completed && (
          <Button
            onClick={(e) => { onDelete(document); e.stopPropagation() } }
            icon={loading ? 'loading' : 'delete_outline'}
            className={styles.delete}
            disabled={loading}
            type='ghost'
            state='warning'
            size='small'
          />
        )}
      </div>
      {error && <small className={styles.errorMsg}>{error}</small>}
    </div>
  )
}