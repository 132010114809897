import { Form, Input, useForm } from "@sis-lab/web-ui-components"
import React, { useEffect, useState } from "react"
import { KevinConfig } from "apis/prmApi"
import { useI18nContext } from "i18n/i18n-react"
import EditFooter from "../../EditFooter/EditFooter"
import styles from './ProviderForm.module.scss'

interface Props {
  kevinConfig?: KevinConfig
  updateKevinConfig: (newConfig: KevinConfig) => Promise<void>
  hidden: boolean
  loading: boolean
}


export default function KevinForm({ kevinConfig, updateKevinConfig, hidden, loading }: Props) {
  const [inEdit, setInEdit] = useState(false);
  const { LL } = useI18nContext();
  const form = useForm();

  useEffect(() => {
    form.clearErrors()
    form.setValue('clientId', kevinConfig?.clientId)
    form.setValue('clientSecret', kevinConfig?.clientSecret)
    form.setValue('iban', kevinConfig?.iban)
    form.setValue('name', kevinConfig?.name)
    form.setValue('redirectUrl',kevinConfig?.redirectUrl)
  }, [kevinConfig, inEdit]);

  const startConfigUpdate = async () => {
    form.clearErrors()
    const newConfig = form.getValues() as KevinConfig
    const errors: { field: string, message: string }[] = []

    if (!newConfig.clientId) errors.push({ field: 'clientId', message: 'Please provide Client ID'})
    if (!newConfig.clientSecret) errors.push({ field: 'clientSecret', message: 'Please provide Client Secret'})
    if (!newConfig.iban) errors.push({ field: 'iban', message: 'Please provide IBAN'})
    if (!newConfig.name) errors.push({ field: 'name', message: 'Please provide Name'})
    if (!newConfig.redirectUrl) errors.push({ field: 'redirectUrl', message: 'Please provide Redirect Url'})

    if (errors.length > 0) {
      errors.forEach(({ field, message }) => form.setError(field, { message }))
      return
    }

    await updateKevinConfig(newConfig)
    setInEdit(false)
  }

  return (
    <Form formHook={form} disabled={!inEdit || loading} className={[styles.form, hidden && styles.hidden].join(' ')}>
      <Input
        name='clientId'
        title={LL.paymentsTab.clientId.title()}
        tooltip='To do'
      />
      <Input
        name='clientSecret'
        title={LL.paymentsTab.clientSecret.title()}
        tooltip='To do'
      />
      <Input
        name='iban'
        title={LL.paymentsTab.iban.title()}
        tooltip='To do'
        defaultValue={kevinConfig?.iban}
      />
      <Input
        name='name'
        title={LL.paymentsTab.name.title()}
        tooltip='To do'
        defaultValue={kevinConfig?.name}
      />
      <Input
        name='redirectUrl'
        title={LL.paymentsTab.redirectUrl.title()}
        tooltip='To do'
        defaultValue={kevinConfig?.redirectUrl}
      />
      <EditFooter
        inEdit={inEdit}
        loading={loading}
        onChange={isInEdit => setInEdit(isInEdit)}
        onSumbit={startConfigUpdate}
      />
    </Form>
  )
}
