import React from "react";
import PeppolConfigCard from "./components/PeppolConfigCard/PeppolConfigCard";
import PeppolMessagesCard from "./components/PeppolMessagesCard/PeppolMessagesCard";
import styles from './PeppolTab.module.scss'


export default function PaypolTab() {
  return (
    <div className={styles.peppolTab}>
      <PeppolConfigCard />
      <PeppolMessagesCard />
    </div>
  )
}
