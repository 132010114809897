
import React from 'react';
import { Card, CardHeader, Radio, RadioSelector, useForm, Locale } from '@sis-lab/web-ui-components';
import { connect, useDispatch } from 'react-redux';
import { loadLocaleAsync } from 'i18n/i18n-util.async';
import { useI18nContext } from 'i18n/i18n-react';
import { ApplicationState } from 'modules';
import auth, { UserMetadata } from 'apis/auth';
import { setMetadataAction } from 'modules/authentication/actions';
import styles from './LangCard.module.scss';

interface Props {
  metadata?: UserMetadata
}

const mapStateToProps = (state: ApplicationState) => ({
  metadata: state.authentication.metadata
});


function LangCard({ metadata }: Props) {
  const { LL, locale, setLocale } = useI18nContext()
  const dispatch = useDispatch()
  const form = useForm();


  const handleLangChange = async (langCode: Locale) => {
    if (!metadata) return

    localStorage.setItem('lang', langCode)
    await loadLocaleAsync(langCode);
    setLocale(langCode)
    
    const newPreferences: UserMetadata = { ...metadata, preferences: { ...metadata.preferences, language: langCode } }
    setMetadataAction(newPreferences)(dispatch)
    await auth.updateUserMetadata(newPreferences)
  }
  
  return (
    <Card  className={styles.lang}>
      <CardHeader title={LL.langCard.title()} />
      <RadioSelector
        name='lang'
        formHook={form}
        defaultValue={locale}
        onSelect={val => handleLangChange(val as Locale)}
      >
        <Radio value='en' label={LL.langCard.enLanguage()} descr='(English)' />
        <Radio value='lt' label={LL.langCard.ltLanguage()} descr='(Lietuviškai)' />
        <Radio value='ru' label={LL.langCard.ruLanguage()} descr='(На русском)' />
        <Radio value='de' label={LL.langCard.deLanguage()} descr='(auf Deutsch)' />
        <Radio value='lv' label={LL.langCard.lvLanguage()} descr='(Latviešu)' />
        <Radio value='ee' label={LL.langCard.eeLanguage()} descr='(Eesti keel)' />
     </RadioSelector>
   </Card>
  )
}

export default connect(mapStateToProps)(LangCard);
