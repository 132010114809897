import { Avatar } from '@sis-lab/web-ui-components';
import React from 'react';

import { User } from 'apis/auth';
import styles from './UserRow.module.scss';

type Props = {
  user: User;
  onClick: () => void
}

export default function UserRow({ user, onClick }: Props) {
  return (
    <button type='button' onClick={onClick} className={styles.row}>
      <Avatar name={user.sub} width={34} />
      <div className={styles.title}>
        <h3>{`${user.firstName} ${user.lastName}`}</h3>
        <p>{user.sub}</p>
      </div>
    </button>
  );
}
