import { objectToQuery } from "utils";
import { instance } from './instance';
import { IDocumentsSearch, MyDocumentsResponse, MySignaturesResponse } from "./types";

export const getOrganizationDocuments = (searchParams: IDocumentsSearch, signal?: AbortSignal) => (
  instance.get<MyDocumentsResponse>(`/organizations/documents${objectToQuery(searchParams)}`, { signal })
)

export const getOrganizationSignatures = (searchParams: IDocumentsSearch, signal?: AbortSignal) => (
  instance.get<MySignaturesResponse>(`/organizations/signatures${objectToQuery(searchParams)}`, { signal })
)

export const getSealDocuments = (sealSub: string, searchParams: IDocumentsSearch, signal?: AbortSignal) => (
  instance.get<MyDocumentsResponse>(`/organizations/seals/${sealSub}/documents${objectToQuery(searchParams)}`, { signal })
)

export const getSealSignatures = (sealSub: string, searchParams: IDocumentsSearch, signal?: AbortSignal) => (
  instance.get<MySignaturesResponse>(`/organizations/seals/${sealSub}/signatures${objectToQuery(searchParams)}`, { signal })
)
