/* eslint-disable no-else-return */
import React, { useCallback, useRef, useState } from "react";
import { FormSelectAsync, FormSelectProps , OptionType } from "@sis-lab/web-ui-components";
import { useI18nContext } from "i18n/i18n-react";
import { DOCUMENT_TYPES } from "utils/consts";
import contractApi from "apis/contract";
import { localeContains } from "utils";


export default function DocumentTypeSelector<T extends boolean>({ name, ...rest  }: FormSelectProps<T>) {
  const { LL } = useI18nContext();
  const [types, setTypes] = useState<OptionType[]>();
  const abortControllerRef = useRef(new AbortController());

  const loadDocumentTypes = useCallback(async (search: string) => {
    abortControllerRef.current.abort()
    abortControllerRef.current = new AbortController()
    if (types) {
      return {
        options: types.filter(option => localeContains(option.label as string, search)),
        hasMore: false
      };
    } else {
      const { data } = await contractApi.getDocumentTypes(abortControllerRef.current.signal);

      const rawTypes = data ? Object.keys(data).filter(type => data[type]) : DOCUMENT_TYPES;
      const options = rawTypes
          .map(type => ({ value: type, label: LL.documentTypes[type as 'workOrder']() || type }))
          .sort((a, b) => a.label.localeCompare(b.label))
      setTypes(options);

      return {
        options,
        hasMore: false
      };
    }
  }, [abortControllerRef, types])

  return (
    <FormSelectAsync
      name={name}
      isClearable
      isCreatable={false}
      loadOptions={loadDocumentTypes}
      placeholder={LL.commonFields.documentType.placeholder()}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  )
}
