import { ISignature } from "apis/contract";

export enum ActionTypes {
  CLEAR_DOCUMENT_DOCUMENT_SIGNATURE = '@@signatures/REQUEST_GET_DOCUMENT_SIGNATURE',
  // Get single signature
  REQUEST_GET_DOCUMENT_SIGNATURE = '@@signatures/REQUEST_GET_DOCUMENT_SIGNATURE',
  SUCCESS_GET_DOCUMENT_SIGNATURE = '@@signatures/SUCCESS_GET_DOCUMENT_SIGNATURE',
  ERROR_GET_DOCUMENT_SIGNATURE = '@@signatures/ERROR_GET_DOCUMENT_SIGNATURE',
  // Get all signatures by document id
  REQUEST_GET_DOCUMENT_SIGNATURES = '@@signatures/REQUEST_GET_DOCUMENT_SIGNATURES',
  SUCCESS_GET_DOCUMENT_SIGNATURES = '@@signatures/SUCCESS_GET_DOCUMENT_SIGNATURES',
  ERROR_GET_DOCUMENT_SIGNATURES = '@@signatures/ERROR_GET_DOCUMENT_SIGNATURES',
}

export interface SignaturesAction {
  type: ActionTypes,
  loading?: boolean,
  signature?: ISignature,
  signatures?: ISignature[],
  bookmark?: string,
  pageSize?: number,
  count?: number,
  error?: Error
}

export interface SignaturesState {
  readonly loading: boolean;
  readonly signature?: ISignature | undefined;
  readonly signatures?: ISignature[] | undefined;
  readonly error?: Error;
  readonly bookmark?: string;
  readonly pageSize?: number;
  readonly count?: number;
}
