import axios from 'axios';
import config from '../../config';

export const instance = axios.create({
  baseURL: config.xmlApi.url,
  headers: {
    'Content-Type': 'multipart/form-data',
  }
});

export const setAccessToken = (token?: string) => {
  if (token) {
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete instance.defaults.headers.common.Authorization;
  }
};
