import { EMAIL_REG_EXP } from "utils/consts";


// Add transaltion
export default function inviteeValidation(invitee: string, owner: string, invitees: string[], existingUsers: string[]) {
  if (!invitee.match(EMAIL_REG_EXP)) return `"${invitee}" is invalid email`
  if (invitees.includes(invitee)) return `"${invitee}" is already entered`
  if (existingUsers.includes(invitee)) return `"${invitee}" is already invited`
  if (invitee === owner) return `"${invitee}" is the owner`
  return null
}
