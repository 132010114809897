import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Modal,
  Avatar,
  Form,
  Input,
  Divider,
  useForm,
} from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';

import { transformDate } from 'utils';
import { ISignatureDetailed } from 'apis/contract';
import ModalFooter from './components/ModalFooter';
import styles from './SignatureDetailsModal.module.scss';

interface SignatureDetailsModalProps {
  visible: boolean;
  onClose: () => void;
  signature?: ISignatureDetailed;
  fingerprint?: string;
  isOwner?: boolean
}


export default function SignatureDetailsModal({ visible, signature, onClose, isOwner, fingerprint }: SignatureDetailsModalProps) {
  const { LL } = useI18nContext()
  const formHook = useForm();
  const history = useHistory();
  const certificates = signature?.seal ? signature?.seal?.certificates : signature?.creator.certificates || {}

  const navigateToJWTIO = () => {
    if (signature) {
      const jwtIO = `https://jwt.io/#debugger-io?token=${
        signature.jws
      }&publicKey=${encodeURIComponent(Object.values(certificates)[Object.keys(certificates).length - 1])}`;
      setTimeout(() => window.open(jwtIO, '_blank', 'noopener,noreferrer'));
    }
  };

  if (!signature) return <div/>
  return (
    <Modal
      width={720}
      title={LL.signautureModal.title()}
      open={visible}
      onCancel={onClose}
      footer={
        <ModalFooter
          certificate={Object.values(certificates)[Object.keys(certificates).length - 1]}
          jws={signature.jws}
          onNavigate={navigateToJWTIO}
        />
      }
    >
      <Form className={styles.formContainer} formHook={formHook}>
        <div className={styles.generalInfoRow}>
          <Avatar width={80} name={signature.creator.name} />
          <div className={styles.generalInfoValues}>
            <Input
              disabled
              name='name'
              title={LL.commonFields.fullName.title()}
              defaultValue={signature.creator.name}
              descr={isOwner ? <div className={styles.ownerTag}>{LL.signautureModal.ownerTag()}</div> : undefined}
            />
            <Input
              disabled
              name='status'
              title={LL.commonFields.status.title()}
              styleType={signature.status === 'accept' ? 'positive' : 'negative'}
              defaultValue={LL.signautureModal[signature.status]()}
              prefix={signature.status === 'accept' ? 'check_circle_outline' : 'close_circle_outline'}
            />
            <Input
              disabled
              name='timestamp'
              title={LL.commonFields.timeStamp.title()}
              defaultValue={transformDate(signature.createdAt)}
            />
            <Input
              disabled
              name='notes'
              title={LL.commonFields.notes.title()}
              defaultValue={signature.notes || '—'}
            />
          </div>
        </div>
        <Divider size='small' />
        <div className={styles.companyValues}>
          <Input
            disabled
            name='position'
            title={LL.commonFields.position.title()}
            wrapperClassName={!signature.creator.parentSub ? styles.hidden : ''}
            defaultValue={signature.creator.position}
          />
          <Input
            disabled
            name='email'
            title={LL.commonFields.email.title()}
            defaultValue={signature.creator.email}
          />
          <Input
            disabled
            name='company_name'
            title={LL.commonFields.companyName.title()}
            wrapperClassName={!signature.creator.parentSub? styles.hidden : ''}
            defaultValue={signature.creator.organizationName}
          />
          <Input
            disabled
            name='company_id'
            title={LL.commonFields.companyId.title()}
            wrapperClassName={!signature.creator.parentSub ? styles.hidden : ''}
            defaultValue={signature.creator?.registrationNumber}
          />
        </div>
        <Divider size='small' />
        <Input
          disabled
          name='fingerprint'
          defaultValue={fingerprint}
          onSuffix={() => history.push(`/documents/${fingerprint}`)}
          suffix={'open_in_new' as 'arrow_down'}
          title={LL.commonFields.fingerprint.title()}
          tooltip={LL.commonFields.fingerprint.tooltip()}
          wrapperClassName={fingerprint ? '' : styles.hidden}
        />
        <div className={styles.certificateValues}>
          <Input
            disabled
            name='certificate_id'
            title={LL.commonFields.certificateFingerprint.title()}
            tooltip={LL.commonFields.certificateFingerprint.tooltip()}
            defaultValue={signature.creator.certificateId}
          />
          <Input
            disabled
            name='ip_address'
            title={LL.signautureModal.ipAddress()}
            defaultValue={signature.ipAddress}
          />
          <Input
            disabled
            name='coordinates'
            title={LL.signautureModal.coordinates()}
            defaultValue={
              signature.latitude && signature.longitude ? `${signature.latitude}, ${signature.longitude}` : '—'
            }
          />
        </div>
      </Form>
    </Modal>
  );
}
