import React, { useState } from 'react'
import { useForm, Input, Icon } from "@sis-lab/web-ui-components"
import eDeliveryApi, { MessageStatus } from 'apis/eDelivery'
import { useI18nContext } from 'i18n/i18n-react'
import StatusModal from './StatusModal/StatusModal'
import styles from './CheckStatusField.module.scss'


export default function CheckStatusField() {
  const [expanded, setExpanded] = useState(false)
  const [messageStatus, setMessageStatus] = useState<MessageStatus>()
  const [loading, setLoading] = useState(false)
  const { LL } = useI18nContext()
  const form = useForm()

  const onLookup = async () => {
    if (!expanded) {
      setExpanded(true)
      return
    }

    setLoading(true)
    eDeliveryApi.getMessageStatus(form.getValues('id'))
      .then(({ data }) => setMessageStatus(data))
      // eslint-disable-next-line no-alert
      .catch(e => alert(e))
      .finally(() => setLoading(false) )
  }

  const onIcon = () => setExpanded(false)

  return (
    <div className={styles.statusField}>
      <Input
        className={styles.input}
        descr={!loading && <Icon onClick={onIcon} name='close' className={styles.collapse} />}
        wrapperClassName={[styles.inputWrapper, expanded && styles.wrapperExpanded].join(' ')}
        suffix={loading ? 'loading' : 'search'}
        name='id'
        formHook={form}
        placeholder={LL.peppolTab.checkStatus.placeholder()}
        onSuffix={onLookup} 
        disabled={loading}
      /> 
      <StatusModal status={messageStatus} onClose={() => setMessageStatus(undefined)} />
    </div>
  )
}
