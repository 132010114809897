import { AxiosResponse } from "axios";


export default function extractDocumentInfo(response: AxiosResponse<ArrayBuffer>) {
  const prmDocumentAB = response.data;
  const contentType: string = response.headers['content-type'].split(';')[0];
  const blob = new Blob([prmDocumentAB], { type: contentType });
  const documentType = response.headers['document-type'];
  const match = response.headers['content-disposition'].match(
    /filename\*?=['"]?(?:UTF-8(?:'|'')'')?([^;\r\n"']*)['"]?;?/i
  );
  const fileName = (match && match.length > 1) ? decodeURIComponent(match[1]) : ''

  return [blob, fileName, contentType, documentType] as const;
}
