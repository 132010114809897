import { instance } from "../instance";
import { KevinConfig, MontonioConfig, PaymentProvider, RtpConfig } from "./types";

export const getKevinConfig = () => (
  instance.get<KevinConfig>('/account/config?provider=KEVIN')
)

export const getRtpConfig = () => (
  instance.get<RtpConfig>('/account/config?provider=RTP')
)

export const getMontonioConfig = () => (
  instance.get<MontonioConfig>('/account/config?provider=MONTONIO')
)
export const updateKevinConfig = (newConfig: KevinConfig) => (
  instance.put(`/account/config?provider=KEVIN`, newConfig)
)

export const updateRtpConfig = (newConfig: RtpConfig) => (
  instance.put(`/account/config?provider=RTP`, newConfig)
)

export const updateMontonioConfig = (newConfig: MontonioConfig) => (
  instance.put(`/account/config?provider=MONTONIO`, newConfig)
)

export const deletePaymentConfig = (provider: PaymentProvider) => (
  instance.put(`/account/config?${provider}`)
)
