import PageHeader from "components/PageHeader/PageHeader";
import React, { useContext } from "react";
import PrmContext from "../PrmContext/PrmContext";


export default function PaymentPageHeader() {
  const { prm } = useContext(PrmContext)

  return (
    <PageHeader title={prm?.orderId || ''} />
  )
}