/* eslint-disable no-console */
/* eslint-disable no-alert */
/* eslint-disable no-await-in-loop */
import React, { useEffect } from 'react';
import { Modal, QrCode, message } from '@sis-lab/web-ui-components';
import issuerApi from 'apis/issuerApi';
import { errorParser } from 'utils/requests/errorParser';
import styles from './QrCodeModal.module.scss';

interface Props {
  deeplink: string
  isOpen: boolean
  onClose: () => void
}

export default function QrCodeModal({ deeplink, isOpen, onClose }: Props) {

  // const checkCredentialOfferStatus = (check: boolean) => {
  //   console.log(check)
  //     // * do not check if user has closed the modal
  //   if (check) {
  //     const credentialOfferId = deeplink.replace(
  //       "openid-credential-offer://?credential_offer_uri=https%3A%2F%2Fissuer.corposign.net%2Fapi%2Fv1%2Fissuer%2Foffers%2F",
  //       ""
  //     )
  //     issuerApi.getCredentialOffer(credentialOfferId)
  //       .then(({ data }) => {
  //         if (!data) {
  //           message.success('Credential was successfully received!')
  //           onClose();
  //         } else {
  //           setTimeout(() => checkCredentialOfferStatus(isOpen), 2000);
  //         }
  //       })
  //       .catch((error) => errorParser(error, err => alert(err.response?.data|| 'Some error occured')))
  //   }
  // }

  // useEffect(() => checkCredentialOfferStatus(isOpen), [isOpen]);

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      className={styles.modal}
      title='Scan the qr code'
      footer={null}
    >
      <QrCode
        width={300}
        height={300}
        data={deeplink}
      />
    </Modal>
  )
}