import { useContext, useState } from "react";
import authApi, { CreateMemberRequest } from "apis/auth";
import MembersContext from '../MemberContext/MembersContext';

const useCreateMember = () => {
  const [loading, setLoading] = useState(false);
  const { members, updateMembers } = useContext(MembersContext)

  const createMember = async (newMemberInfo: CreateMemberRequest, onSuccess: () => void, onError: (err: any) => void) => {
    try {
      setLoading(true);
      const { data: newMember } = await authApi.createMember(newMemberInfo);
      updateMembers([members[0], newMember, ...members.slice(1)])
      onSuccess();
    } catch (error: any) {
      onError(error)
      // message.error(error?.response?.data?.message || 'Some error occured')
    } finally {
      setLoading(false);
    }
  };

  return [createMember, loading] as const;
}

export default useCreateMember