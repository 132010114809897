import React, { useEffect, useRef } from 'react';
import { Form, Input, RangePicker, RangeValue, useForm } from '@sis-lab/web-ui-components';
import { useI18nContext } from 'i18n/i18n-react';
import DocumentTypeSelector from 'components/DocumentTypeSelector/DocumentTypeSelector';
import { ISignaturesSearch } from 'apis/contract';
import styles from './SignaturesSearch.module.scss';

interface Props {
  onSearch: (searchParams: ISignaturesSearch, controller?: AbortSignal) => void
}

interface FormFields {
  type?: { value: string , label: string }[];
  date?: RangeValue
  search?: string
}


export default function SignaturesSearch({ onSearch }: Props) {
  const { LL } = useI18nContext()
  const abortControllerRef = useRef(new AbortController())
  const setTimeoutRef = useRef<NodeJS.Timeout>()
  const form = useForm();
  const watch = form.watch() as FormFields

  useEffect(() => {
    const subscribtion = form.watch(formValues => {
      abortControllerRef.current.abort()
      abortControllerRef.current = new AbortController()
      if (setTimeoutRef.current) clearTimeout(setTimeoutRef.current)

      const typedValues = formValues as FormFields;

      setTimeoutRef.current = setTimeout(() => {
        onSearch({
          substring: typedValues.search !== '' ? typedValues.search : undefined,
          createdAfter: typedValues.date?.[0]?.startOf('day').toISOString(),
          createdBefore: typedValues.date?.[1]?.endOf('day').toISOString(),
          documentType: typedValues.type?.map(({ value }) => value),
          // status: showSigned ? 'accept' : 'reject'
        }, abortControllerRef.current.signal)
      }, 500)
    })

    return () => subscribtion.unsubscribe()
  }, [watch])

  return (
    <Form formHook={form} className={styles.searchWrapper}>
      <Input
          name='search'
          placeholder={LL.paymentsPage.search.placeholder()}
          wrapperClassName={styles.searchField}
          suffix='search'
        />
      <DocumentTypeSelector
        isRtl
        isMulti
        fixedHeight
        name='type'
        indicator='filter_alt'
      />
      <RangePicker
        name='date'
        placeholder={[LL.paymentsPage.dateFilter.startDate(), LL.paymentsPage.dateFilter.endDate()]} 
      />
    </Form>
  )
}
