/* eslint-disable no-param-reassign */
import axios from 'axios';
import React, { useState } from 'react';
import { connect , useSelector } from 'react-redux';
import {
  Modal,
  Form,
  useForm,
  Input,
  TextArea,
  Select,
  Icon,
  Label,
  Button,
  message,
} from '@sis-lab/web-ui-components';
import { useHistory } from 'react-router-dom';

import { useI18nContext } from 'i18n/i18n-react';
import { getUserLocation } from 'utils';
import { IDocumentBasic  } from 'apis/contract';
import { ApplicationState } from 'modules';
import { UserMetadata, UserDetailed } from 'apis/auth';

import DocumentTypeSelector from 'components/DocumentTypeSelector/DocumentTypeSelector';
import InviteSelector from 'components/InviteSelector/InviteSelector';
import ModalFooter from './components/ModalFooter/ModalFooter';
import useCreateDocument from './components/useCreateDocument';
import styles from './NewDocumentModal.module.scss';
import FileUpload from './components/FileUpload/FileUpload';
import { NewDocumentInfo } from './types';
import useGetSummary from './components/useGetSummary';

interface Props {
  documents: IDocumentBasic[];
  onCancel: () => void;
  open: boolean;
  user?: UserDetailed;
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.authentication.user
});


function NewDocumentModal({ open, onCancel, documents, user }: Props) {
  const metadata = useSelector<ApplicationState, UserMetadata | undefined>(state => state.authentication.metadata)

  const form = useForm();
  const history  = useHistory();
  const { LL } = useI18nContext();

  const [fileData, setFileData] = useState<number[]>([]);
  const [fileBlob, setFileBlob] = useState<Blob>();
  const [invitees, setInvitees] = useState<string[]>([]);
  const [uploadError, setUploadError] = useState<string>();
  const [referencedDocs, setReferencedDocuments] = useState<{ fingerprint: string }[]>([]);
  const [uploadFile, setUploadFile] = useState<boolean>(metadata?.preferences.saveForPreview || true);
  const [createDocument, loading] = useCreateDocument(referencedDocs, invitees, uploadFile);
  const [getSummary, resetSummary ,summaryLoading, summaryDone, summaryAllowed] = useGetSummary(fileBlob);

  const handleClose = () => {
    form.reset();
    setInvitees([]);
    setFileData([]);
    setUploadFile(false);
    resetSummary();
    onCancel();
  }

  const afterUpload = async ({fingerprint, format, title, type, data, blob}: NewDocumentInfo) => {
    resetSummary();
    setFileData(data);
    setFileBlob(blob);

    form.setValue('title', title);
    form.setValue('fingerprint', fingerprint);
    form.setValue('format', format);
    form.setValue('type', {
      value: type,
      label: LL.documentTypes[type as 'workOrder' || 'other']() || type,
    });
  };

  const onSubmit = async (docData: Record<string, unknown>) => {
    if (uploadError) return
    if (!docData.fingerprint) {
      setUploadError(LL.errors.contract.createDocument.noDocument())
      return;
    }
    if (!docData.title) {
      form.setError('title', { message: LL.errors.contract.createDocument.noTitle()})
      return;
    }

    if (docData.notes === '') delete docData.notes;
    if (metadata?.preferences.provideLocation) docData.position = await getUserLocation()

    try {
      await createDocument(docData, fileData);
      history.push(`/documents/${docData.fingerprint}`);
      
    } catch (err: unknown) {
      if (!axios.isAxiosError(err)) return;
      if (err.response?.status === 409) setUploadError(LL.errors.contract.createDocument.alreadyCreated())
    }
  }

  const onGetSummary = async () => {
    const { title, format } = form.getValues();
    const summary = await getSummary(`${title}.${format}`)
    form.setValue('notes', summary);
  }

  return (
    <Modal
      className={styles.modal}
      open={open}
      onCancel={handleClose}
      width={720}
      wrapClassName={styles.wrapped}
      title={
        <>
          <Icon name='add'/>
          <Label className={styles.title}>{LL.documentsPage.newModalTitle()}</Label>
        </>
      }
      footer={
        <ModalFooter
          isSaved={uploadFile}
          onCancel={handleClose}
          loading={loading}
          onSaveToogle={val => setUploadFile(val)}
          onUpload={form.handleSubmit(onSubmit)}
        />
      }
    >
      <Form formHook={form} disabled={loading} className={styles.form} size='medium'>
        {/* <Upload
          disabled={loading}
          icon={fileData.length ? 'document' : 'find'}
          name='file'
          beforeUpload={onUpload}
          size='small'
          errorMessage={uploadError}
          text={fileData.length ? fileInfo : uploadText}
          helpText={LL.commonFields.upload.helpText()}
          title={LL.commonFields.upload.title()}
        /> */}
        <FileUpload afterUpload={afterUpload} />
        <Input
          disabled={loading}
          name='title'
          title={LL.commonFields.documentTitle.title()}
        />
        <Input
          disabled
          name='fingerprint'
          title={LL.commonFields.fingerprint.title()}
          tooltip={LL.commonFields.fingerprint.tooltip()}
        />
        <DocumentTypeSelector
          name='type'
          className={styles.type}
          title={LL.commonFields.documentType.title()}
        />
        <Input
          disabled
          name='format'
          title={LL.commonFields.documentFormat.title()}
          wrapperClassName={styles.format}
        />
        <Select
          isMulti
          title={LL.documentsPage.referencedDocuments()}
          placeholder={LL.documentsPage.referencedPlaceholder()}
          onChange={options => setReferencedDocuments(options.map(({ value }) => ({ fingerprint: value })))}
          options={documents.map((document) => ({
            value: document.fingerprint,
            label: document.title,
          }))}
        />
        <InviteSelector
          invitees={invitees}
          currentParticipants={[]}
          updateList={setInvitees}
          owner={user?.email || ''}
        />
        <div className={summaryAllowed ? styles.notesPredict : ''}>
          <TextArea
            name='notes'
            title={LL.commonFields.notes.title()}
            placeholder={LL.commonFields.notes.placeholder()}
            rules={{ maxLength: { value: 200, message: LL.errors.common.notesMaxLength() }}}
            />
          <Button
            style={!summaryAllowed ? { display: 'none' } : {}}
            type='primary'
            disabled={summaryDone || summaryLoading}
            icon={summaryLoading ? 'loading' : 'document_scanner' as 'loading'}
            // children={LL.commonButtons.cancel()}
            onClick={onGetSummary}
          />
          </div>
      </Form>
    </Modal>
  );
}

export default connect(mapStateToProps)(NewDocumentModal);