import {
  getCredentialTypes,
  initiateCredentialOffer,
  initiateWalletBind,
  removeWalletBind,
  getCredentialOffer,
} from './services'

import {
  setAuthAccessToken
} from './instance'

export * from './services'
export * from './types'

const issuerApi = {
  getCredentialTypes,
  initiateCredentialOffer,
  initiateWalletBind,
  removeWalletBind,
  setAuthAccessToken,
  getCredentialOffer,
}

export default issuerApi;
