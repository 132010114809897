import React from 'react'
import { Button, Checkbox } from '@sis-lab/web-ui-components'
import { useI18nContext } from 'i18n/i18n-react';
import styles from './ModalFooter.module.scss';

interface Props {
  isSaved: boolean
  loading: boolean
  onCancel: () => void
  onSaveToogle: (x: boolean) => void
  onUpload: () => void
}

export  default function ModalFooter({ onSaveToogle, loading, isSaved, onUpload, onCancel }: Props) {
  const { LL } = useI18nContext()

  return <div className={styles.modalFooter}>
      <Checkbox
        checked={isSaved}
        onChange={() => onSaveToogle(!isSaved)}
        children={LL.documentsPage.storeFile()}
        tooltip={LL.documentsPage.storeFileTooltip()}
      />
      <Button
        disabled={loading}
        onClick={onCancel}
        type='secondary'
        children={LL.commonButtons.cancel()}
      />
      <Button
        disabled={loading}
        onClick={onUpload}
        suffix={loading ? 'loading' : undefined}
        children={LL.commonButtons.submit()}
      />
    </div>
}
