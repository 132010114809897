import React from 'react';
import logoSvg from '../../images/corposign-logo.svg';
import styles from './LoadingPage.module.scss';


export default function LoadingPage() {
  return (
    <div className={styles.page}>
      <div>
        <img src={logoSvg}  alt='logo' />
        <h3>CorpoSign</h3>
      </div>
      <span className={styles.loader} />
    </div>
  )
}
