import auth, { Member, MemberSearchParams } from "apis/auth";
import { ApplicationState } from "modules";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { errorParser } from "utils/requests/errorParser";


export default function useFetchMembers() {
  const [members, setMembers] = useState<Member[]>([])
  const [fullyLoaded, setFullyLoaded] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const userSub = useSelector((state: ApplicationState) => state.authentication.user?.sub) 
  const bookmarkRef = useRef<string>()
  const abortControllerRef = useRef(new AbortController())

  const getMoreMembers = useCallback(async (search: MemberSearchParams) => {
    abortControllerRef.current.abort()
    abortControllerRef.current = new AbortController()

    setLoading(true)
    auth.getMembers({ bookmark: bookmarkRef.current, ...search }, abortControllerRef.current.signal)
      .then(({ data }) => {
        if (data.items.length < 10 || data.bookmark === '') setFullyLoaded(true)
        const newList = members.concat(data.items)
        const index = newList.findIndex(member => member.sub === userSub)

        if (index !== -1 && index !== 0) {
          setMembers([newList[index], ...newList.filter(member => member.sub !== userSub)])
        } else {
          setMembers(newList)
        }
        bookmarkRef.current  = data.bookmark
      })
      .finally(() => setLoading(false))
      .catch(e => errorParser(e, err => setError(err.response?.data)))
  }, [members, bookmarkRef.current, abortControllerRef])

  const searchMembers = useCallback(async (search: MemberSearchParams) => {
    abortControllerRef.current.abort()
    abortControllerRef.current = new AbortController()
    setFullyLoaded(false)
    setMembers([])

    setLoading(true)
    auth.getMembers({ ...search }, abortControllerRef.current.signal)
    .then(({ data }) => {
      if (data.items.length < 10) setFullyLoaded(true)

      const index = data.items.findIndex(member => member.sub === userSub)

      if (index !== -1) {
        setMembers([data.items[index], ...data.items.filter(member => member.sub !== userSub)])
      } else {
        setMembers(data.items)
      }

      bookmarkRef.current = data.bookmark
    })
    .finally(() => setLoading(false))
    .catch(e => errorParser(e, err => setError(err.response?.data)))
  }, [abortControllerRef])

  useEffect(() => {
    if (!userSub) return
    auth.getMembers({ }, abortControllerRef.current.signal)
      .then(({ data: memberList }) => {
        if (memberList.items.length < 10 || memberList.bookmark === '') setFullyLoaded(true)

        const index = memberList.items.findIndex(member => member.sub === userSub)

        if (index !== -1) {
          setMembers([memberList.items[index], ...memberList.items.filter(member => member.sub !== userSub)])
        } else {
          setMembers(memberList.items)
        }

        bookmarkRef.current  = memberList.bookmark
      })
    .finally(() => setLoading(false))
    .catch(e => errorParser(e, err => setError(err.response?.data)))
  }, [userSub])

  return {
    members,
    loading,
    fullyLoaded,
    error,
    setMembers,
    getMoreMembers,
    searchMembers
  }
}
