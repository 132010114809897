import { Button, CardFooter } from "@sis-lab/web-ui-components";
import React, { useContext } from "react";
import PrmContext from "pages/PaymentPage/components/PrmContext/PrmContext";
import { useI18nContext } from "i18n/i18n-react";
import useRespondToPrm from "./useRespondToPrm";
import styles from './PayerFooter.module.scss'

interface Props {
  hidden: boolean
  accepted: boolean
}


export default function PayerFooter({ accepted, hidden }: Props) {
  const { LL } = useI18nContext()
  const { prm } = useContext(PrmContext)
  const [respondToPrm, loading] = useRespondToPrm(prm?.id || '')
  
  return (
    <CardFooter className={[hidden && styles.hidden].join(' ')}>
      <Button
        state='warning'
        type='secondary'
        disabled={loading || accepted}
        onClick={() => respondToPrm('reject')}
        children={LL.paymentPage.rejectButton()}
      />
      <Button
        disabled={loading || accepted}
        onClick={() => respondToPrm('accept')}
        children={LL.paymentPage.acceptButton()}
      />
    </CardFooter>
  )
}
