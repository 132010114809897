import React, { useContext } from 'react';
import { Avatar, Skeleton, Timeline, TimelineItem } from '@sis-lab/web-ui-components';
import DocumentEvent from './component/DocumentEvent/DocumentEvent';
import DocumentContext from '../../DocumentContext/DocumentContext';
import styles from './HistoryTab.module.scss';


export default function HistoryTab() {
  const { document } = useContext(DocumentContext)

  if (!document) return <Skeleton height={70} />
  return (
    <Timeline className={styles.history}>
      {document?.history.map(event => {
        switch (event.action) {
          case 'accept':
          case 'reject': return (
            <DocumentEvent
              key={event.createdAt}
              event={event}
              docOwnerId={document.creator.identityId}
              signature={document.signatures.find(signature => signature.creator.sub === event.actor?.sub)}
            />
          )
          case 'inviteCreated':
          case 'inviteRevoked':
          case 'inviteReCreated':
          case 'update':
          case 'eIdSign':
          case 'create': return <DocumentEvent key={event.createdAt} event={event} />;
          default: return (
            <TimelineItem
              dot={<Avatar name={event.actor?.name} width={40} />}
              key={event.createdAt}
              title={event.actor?.name}
              subtitle={event.actor?.email}
              note={event.notes}
              children={event.action}
            />
          );
        }
      })}
    </Timeline>
  )
}
