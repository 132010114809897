import { RcFile } from "@sis-lab/web-ui-components";
import sha256 from 'crypto-js/sha256';
import { arrayBufferToWordArray } from "utils/dataManipulation";

export const getAbFingerprint = (ab: ArrayBuffer) =>
  sha256(arrayBufferToWordArray(ab)).toString()

export const getFileFingerprint = async (file: RcFile) => {
  const fingerprint: string = await new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      resolve(sha256(arrayBufferToWordArray(reader.result)).toString());
    };
  });

  return fingerprint
}
