import React from 'react';
import { connect} from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { ApplicationState } from 'modules';

interface Props extends RouteProps {
  authenticated?: boolean
}

const mapStateToProps = (state: ApplicationState) => ({
  authenticated: state.authentication.authenticated,
});

// eslint-disable-next-line react/jsx-props-no-spreading, react/function-component-definition
const PublicRoute = ({ authenticated, ...rest }: Props) => authenticated ? <Redirect to='/upload' /> : <Route {...rest} />;

/** Component used for routes which should be unaccessible then loged in */
export default connect(mapStateToProps)(PublicRoute);
