import React from 'react';
import KycFlow from './components/KycFlow';
import MemberFlow from './components/MemberFlow';
import KybFlow from './components/KybFlow';

interface Props {
  content: 'kyc' | 'kyb' | 'member'
}

export default function IdentityConfirmedPage({ content }: Props) {
  if (content === 'member') return <MemberFlow />
  if (content === 'kyb') return <KybFlow />
  return <KycFlow />
}