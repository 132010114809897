import React from "react";
import { Button, Card, CardFooter, CardHeader, Form, Input, Skeleton, TextArea, useForm } from "@sis-lab/web-ui-components";
import { transformDate } from "utils";
import { useI18nContext } from "i18n/i18n-react";
import useGetMessagesInfo from "./hooks/useGetMessagesInfo";
import useReceiveMessages from "./hooks/useReceiveMessages";
import CheckStatusField from "./components/CheckStatusField";
import styles from './PeppolMessagesCard.module.scss';


export default function PeppolMessagesCard() {
  const [messages, lastLog, receiving, loading, prohibited, updateMessagesInfo] = useGetMessagesInfo()
  const [loadingReceive, receiveMessages] = useReceiveMessages(updateMessagesInfo)
  const { LL } = useI18nContext()
  const form = useForm()
  
  const showSkeleton = loading && !messages && !form.formState.isDirty
  return (
    <Card className={styles.configCard} >
      <CardHeader
        title={LL.peppolTab.receiveTitle()}
        className={styles.cardHeader}
        suffix={
          <div className={[styles.headerSuffix, (prohibited || showSkeleton) && styles.hidden].join(' ')}>
            <CheckStatusField />
            <Button
              className={[loading && styles.loadingButton].join(' ')}
              onClick={updateMessagesInfo}
              disabled={loading}
              type='secondary'
              icon={'sync' as 'arrow_down'}
            />
          </div>
        }
      />

      {showSkeleton && <Skeleton count={3} style={{ marginBottom: '20px' }} />}
      {prohibited && LL.peppolTab.prohibited()}
      <Form disabled formHook={form} className={[styles.cardBody, (showSkeleton || prohibited) && styles.hidden].join(' ')}>
        {/* later show message ids as in documents */}
        <Input
          name='pendingMessages'
          value={`${LL.peppolTab.pendingMessages.value()} - ${messages?.length}`}
          disabled
        />
        <TextArea
          className={styles.textArea}
          disabled
          name='lastLog'
          title={LL.peppolTab.lastLog.title()}
          helpText={LL.peppolTab.lastLog.helpText()}
          value={lastLog ?
            `${receiving
              ? `Receive is in progress... \n ${JSON.stringify(lastLog, null, 4)}`
              : `${JSON.stringify(lastLog, null, 4)}`
            }`
            : LL.peppolTab.lastLog.notReceived()
          }
        />
      </Form>
      <CardFooter className={styles.footer}>
        <Button
          disabled={loading || receiving || loadingReceive || !messages?.length}
          suffix={receiving || loadingReceive ? 'loading' : 'download'}
          className={(prohibited || showSkeleton) ? styles.hidden : styles.button}
          onClick={receiveMessages}
          type='primary'
          children={LL.peppolTab.receiveButton()}
        />
      </CardFooter>
    </Card>
  )
}
