import { Avatar, Caption, Label } from "@sis-lab/web-ui-components";
import { PrmPayer } from "apis/prmApi";
import React from "react";
import styles from './PayerRow.module.scss'

interface Props {
  payer: PrmPayer
}


export default function PayerRow({ payer }: Props) {
  return (
    <div className={styles.payerRow}>
      <Avatar width={40} name={payer.id} />
      <div>
        <Label>{payer.name}</Label>
        <Caption small>{payer.email}</Caption>
      </div>
    </div>
  )
}
