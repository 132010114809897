import React from "react"
import { Icon } from "@sis-lab/web-ui-components"
import styles from './HistoryRow.module.scss'

interface Props {
  title: string
  date: string
  onClick: () => void
}


export default function HistoryRow({ title, date, onClick }: Props) {
  return (
    <button type='button' onClick={onClick} className={styles.row}>
      <div className={styles.title}>
        <Icon name='document' />
        <p>{title}</p>
      </div>
      <p className={styles.date}>{date}</p>
    </button>
  )
}
