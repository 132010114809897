import { Card, CardHeader, Checkbox, Form, FormSelect, Icon, Input, TextArea, useForm } from "@sis-lab/web-ui-components";
import { useI18nContext } from "i18n/i18n-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserDetailed } from "apis/auth";
import { ApplicationState } from "modules";
import { connect } from "react-redux";
import { getMessageForNewInvite, isNewInviteValid } from "utils/unique/invites";
import DocumentTypeSelector from "components/DocumentTypeSelector/DocumentTypeSelector";
import InviteSelector from "components/InviteSelector/InviteSelector";
import { DocumentForm, DocumentInfo } from "../types";
import styles from './DocumentCard.module.scss'

interface Props {
  selectedDocument: DocumentInfo
  onEdit: (selectedDocument: DocumentInfo) => void
  loading?: boolean
  user?: UserDetailed;
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.authentication.user
});


function DocumentCard({ selectedDocument, loading, onEdit, user }: Props) {
  const { LL } = useI18nContext()
  const form = useForm()
  const [inviteErrorMessage, setInviteErrorMessage] = useState<string>()

  useEffect(() => {
    form.setValue('title', selectedDocument.title)
    form.setValue('type', { value: selectedDocument.type || 'other', label: selectedDocument.type || 'other' })
    form.setValue('invites', selectedDocument.invitees.map(invite => ({ value: invite, label: invite })))
    form.setValue('notes', selectedDocument.notes)
    setInviteErrorMessage('')
  }, [selectedDocument])

  
  const update = () => {
    form.clearErrors()
    const typedData = form.getValues() as DocumentForm;

    if (!typedData.title || typedData.title === '') {
      form.setError('title', { message: LL.errors.contract.createDocument.noTitle()})
      return;
    }

    onEdit({
      title: typedData.title,
      format: selectedDocument.format,
      data: selectedDocument.data,
      fingerprint: selectedDocument.fingerprint,
      invitees: typedData.invites?.map(({ value }) => value),
      notes: typedData.notes,
      type: typedData.type?.value,
      saveForPreview: selectedDocument.saveForPreview,
    })
  }

  const updateInvitees = (invitees: string[]) => onEdit({
    ...selectedDocument,
    invitees
  })

  return (
    <Card>
      <CardHeader
        prefix={<Icon name='document' />}
        title={LL.uploadPage.documentCardTitle()}
        suffix={selectedDocument.completed ? <Link to={`/documents/${selectedDocument.fingerprint}`}>{LL.uploadPage.goToDocument()}</Link> : undefined} 
      />
      <Form disabled={selectedDocument.completed || loading} formHook={form} className={styles.form} size='medium'>
        <Input
          disabled={selectedDocument.completed || loading}
          name='title'
          title={LL.commonFields.documentTitle.title()}
          onChange={update}
        />
        <Input
          disabled
          name='fingerprint'
          value={selectedDocument.fingerprint}
          title={LL.commonFields.fingerprint.title()}
          tooltip={LL.commonFields.fingerprint.tooltip()}
        />
        <Input
          disabled
          name='format'
          value={selectedDocument.format}
          title={LL.commonFields.documentFormat.title()}
          wrapperClassName={styles.format}
        />
        <DocumentTypeSelector
          name='type'
          className={styles.type}
          title={LL.commonFields.documentType.title()}
          isDisabled={selectedDocument.completed || loading}
          onChange={update}
          value={{
            value: selectedDocument.type || '',
            label: LL.documentTypes[selectedDocument.type as 'workOrder']() || selectedDocument.type
          }}
        />
        <InviteSelector
          invitees={selectedDocument.invitees}
          currentParticipants={[]}
          updateList={updateInvitees}
          owner={user?.email || ''}
        />
        {/* <FormSelect
          isMulti
          isCreatable
          name='invites'
          noOptionsMessage={() => null}
          isDisabled={selectedDocument.completed || loading}
          title={LL.documentsPage.inviteTitle()}
          className={(selectedDocument.completed && selectedDocument.invitees.length === 0) ? styles.hidden : undefined}
          placeholder={LL.documentsPage.invitePlaceholder()}
          onChange={update}
          errorMessage={inviteErrorMessage}
          formatCreateLabel={(inputValue) => `Invite ${inputValue}`}
          isValidNewOption={(inputValue) => isNewInviteValid(inputValue, selectedDocument.invitees, user?.email)}
          onInputChange={(newValue) => setInviteErrorMessage(
            getMessageForNewInvite(newValue, selectedDocument.invitees, user?.email)
          )}
        /> */}
        <TextArea
          name='notes'
          title={LL.commonFields.notes.title()}
          onChange={update}
          wrapperClassName={(selectedDocument.completed && selectedDocument.notes === undefined) ? styles.hidden : undefined}
          placeholder={selectedDocument.completed ? undefined : LL.commonFields.notes.placeholder()}
          rules={{ maxLength: { value: 200, message: LL.errors.common.notesMaxLength() }}}
        />
        <Checkbox
          checked={selectedDocument.saveForPreview}
          onChange={() => onEdit({ ...selectedDocument, saveForPreview:  !selectedDocument.saveForPreview })}
          className={selectedDocument.completed ? styles.hidden : styles.storeCheckbox}
          children={LL.documentsPage.storeFile()}
          tooltip={LL.documentsPage.storeFileTooltip()}
        />
      </Form>
    </Card>
  )
}

export default connect(mapStateToProps)(DocumentCard);