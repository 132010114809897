import { QrCode, Card, CardHeader, Icon, Skeleton, message } from '@sis-lab/web-ui-components';
import React, { useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import styles from './IssuerStep.module.scss';
import config from '../../../../../../config';

type Props = {
  setVpSession: (vpSession: string) => void;
  switchToPasswordLogin: () => void
}

export default function IssuerStep({ setVpSession, switchToPasswordLogin }: Props) {
  const [state, setState] = useState('');
  const [deeplink, setDeeplink] = useState<string>()
  // TODO: compare returned issuer state in  before setting VpSession to ensure

  const onMessage = (msg: MessageEvent) => {
    const data = JSON.parse(msg.data);
    if (data.uri && data.state) {
      setDeeplink(data.uri);
      setState(data.state);
    }

    if (data.code && data.state) {
      if (state !== data.state) {
        message.error('Start the flow again, returned state does not match with previous one')
      } else {
        setVpSession(data.code);
        setDeeplink('');
        setState('');
      }
    }
  }

  const { sendJsonMessage } = useWebSocket(
    config.services.issuerApi.verifierWs,
    { onMessage }
  );

  const goBack = () => {
    switchToPasswordLogin();
    setVpSession('');
    setDeeplink('');
    setState('');
  }

  useEffect(() => {
    sendJsonMessage({ op: "generate_authorize_request", data: "" });
  }, []);

  return (
    <Card className={styles.card}>
      <CardHeader
        title='Step 1/2: Scan the QR Code'
        prefix={<Icon onClick={goBack} name='arrow_left' />}
      />
      <div className={styles.cardBody}>
        {
          deeplink
            ? <QrCode width={300} height={300} data={deeplink} />
            : <Skeleton width={300} height={300} />
        }
      </div>
    </Card>
  );
}
