import { getDocumentCertificate } from 'apis/contract';

export const getDocumentCertificateService = async (fingerprint: string): Promise<[Blob, string]> => {
  const documentCertificateResponse = await getDocumentCertificate(fingerprint);
  const filetype = 'pdf';
  const filename = fingerprint;
  const blob = new Blob(
    [documentCertificateResponse.data],
    { type: documentCertificateResponse.headers['content-type'] },
  );
  return [blob, filetype ? `${filename}.${filetype}` : ''];
};
