import { Member, MemberSearchParams } from "apis/auth";
import { createContext } from "react";


export type MembersContextState = {
  members: Member[]
  loading: boolean
  fullyLoaded: boolean
  getMoreMembers: (search: MemberSearchParams) => Promise<void> 
  searchMembers: (search: MemberSearchParams) => Promise<void> 
  updateMembers: (members: Member[]) => void 
}

export default createContext<MembersContextState>({
  members: [],
  loading: true,
  fullyLoaded: false,
  getMoreMembers: () => new Promise(resolve => { resolve() }),
  searchMembers: () => new Promise(resolve => { resolve() }),
  updateMembers: () => null,
})
