import React from "react";
import ProvidersCard from "./components/ProvidersCard/ProvidersCard";
import RtpConfigCard from "./components/RtpConfigCard/RtpConfigCard";

import styles from './PaymentsTab.module.scss';


export default function PaymentsTab() {

  return (
    <div className={styles.paymentsTab}>
      <ProvidersCard/>
      <RtpConfigCard/> 
    </div>
  )
}
