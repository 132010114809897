import {
  getSummary,
  getPreferences,
  classifyByFileName,
} from './services'

import {
  setAuthAccessToken
} from './instance'

export * from './services'
export * from './types'

const ai = {
  getSummary,
  getPreferences,
  classifyByFileName,
  setAuthAccessToken,
}

export default ai;
